import { FieldArray, FieldArrayRenderProps } from "formik";
import { FeamFormArrayFieldInput, FeamFormArrayFieldSelect, FeamFormArrayFieldAutoInputForEmployee, FeamFormArrayTimeFieldInput } from "../../common";
import { FeamEntities, IProps } from "../../../models/feam-entities";
export default function CwsMechanicsAssignedWithWS(props: IProps.ICwsMechanicsAssignedWithWSProps<FeamEntities.IMechanicsAssigned>) {
    const { formProps, arraySelector, isPrinting, payTypes, workTitles, mechanics } = props;
    return (
        <>
            {/*//@ts-ignore */}
            <div className={`row ${formProps.values.multiTail ? "" : "mb-1"}`}>
                <div className="col-12 d-flex flex-column ps-2">
                    <div className="row d-flex  d-inline-flex customer-worksheet-ws-mgap ps-2">
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-ws-mname text-uppercase">NAME</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-ws-date text-uppercase">Start Date</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-ws-date text-uppercase">End Date</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-ws-workshift text-uppercase text-nowrap">Shift</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center m-ws-hours-width text-uppercase">Hours</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center m-ws-select-width text-uppercase">Pay</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center m-ws-select-width text-uppercase">Position</span>
                    </div>
                    <FieldArray
                        name={arraySelector}
                        render={(_: FieldArrayRenderProps) =>
                        (
                            <>
                                {/*//@ts-ignore */}
                                {formProps.values.mechanicsAssigneds?.map((m, index) => {
                                    return (
                                        ((index <= 11) &&
                                            <div key={`mechanicsAssingen_left_${index}`} className="row">
                                                <div className="d-flex  d-inline-flex customer-worksheet-ws-mgap pe-0">
                                                    <div className="col-auto customer-worksheet-ws-mname">
                                                        {/*//@ts-ignore */}
                                                        <FeamFormArrayFieldAutoInputForEmployee formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="mechanicName" className="customer-worksheet-form-control" data={[...mechanics]} outerDivClassName="customer-worksheet-ws-mname" />
                                                    </div>
                                                    <div className="col-auto customer-worksheet-ws-date">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].shiftStartDate)) && <FeamFormArrayFieldInput type="date" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftStartDate" onBlur={(e) => { setTimeDiff(formProps.values.mechanicsAssigneds[index].workShiftTime ?? "hh:mm", e.target.value, formProps.values.mechanicsAssigneds[index].shiftEndDate, index); formProps.handleBlur(e) }} className="customer-worksheet-form-control" max={formProps.values.mechanicsAssigneds[index].shiftEndDate} />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].shiftStartDate && <input type="text" className="form-control customer-worksheet-form-control" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-ws-date">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].shiftEndDate)) && <FeamFormArrayFieldInput type="date" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftEndDate" onBlur={(e) => { setTimeDiff(formProps.values.mechanicsAssigneds[index].workShiftTime ?? "hh:mm", formProps.values.mechanicsAssigneds[index].shiftStartDate, e.target.value, index); formProps.handleBlur(e) }} className="customer-worksheet-form-control" min={formProps.values.mechanicsAssigneds[index].shiftStartDate} />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].shiftEndDate && <input type="text" className="form-control customer-worksheet-form-control" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-ws-workshift">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].workShiftTime)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="workShiftTime" onBlur={(e) => { setTimeDiff(e.target.value ?? "hh:mm", formProps.values.mechanicsAssigneds[index].shiftStartDate, formProps.values.mechanicsAssigneds[index].shiftEndDate, index); formProps.handleBlur(e) }} className="customer-worksheet-form-control" step={1} min={0} max={24} pattern="[0-9]" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].workShiftTime && <input type="text" className="form-control customer-worksheet-form-control" />}
                                                    </div>
                                                    <div className="col-auto m-ws-hours-width">
                                                        <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftHours" className="customer-worksheet-form-tcontrol" readOnly={true} />
                                                    </div>
                                                    <div className="col-auto m-ws-select-width">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].payCode)) && <FeamFormArrayFieldSelect defaultText="Pay" data={[...payTypes]} formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="payCode" className="customer-worksheet-form-control m-ws-select-width" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].payCode && <input type="text" className="form-control customer-worksheet-form-control m-ws-select-width" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mselect m-select-width">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].workTitleCode)) && <FeamFormArrayFieldSelect defaultText="Role" data={[...workTitles]} formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="workTitleCode" className="customer-worksheet-form-control m-ws-select-width" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].workTitleCode && <input type="text" className="form-control customer-worksheet-form-control m-ws-select-width" />}
                                                    </div>
                                                </div>
                                            </div>)
                                    )
                                })}
                            </>
                        )} />

                </div>
            </div>
        </>
    )


    function setTimeDiff(workshift: string, start: string, end: string, index: number) {
        if (!start || !end || start == '' || end == '' || workshift == '' || workshift === "00:00" || (workshift.includes("hh") || workshift.includes("mm"))) {
            if (start && (!end || end == '')) {
                formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftEndDate"}`, start);
            }
            formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, "");
            return;
        }
        const workshiftCollection = workshift?.split(":");
        let startDate = new Date(new Date(start.split('T')[0]).setHours(0, 0, 0, 0));
        let endDate = new Date(new Date(end.split('T')[0]).setHours(0, 0, 0, 0));
        endDate.setDate(endDate.getDate() + 1);
        let diff = endDate.getTime() - startDate.getTime();
        const days = diff / (1000 * 60 * 60 * 24);
        let hours = +workshiftCollection[0] * days;
        let totalMinutes = +workshiftCollection[1] * days;
        hours += Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        const hm = (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
        formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, hm);
    }
}