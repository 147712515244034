import { useState } from "react";
import { IProps } from "../../../models/feam-entities";
import { formatStringDate } from "../../../models/feam-utils";
import { D407FormViewSelection } from "../common";
import { useFeamAuth } from "../../hooks";
import * as BootStrapIcon from "react-bootstrap-icons";

export default function TailMaterialDetailView(
  props: IProps.ITailMaterialDetailProps
) {
  const [id, setId] = useState(undefined);
  const [dynamicView, setDynamicView] = useState(false);
  const { user } = useFeamAuth();
  const [showTailMaterialDetail, setTailMaterialDetail] = useState(false);

  return (
    <>
      <div
        id="searchSection"
        className="d-flex justify-content-between align-items-center header-col w-100 p-2 pt-1 pb-1 fw-bold rounded text-uppercase "
      >
        <button
          type="button"
          className="btn btn-light-secondary btn-block w-100 text-uppercase m-0  p-2 feam-font-size-sm text-start align-items-center d-flex gap-2 fw-bold"
          onClick={() => setTailMaterialDetail((p) => !p)}
          aria-controls="Hours Detail"
          aria-expanded={showTailMaterialDetail}
        >
          {!showTailMaterialDetail ? (
            <>
              {" "}
              <BootStrapIcon.ArrowsFullscreen size={16} /> Show Tail Material
              Detail
            </>
          ) : (
            <>
              <BootStrapIcon.FullscreenExit size={16} /> Hide Tail Material
              Detail
            </>
          )}
        </button>
      </div>
      {showTailMaterialDetail && (
        <div className="p-2">
          <table className="table">
            <thead>
              <tr>
                <th>D407Id</th>
                <th className="text-nowrap">Week No.</th>
                <th className="text-nowrap">EntryDate</th>
                <th>Tail</th>
                <th className="text-nowrap">HYD Engine1</th>
                <th className="text-nowrap">HYD Engine2</th>
                <th className="text-nowrap">HYD Engine3</th>
                <th className="text-nowrap">HYD Engine4</th>
                <th className="text-nowrap">OIL Engine1</th>
                <th className="text-nowrap">OIL Engine2</th>
                <th className="text-nowrap">OIL Engine3</th>
                <th className="text-nowrap">OIL Engine4</th>
                <th className="text-nowrap">Towing</th>
                <th className="text-nowrap">Oxygen</th>
                <th className="text-nowrap">Nitrogen</th>
              </tr>
            </thead>
            <tbody>
              {props.tailMaterialsData.map((h, hi) => {
                return (
                  <tr key={`tailMaterialsDetail_${hi}`}>
                    <td>
                      <a
                        className="link-offset-2 link-underline link-underline-opacity-100"
                        href="javascript: void(0);"
                        onClick={(e) => {
                          e.preventDefault();
                          //@ts-ignore
                          setId(h.d407id?.toString());
                        }}
                      >
                        {h.d407id}
                      </a>
                    </td>
                    <td>{h.weekNum}</td>
                    <td className="text-nowrap">
                      {h.entryDate
                        ? formatStringDate(h.entryDate.toString().split("T")[0])
                        : ""}
                    </td>
                    <td>{h.tail}</td>
                    <td>{h.hydEngine1 ?? 0}</td>
                    <td>{h.hydEngine2 ?? 0}</td>
                    <td>{h.hydEngine3 ?? 0}</td>
                    <td>{h.hydEngine4 ?? 0}</td>
                    <td>{h.oilEngine1 ?? 0}</td>
                    <td>{h.oilEngine2 ?? 0}</td>
                    <td>{h.oilEngine3 ?? 0}</td>
                    <td>{h.oilEngine4 ?? 0}</td>
                    <td>{h.towing === true ? 1 : 0}</td>
                    <td>{h.materialsOxygen ?? 0}</td>
                    <td>{h.materialsNitrogen ?? 0}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <D407FormViewSelection id={id} resetData={() => setId(undefined)} />
    </>
  );
}
