import logo from "../../../assets/images/logo.png";
import { IProps } from "../../../models/feam-entities";

export default function CwsHeaderSection(props: IProps.ICwsHeaderSectionProps) {

    return (
        <>
            <div className="d-flex flex-row justify-content-between align-items-center customer-worksheet-header gap-3">
                <img alt="FEAM Aero" src={logo} className="customer-worksheet-logo" />
                <div className="d-flex fs-4 fw-bold flex-fill justify-content-center">
                    <span>CUSTOMER WORK SHEET</span>
                </div>
                <div className={`d-flex flex-column  justify-content-end align-items-end customer-worksheet-version-container-cvg`}>
                    <div className={`d-flex  flex-column alignitems ${props.station === 'CVG' ? 'customer-worksheet-version-container-cvg' : 'customer-worksheet-version-container'}`}>
                        <span className="fs-6 fw-bold w-100">{props.station === 'CVG' ? 'FEAM CVG-407' : 'FEAM-407'}</span>
                        <span className="customer-worksheet-version-fontsize w-100">Web Version V5</span>
                        <span className="customer-worksheet-version-fontsize w-100">8-01-2024</span>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center ">
                <div className="flex-column" style={{ width: "165px" }}></div>
                <div><span className="d-flex flex-column flex-fill justify-content-center fs-6 fw-bold">F&amp;E Aircraft Maintenance ({props.station === 'CVG' ? 'CVG' : 'MIAMI'}) LLC </span></div>
                <div className="d-flex  justify-content-end align-items-center customer-worksheet-version-container-cvg">
                    <div className="d-flex flex-column me-1  fs-6 fw-bold">{props.d407Id && <span>D407ID: {props.d407Id}</span>}</div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="flex-column" style={{ width: "165px" }}></div>
                {/** TODO: Change on the basis of station */}
                <span className="fs-6 fw-bold">{props.station === 'CVG' ? "FAA CRS GNDR920D" : "FAA CRS F8ER232Y"}</span>
                <div className="d-flex  justify-content-end align-items-center customer-worksheet-version-container-cvg"></div>
            </div>
        </>
    );

}