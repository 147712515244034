import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import SingIn from "./SingIn";
import { useBetween } from "use-between";
import FeamFooter from "./FeamFooter";
import FeamNavBar from "./FeamNavBar";
import { useFeamPrint } from "./components/hooks";

export const usePrint = () => useBetween(useFeamPrint);
export default function PageLayout(props: { children: any }) {
    const { isPrint } = usePrint();
    return (
        <>
            <AuthenticatedTemplate>
                {!isPrint && <div className="d-flex flex-row"> <FeamNavBar /></div>}
                <div className={!isPrint ? "d-flex container flex-row" : ""}>
                    {props.children}</div>
                {!isPrint && <div className="d-flex flex-row"> <FeamFooter /></div>}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SingIn />
            </UnauthenticatedTemplate>
        </>
    );


};


