import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class MaintenanceTypeService {
  #rootPath: string = "maintenancetype";
  async getAll(): Promise<FeamEntities.IMaintenanceType[]> {
    try {
      const res = await apiClient.get<FeamEntities.IMaintenanceType[]>(
        this.#rootPath
      );
      return res.data??[];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const maintenanceTypeService = new MaintenanceTypeService();
export default maintenanceTypeService;
