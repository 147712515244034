import { IProps } from "../../../../models/feam-entities";
import { sumOfTimes } from "../../../../models/feam-utils";
import { allWorkTitles } from "../../../../models/constants";
import { Fragment } from "react";

export default function JobDescriptionOfSummaryReport(props: IProps.IJobDescriptionOfSummaryReportProps) {
    const { summaryReport } = props;
    const allWorkTitlesCount = allWorkTitles.length;
    return (
        <table className="table rounded">
            <thead className="p-1 rounded">
                <tr className="text-uppercase fw-bold header-col ">
                    <th className="header-col ">JOB DESCRIPTION:</th>
                    <th className="header-col ">REG</th>
                    <th className="header-col ">OT</th>
                    <th className="header-col "></th>
                    <th className="header-col ">REG</th>
                    <th className="header-col ">OT</th>
                </tr>
            </thead>
            <tbody>
                {
                    allWorkTitles.map((wt, i) => {
                        return (
                            <Fragment key={wt}>
                                {(i % 2 === 0) && (< tr key={wt} className="text-uppercase">
                                    <td className="fw-bold">{wt}</td>
                                    <td>{getRegTime(wt)} </td>
                                    <td>{getOtTime(wt)} </td>
                                    <td className="fw-bold">{allWorkTitlesCount > (i + 1) && allWorkTitles[i + 1]}</td>
                                    <td>{allWorkTitlesCount > (i + 1) && getRegTime(allWorkTitles[i + 1])} </td>
                                    <td>{allWorkTitlesCount > (i + 1) && getOtTime(allWorkTitles[i + 1])} </td>
                                </tr>)}
                            </Fragment>)
                    })}
                <tr className="text-uppercase fw-bold">
                    <td colSpan={4}>Total</td>
                    { /*@ts-ignore next-line*/}
                    <td>{summaryReport.mechanicsAssigned ? sumOfTimes(summaryReport.mechanicsAssigned.flatMap(m => m.detail?.flatMap(d => d.reg))) : "00:00"} </td>
                    { /*@ts-ignore next-line*/}
                    <td>{summaryReport.mechanicsAssigned ? sumOfTimes(summaryReport.mechanicsAssigned.flatMap(m => m.detail?.flatMap(d => d.ot))) : "00:00"} </td>
                </tr>
            </tbody>
        </table >
    );


    function getRegTime(workTitle: string) {
        { /*@ts-ignore next-line*/ }
        return summaryReport.mechanicsAssigned ? sumOfTimes(summaryReport.mechanicsAssigned.filter(x => x.workTitle === workTitle).flatMap(m => m.detail?.flatMap(d => d.reg))) : "00:00";
    }

    function getOtTime(workTitle: string) {
        { /*@ts-ignore next-line*/ }
        return summaryReport.mechanicsAssigned ? sumOfTimes(summaryReport.mechanicsAssigned.filter(x => x.workTitle === workTitle).flatMap(m => m.detail?.flatMap(d => d.ot))) : "00:00";
    }
}