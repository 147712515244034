import { FeamEntities, IProps } from "../../../models/feam-entities";
import { mechanicsTypes, supervisorsTypes, avionicsType, leadsTypes, regularPay, overtimePay } from "../../../models/constants";
import { sumOfTimes } from "../../../models/feam-utils";
import CwsMechanicsAssignedWithOutWS from "./CwsMechanicsAssignedWithOutWS";
import CwsMechanicsAssignedWithWS from "./CwsMechanicsAssignedWithWS";
import * as factories from "../../../models/factories";
import CwsMechanicsAssignedForAog from "./CwsMechanicsAssignedForAog";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS } from "react-device-detect";

export default function CwsMechanicsAssigned(props: IProps.ICwsMechanicsAssignedProps<FeamEntities.IMechanicsAssigned>) {
    const { isPrint } = usePrint();
    const { formProps, isPrinting, maintenanceTypes } = props;
    const totalSum = sumOfTimes(
        //@ts-ignore 
        formProps.values.mechanicsAssigneds?.flatMap(x => x.shiftHours)
    );
    //@ts-ignore 
    if (totalSum !== formProps.values.totalSum) formProps.setFieldValue("totalSum", totalSum);
    return (
        <>
            {/*//@ts-ignore */}
            <div className={`d-flex d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase ${formProps.values.multiTail ? "" : "mb-1"}`}>
                <div>MECHANICS ASSIGNED</div>
                {!isPrinting &&
                    <div className="d-flex align-items-center">
                        <div className="form-check d-inline-flex gap-1 align-items-center">
                            {/*//@ts-ignore */}
                            <input type="checkbox" id="isWorkShift" name="isWorkShift" checked={formProps.values.isWorkShift ?? false} onChange={e => { formProps.values.mechanicsAssigneds = factories.getmechanicsAssigneds(); formProps.handleChange(e) }} className="form-check-input" disabled={formProps.values.maintenanceTypeId && ["AOG", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name)} />
                            <label className="customer-worksheet-ws-label form-check-label" >
                                Day/Week Shift
                            </label>
                        </div>
                    </div>}
                <div className="d-inline-flex gap-5">
                    <div className="fw-bold justify-content-center">TOTAL HOURS:</div>
                    <div className="d-flex justify-content-center  gap-2">
                        <span className="fw-bold">Regular Time: {sumOfTimes(
                            //@ts-ignore 
                            formProps.values.mechanicsAssigneds?.filter(x => regularPay.includes(x.payCode)).flatMap(x => x.shiftHours)
                        )}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-start gap-2">
                        <span className="fw-bold">Overtime: {sumOfTimes(
                            //@ts-ignore 
                            formProps.values.mechanicsAssigneds?.filter(x => overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
                        )}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-start gap-2">
                        <span className="fw-bold">OTHER: {sumOfTimes(
                            //@ts-ignore 
                            formProps.values.mechanicsAssigneds?.filter(x => !(regularPay.includes(x.payCode) || overtimePay.includes(x.payCode))).flatMap(x => x.shiftHours)
                        )}</span>
                    </div>
                </div>
            </div>
            {/*//@ts-ignore */}
            {!formProps.values.isWorkShift && <CwsMechanicsAssignedWithOutWS {...props} payTypes={props.payTypes} workTitles={props.workTitles} mechanics={props.employees} />}
            {/*//@ts-ignore */}
            {formProps.values.isWorkShift && (!formProps.values.maintenanceTypeId || (formProps.values.maintenanceTypeId && !["AOG", "MRR", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name?.trim()))) && <CwsMechanicsAssignedWithWS {...props} payTypes={props.payTypes} workTitles={props.workTitles} mechanics={props.employees} />}
            {/*//@ts-ignore */}
            {formProps.values.isWorkShift && formProps.values.maintenanceTypeId && ["AOG", "MRR", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name?.trim()) && <CwsMechanicsAssignedForAog {...props} payTypes={props.payTypes} workTitles={props.workTitles} mechanics={props.employees} />}
            <div className={` ${isPrint && isMobile && isIOS ? "feam-mt-2 feam-mb-2" : "mt-3 mb-1"} d-flex d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase`}>
                <div className="d-flex justify-content-center align-items-start mr-3">
                    <span className="fw-bold">Total:</span>
                </div>
                <div className="d-flex justify-content-center align-items-start gap-2">
                    <span className="fw-bold">Mechanics: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => mechanicsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-end align-items-start gap-2">
                    <span className="fw-bold">Avionics: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => avionicsType.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-center align-items-start gap-2">
                    <span className="fw-bold ">Supervisors: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => supervisorsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-end align-items-start gap-2">
                    <span className="fw-bold">LEADS: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => leadsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-end align-items-start gap-2">
                    <span className="fw-bold">Other: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => ![
                            ...mechanicsTypes,
                            ...avionicsType,
                            ...supervisorsTypes,
                            ...leadsTypes
                        ].includes(x.workTitleCode?.trim())).flatMap((x: FeamEntities.IMechanicsAssigned) => x.shiftHours)
                    )}</span>
                </div>
            </div>
        </>
    )


}