import { IProps } from "../../../models/feam-entities";

export default function FeamFieldSetWrapper(props: IProps.IFeamFieldSetWrapper) {
  return (
    <>
      <fieldset disabled={props.disabled} style={{ display: !props.isPrint ? "" : "none" }}>
        {props.children}
      </fieldset>
      {props.isPrint && <> {props.children}</>}
    </>
  )
}