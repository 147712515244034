class Cache extends Map<string, any> {
  constructor() {
    super();
  }
  delete(key: string) {
    return super.delete(this.preProcess(key));
  }
  get(key: string) {
    return super.get(this.preProcess(key));
  }
  has(key: string) {
    return super.has(this.preProcess(key));
  }

  set(key: string, value: any) {
    return super.set(this.preProcess(key), value);
  }
  /*
     This is the method that will convert an object into a custom key.  It takes a parameter and returns it's processed key value.  If the type of parameter is primitive, it will return it unchanged.  Otherwise, the JSON.stringify will be called on the argument and the resulting value will be returned.  
    */

  preProcess(key: string) {
    if (typeof key === "object") {
      return JSON.stringify(key);
    } else {
      return key;
    }
  }
}

const feamCache = new Cache();

export default feamCache;
