import { useState } from "react";
import { IProps } from "../../../models/feam-entities";
import { useServiceType } from "./BillingReviewContainer";
import * as BootStrapIcon from "react-bootstrap-icons";
import * as utils from "../../../models/feam-utils";
import { useFeamAuth } from "../../hooks";

export default function ServiceInfoArea(props: IProps.IServiceInfosAreaProps) {
  const { serviceType, setServiceType } = useServiceType();
  const [childToggle, setChildToggle] = useState(false);
  const { user } = useFeamAuth();
  const [active, setActive] = useState<number | undefined>();

  const setActiveRow = (id: number) => {
    setActive(id);
  };

  return (
    <ul>
      <li>
        <span
          className={`caret feam-font-size-sm ${
            childToggle ? "caret-down" : ""
          }`}
          onClick={() => {
            setChildToggle((p) => !p);
          }}
        >
          {props.cobblestoneArea}
        </span>
        {childToggle && (
          <div className="ps-3 pt-1">
            <table className="table ">
              <thead>
                <tr>
                  <th className="text-nowrap">Service Type</th>
                  {(utils.isSuperAdmin(user) ||
                    utils.isBillingApprover(user)) && (
                    <th className="text-nowrap">Rate USD</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {props.serviceTypes?.map((h, i) => {
                  return (
                    <tr
                      key={`serviceType_${i}`}
                      className={`${active === i ? "table-active" : ""}`}
                      onClick={() => {
                        setServiceType(h.cobblestoneServiceType);
                        setActiveRow(i);
                      }}
                    >
                      <td>
                        <div className="d-inline-flex gap-2">
                          {h.hasBillingInfo && (
                            <span>
                              <BootStrapIcon.InfoCircle
                                size={16}
                                className="text-info"
                                title="Billing Info Exists"
                              />
                            </span>
                          )}
                          <span> {h.cobblestoneServiceType}</span>
                        </div>
                      </td>
                      {(utils.isSuperAdmin(user) ||
                        utils.isBillingApprover(user)) && (
                        <td>${h.cobblestoneRateUSD ?? 0}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </li>
    </ul>
  );
}
