import axios from "axios";
import { feamConfig } from "../feamConfig";

export const apiConfigMiddleWare: {
  accessToken: string;
  expireOn: number;
} = {
  accessToken: "",
  expireOn: -1,
};

const apiClient = axios.create({
  baseURL: feamConfig.apiUrl,
});

export default apiClient;
