import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class D407BillingService {
  #rootPath: string = "d407billingreview";
  async searchD407PerMenHoursForBilling(
    filter: FeamEntities.IWorkSheetFilter
  ): Promise<FeamEntities.ID407perManHoursBilling[]> {
    try {
      const response = await apiClient.get<
        FeamEntities.ID407perManHoursBilling[]
      >(`${this.#rootPath}/search`, {
        params: filter,
      });
      return response.data ?? [];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const d407BillingService = new D407BillingService();
export default d407BillingService;
