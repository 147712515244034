import { IProps } from "../../../models/feam-entities";
import { formatDateForDateControl } from "../../../models/feam-utils";
import { useFeamFormControlProps } from "../../hooks";

export default function FeamFormInput<T>(props: IProps.IFeamFormInputProps<T>) {
    const [id, value, standardProps] = useFeamFormControlProps<IProps.IFeamFormInputProps<T>>(props);
    let val: string | Date = value;
    if (props.type === 'date' && val) {
        val = formatDateForDateControl(val);
    }
    return (
        //@ts-ignore
        <input {...standardProps} id={id} name={id} value={val || (props.value ?? '')} onChange={props.onChange || props.formProps?.handleChange} type={props.type ?? "text"} className={`form-control ${props.className} ${props.type && props.type.includes('date') ? "text-start" : ""}`} autoComplete="off" />
    );
}

