import { useEffect, useRef, useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { usePrint } from "../../../PageLayout";

export default function CwsMultiTailDescriptionTextArea(props: IProps.ICwsMultiTailDescriptionProps<FeamEntities.IWorkDetail>) {
    const [value, setValue] = useState(props.value);
    const [height, setHeight] = useState("1.30rem");
    const { isPrint } = usePrint();
    const descRef = useRef<any>(null);
    const { arraySelector, itemIndex, formProps } = props;

    useEffect(() => {

        if (props.value === '') {
            if (height === '1.30rem') {
                setHeight("21px")
            } else {
                setHeight("1.30rem")
            }
            setValue(props.value);
        } else {
            if (props.value || isPrint) {
                window.setTimeout(() => {
                    const input = descRef.current;
                    if (input) {
                        /*//@ts-ignore */
                        const setter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;
                        /*//@ts-ignore */
                        setter.call(input, props.value);
                        input.dispatchEvent(new Event('input', { bubbles: true }));
                        window.setTimeout(() => {
                            input.style.height = "";
                            input.style.height = input.scrollHeight + "px"
                        }, 200);
                    }
                }, 100);
            }
        }

    }, [props.value, isPrint])
    return (
        /*//@ts-ignore */
        <textarea ref={descRef} name={`${arraySelector}.${[itemIndex]}.workDetails.${[0]}.description`} id={`${arraySelector}.${[itemIndex]}.workDetails.${[0]}.description`} value={value ?? ''} onChange={changeHandler} className="form-control customer-worksheet-form-control" rows={1} style={{ minHeight: "1rem", overflow: "hidden", height: height }} onBlur={blurHandler} spellCheck={true} />
    )

    function changeHandler(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (e.target.value.trim() !== '') {
            e.target.style.height = "";
            e.target.style.height = e.target.scrollHeight + "px"
        }
        else {
            e.target.style.height = "1.30rem";
        }
        setValue(e.target.value ?? '');
    }
    function blurHandler(e: React.ChangeEvent<HTMLTextAreaElement>) {
        {/*//@ts-ignore */ }
        if (e.target.value !== '' && (!formProps.values.tailDetails[itemIndex].tail || (formProps.values.tailDetails[itemIndex].tail && formProps.values.tailDetails[itemIndex].tail === ''))) {
            alert("Please enter Tail before description. Entering description without Tail is not allowed.");
            setValue('');
            if (height === '1.30rem') {
                setHeight("21px")
            } else {
                setHeight("1.30rem")
            }
        } else {
            formProps.handleChange(e);
        }
    }
}