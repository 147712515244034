import { useState } from "react";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { FeamEntities } from "../../../models/feam-entities";
import NoRecordFound from "../common/NoRecordFound";
import { formatDateTime, isBillingApprover, isSuperAdmin } from "../../../models/feam-utils";
import useSortableData from "../../hooks/useSortableData";
import processingService from "../../../services/processing-service";
import { processingStatusType, resultStatusType, searchType } from "../../../models/constants";
import { CwsAdvancedSearchSection, CwsGeneralHeader } from "../common";
import { useFeamAuth } from "../../hooks";

export default function BillingProcessingStatusContainer() {
    const [data, setData] = useState<FeamEntities.IProcessing[]>([])
    const [searching, setSearching] = useState<boolean>(false);
    const { items, requestSort, sortConfig } = useSortableData(data, { key: "processingId", direction: "descending" });
    const currentUser = useFeamAuth();
    return (
        <div className="container cws-advance-search feam-m-5" >
            <CwsGeneralHeader title="Billing Processing Status" />
            <CwsAdvancedSearchSection onSearch={searchHandler} searchType={searchType.billingStatus} onClear={() => { setData([]) }} maintenanceTypesForDropdown={[]} searching={searching} />
            {items.length === 0 && <NoRecordFound message={"No Data found"} />}
            {items.length > 0 &&
                <div className="ps-3  pe-3">
                    <table className="table table-striped advanced-search-table">
                        <thead>
                            <tr className="text-uppercase">
                                <th scope="col" className="p-0"><button
                                    type="button"
                                    onClick={() => requestSort('invoiceNumber')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('invoiceNumber')}`}>Invoice Number</button></th>
                                <th scope="col" className="p-0"><button
                                    type="button"
                                    onClick={() => requestSort('customerId')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100  m-0 p-0 text-nowrap ${getClassNamesFor('customerId')}`}>Customer</button></th>
                                <th scope="col" className="p-0"><button
                                    type="button"
                                    onClick={() => requestSort('station')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0  text-nowrap " ${getClassNamesFor('station')}`}>Station</button></th>
                                <th scope="col" className="p-0"><button
                                    type="button"
                                    onClick={() => requestSort('weekNumber')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('weekNumber')}`}>Week Number</button></th>
                                <th scope="col" className="no-wordwrap p-0"><button
                                    type="button"
                                    onClick={() => requestSort('sendingDate')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('sendingDate')}`}>Process Start Date</button></th>
                                <th scope="col" className="no-wordwrap p-0"><button
                                    type="button"
                                    onClick={() => requestSort('endDate')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('endDate')}`}>End Date</button></th>
                                <th scope="col" className="no-wordwrap p-0"><button
                                    type="button"
                                    onClick={() => requestSort('createdBy')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 text-nowrap ps-1 ${getClassNamesFor('createdBy')}`}>Created By</button></th>

                                <th scope="col" className="no-wordwrap p-0"><button
                                    type="button"
                                    onClick={() => requestSort('statusName')}
                                    className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('statusName')}`}>Status</button></th>
                                {currentUser.user && (isSuperAdmin(currentUser.user) || isBillingApprover(currentUser.user)) && <th scope="col" className="p-0"></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {items?.map((item: FeamEntities.IProcessing, index) => {
                                return (
                                    <tr key={`${item.processingId}_${index}`}>
                                        <td>{item.invoiceNumber}</td>
                                        <td className="text-nowrap">{item.customer}</td>
                                        <td>{item.stationCode}</td>
                                        <td className="text-break">{item.weekNumber}</td>
                                        <td className="text-nowrap">{item.sendingDate ? formatDateTime(new Date(item.sendingDate)) : ''}</td>
                                        <td className="text-nowrap">{item.endDate ? formatDateTime(new Date(item.endDate)) : ''}</td>
                                        <td className="text-nowrap">{item.createdBy}</td>
                                        <td className="">
                                            <div className={`${getStatusColor(item.status)} text-white rounded p-1 d-flex justify-content-center align-items-center`} style={{ width: "71px", height: "21px" }}>
                                                {item.statusName}
                                            </div>
                                        </td>
                                        {currentUser.user && (isSuperAdmin(currentUser.user) || isBillingApprover(currentUser.user)) && <td className="text-center">
                                            <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(item.processingId, item.invoiceNumber)} title="Delete" />
                                        </td>}
                                    </tr>)
                            })}
                        </tbody>
                    </table></div>}
        </div>
    )

    function getClassNamesFor(name: string) {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    async function deleteHandler(processingId: number, invoiceNumber?: string) {

        if (window.confirm(`Are you sure! Do you want to delete Invoice: ${invoiceNumber}?`)) {
            const result = await processingService.deleteByIdAsync(processingId);
            const message = result === resultStatusType.success ? 'The Invoice has been deleted successfully.' :
                result === resultStatusType.failed ? 'Request to delete record has failed.' : 'The record you are trying to delete does not exists.';
            alert(message);
            if (result === resultStatusType.success) {
                const updatedData = data.filter(f => f.processingId !== processingId);
                setData(updatedData);
            }
        }
    };

    function getStatusColor(status?: number) {
        if (!status) return;
        switch (status) {
            case processingStatusType.queue: return "bg-secondary";
            case processingStatusType.inProgress: return "bg-info";
            case processingStatusType.created: return "bg-success";
            case processingStatusType.failed: return "bg-danger";

        }

    }
    async function searchHandler(filter: FeamEntities.IWorkSheetFilter) {
        setSearching(true);
        const result = await processingService.getProcesses(filter);
        setData(result);
        setSearching(false);
    }
}

