import { IProps } from "../../../models/feam-entities";
import entryVideo from "../../../assets/video/1-How-to-Enter-D407.mp4";
import printAndSaveVideo from "../../../assets/video/2-How-to-Print-N-Save-D407.mp4";
import updateVideo from "../../../assets/video/4-How-to-Update-a-D407.mp4";
import searchVideo from "../../../assets/video/3-How-to-search-for-D407.mp4";
import multiTailVideo from "../../../assets/video/5-How-to-Enter-a-MultiTail-D407.mp4";

export default function GuideVideo(props: IProps.IGuideVideoProps) {
    return (
        <div className="m-3">
            <video  height="auto" autoPlay controls className="embed-responsive w-100">
                <source src={getVideo()} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )

    function getVideo() {
        switch (props.value) {
            case "enter": return entryVideo;
            case "save": return printAndSaveVideo;
            case "update": return updateVideo;
            case "search": return searchVideo;
            case "multitail": return multiTailVideo;
        }
    }
}