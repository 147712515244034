import { FieldArray, FieldArrayRenderProps } from "formik";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { NoRecordFound } from "../common";
import { FeamFormArrayAutoCompleteInputForEmployee, FeamFormArrayFieldInput } from "../../common";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { useState } from "react";

export default function CwsPersonalExpensesForm(props: IProps.ICwsPersonalExpensesFormProps<FeamEntities.IExpenses>) {
    const [, setDeleteToggle] = useState(false);
    const { formProps, users } = props;
    const arraySelector = "expenses";
    return (
        <FieldArray
            name={arraySelector}
            render={(arrayHelper: FieldArrayRenderProps) =>
            (
                <div className="expenses-form" style={{ minHeight: "250px" }}>
                    <div className="d-flex  justify-content-between align-items-center header-col w-100 p-1 mb-2 rounded text-uppercase">
                        <div className="d-flex gap-3 align-items-center justify-content-start">
                            <span className="fw-bold">Personal Expenses</span>
                        </div>
                        <BootStrapIcon.PlusCircleFill role="button" className="text-secondary" size={16} onClick={() => { arrayHelper.push({}) }} title="Add Personal Expenses" />
                    </div>
                    {(!formProps.values.expenses || formProps.values.expenses.length === 0) && (
                        <NoRecordFound message="No Personal Expenses found" className="mb-3" />)}
                    {formProps.values.expenses !== undefined && formProps.values.expenses.length > 0 &&
                        <>
                            <table className="table table-ws">
                                <thead>
                                    <tr className="text-uppercase">
                                        <th scope="col" style={{ width: "200px" }}>Name</th>
                                        <th scope="col">Baggage</th>
                                        <th scope="col" className="text-nowrap">Rental Fuel</th>
                                        <th scope="col">Taxi</th>
                                        <th scope="col">Parking</th>
                                        <th scope="col">Mileage</th>
                                        <th scope="col">Other</th>
                                        <th scope="col" ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formProps.values.expenses?.map((_: FeamEntities.IPersonalExpense, index: number) => {
                                        return (
                                            <tr key={`expenses_${index}`}>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayAutoCompleteInputForEmployee formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="employeeName" className="customer-worksheet-form-control" data={[...users]} onchangeHandler={autoCompleteChangeHandler} />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="baggage" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="parking" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="rentalFuel" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="taxi" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="mileage" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="other" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    <td className="text-end">
                                                        <div className="d-flex d-inline-flex gap-1 align-items-center">
                                                            <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(index)} title="Delete Personal Expenses" />
                                                        </div>
                                                    </td>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                    }
                </div>
            )} />
    )
    function deleteHandler(index: number) {
        if (window.confirm(`Are you sure! You want to delete the ${formProps.values.expenses[index].employeeName} from Personal Expenses list.`)) {
            formProps.values.expenses?.splice(index, 1);
            setDeleteToggle(prev => !prev);
        }
    }
    function autoCompleteChangeHandler(id: string, newValue: FeamEntities.IPaylocityEmployeeForAutoInput | string) {
        const idSplit = id.split('.');
        const employeeCodeId = [idSplit[0], idSplit[1], "employeeCode"].join('.');
        const paylocityEmployeeId = [idSplit[0], idSplit[1], "paylocityEmployeeId"].join('.');
        if (typeof newValue === 'string') {
            // @ts-ignore
            formProps.setFieldValue(id, newValue);
        } else if (newValue && newValue.employeeCode) {
            // Create a new value from the user input
            // @ts-ignore
            formProps.setFieldValue(id, (newValue.firstName + " " + newValue.lastName ?? "").trim());
            formProps.setFieldValue(employeeCodeId, newValue.employeeCode);
            formProps.setFieldValue(paylocityEmployeeId, newValue.paylocityEmployeeId);
        } else {
            formProps.setFieldValue(id, newValue === null ? "" : newValue.value ?? "");
        }
    }
}