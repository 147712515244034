import { FeamEntities, IDropdown } from "../models/feam-entities";
import apiClient from "./http-common";

class WorkStatusTypeService {
  #rootPath: string = "workstatustype";
  async getAll(): Promise<IDropdown[]> {
    try {
      const res = await apiClient.get<FeamEntities.IWorkStatusType[]>(
        this.#rootPath,
      );
      const workStatusTypes = res.data.map<IDropdown>(
        (d: FeamEntities.IWorkStatusType): IDropdown => {
          return {
            text: d.name,
            value: d.workStatusTypeId.toString(),
          };
        },
      );

      return workStatusTypes;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const workStatusTypeService = new WorkStatusTypeService();
export default workStatusTypeService;
