import { feamUserInfo } from "../models/constants";
import { FeamEntities } from "../models/feam-entities";
import { resultStatus } from "./customer-worksheet-service";
import apiClient from "./http-common";

class UserRoleService {
  #rootPath: string = "d407userrole";

  async setUserRolesByEmailId(
    emailId: string,
  ): Promise<FeamEntities.ID407userRole | null> {
    try {
      if (
        localStorage.getItem(feamUserInfo) &&
        localStorage.getItem(feamUserInfo) !== ""
      ) {
        const user = localStorage.getItem(feamUserInfo);
        //@ts-ignore
        if (user?.userEmail === emailId) {
          return Promise.resolve(
            //@ts-ignore
            JSON.parse(localStorage.getItem(feamUserInfo)),
          );
        }
      }
      const res = await apiClient.get<FeamEntities.ID407userRole>(
        this.#rootPath,
        {
          params: { emailId: emailId },
        },
      );
      localStorage.setItem(feamUserInfo, JSON.stringify(res.data));
      return res.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return null;
  }

  async getUserRoles(): Promise<FeamEntities.ID407userRole> {
    return Promise.resolve(JSON.parse(localStorage.get(feamUserInfo)));
  }
  async getStationApproversByStation(
    stationId: number,
  ): Promise<FeamEntities.ID407userRole[]> {
    try {
      const res = await apiClient.get<FeamEntities.ID407userRole[]>(
        `${this.#rootPath}/stationapprovers`,
        {
          params: { stationId: stationId },
        },
      );
      return res.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }

  async searchUserRoles(
    filter: FeamEntities.IUserManagementFilter,
  ): Promise<FeamEntities.ID407userRole[]> {
    try {
      const response = await apiClient.get<FeamEntities.ID407userRole[]>(
        `${this.#rootPath}/search`,
        {
          params: filter,
        },
      );
      return response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }

  async dateUserRoles(
    uerRoleId: number,
    updatedBy: string,
  ): Promise<FeamEntities.ID407userRole[]> {
    try {
      const data = { userRoleId: uerRoleId, updatedBy: updatedBy };
      const response = await apiClient.patch<FeamEntities.ID407userRole[]>(
        `${this.#rootPath}/delete`,
        data,
      );
      return response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }

  async saveUserRole(
    data: FeamEntities.ID407userRole,
  ): Promise<{ status: string; id: number }> {
    try {
      if (!data.d407userRoleId) {
        let response = await apiClient.post<number>(this.#rootPath, data);
        return {
          status: resultStatus.saved,
          //@ts-ignore
          id: +response.data?.d407userRoleId,
        };
      } else {
        await apiClient.put(this.#rootPath, data);
        return { status: resultStatus.updated, id: data.d407userRoleId };
      }
    } catch (err: any) {
      console.log(err.response?.data || err);
      return { status: resultStatus.failed, id: -1 };
    }
  }
}

const userRoleService = new UserRoleService();

export default userRoleService;
