import { useEffect, useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { useFeamFormArrayFieldControlProps } from "../../hooks";

export default function FeamFormArrayFieldAutoInputForEmployee(props: IProps.IFeamFormArrayFieldAutoInputForEmployeeProps<FeamEntities.IPaylocityEmployee>) {
    const [id, value, standardProps] = useFeamFormArrayFieldControlProps(props);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [isHideSuggs, setIsHideSuggs] = useState(false);
    const [selectedVal, setSelectedVal] = useState(value || (props.value ?? ''));

    useEffect(() => { setSelectedVal(value || (props.value ?? '')) }, [value, props.value])

    // @ts-ignore
    delete standardProps.data;
    return (
        <div className="feam-suggestion-auto form">
            <div className="feam-form-control-auto form-group">
                <div className={`${props.outerDivClassName ?? "customer-worksheet-mname"}`}>
                    <input
                        id={id}
                        name={id}
                        placeholder={props.placeholder}
                        type="text"
                        value={value || (props.value ?? '')}
                        onChange={handleChange}
                        onKeyUp={keyUpHandler}
                        onClick={(e) => hideSuggs(selectedVal)}
                        onBlur={(e) => props.formProps?.handleBlur(e)}
                        className={`form-control ${props.className}`}
                        autoComplete="off"
                    />
                </div>
            </div>
            <div
                className="feam-suggestions list-group"
                style={{ display: isHideSuggs ? "none" : "block" }}>
                {suggestions.map((item, idx) => (
                    <a href="#" key={id + item + idx} onClick={(e) => { e.preventDefault(); hideSuggs(item); }} className="list-group-item list-group-item-action feam-suggestions-item">
                        {item}
                    </a>
                ))}
            </div>
        </div>
    );

    function keyUpHandler(e: React.KeyboardEvent<HTMLInputElement>) {
        let filterData: FeamEntities.IPaylocityEmployee[] = [...props.data?.filter(i => ((e.target as HTMLInputElement).value) !== '' && (i.firstName + " " + i.lastName ?? "").trim()?.toLocaleLowerCase().startsWith((e.target as HTMLInputElement).value.toLocaleLowerCase()))] ?? [];
        if (filterData.length > 0) {
            const data: string[] = filterData.map(item => {
                if (!item.terminationDate) {
                    return (item.employeeCode + "-" + item.firstName + " " + item.lastName ?? "").trim();
                }
                else {
                    return ("F" + "-" + item.employeeCode + "-" + item.firstName + " " + item.lastName ?? "").trim();
                }
            })
            setSuggestions(data);
        }
        else {
            setSuggestions([]);
            setIsHideSuggs(true);
        }
    };

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const input = e.target.value;
        setIsHideSuggs(!input ? true : false);
        setSelectedVal(input);
        props.formProps?.handleChange(e);
    };

    function hideSuggs(value: string) {
        const inputs = value.split('-');
        let empCode: string | undefined = undefined;
        let empName: string | undefined = undefined;
        if (isNaN(+inputs[0]) && inputs.length >= 3) {
            empCode = inputs[1];
            empName = '';
            for (let index = 2; index < inputs.length; index++) {
                empName += inputs[index];
                if (index + 2 < inputs.length) {
                    empName += '-';
                }
            }
        }
        else if (!isNaN(+inputs[0]) && inputs.length >= 2) {
            empCode = inputs[0];
            empName = '';
            for (let index = 1; index < inputs.length; index++) {
                empName += inputs[index];
                if (index + 1 < inputs.length) {
                    empName += '-';
                }
            }
        } else {
            empName = value;
        }
        if (empCode) {
            props.formProps?.setFieldValue(`${props.feamArraySelector}.${[props.feamItemIndex]}.employeeCode`, empCode);
        }
        setSelectedVal(empName);
        props.formProps?.setFieldValue(id, empName);
        setIsHideSuggs(true);

    };
}