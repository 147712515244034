import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { IProps } from "../../../models/feam-entities";
import { viewSectionAction } from "../../../models/constants";

export default function D407FormViewSelection(props: IProps.ID407FormViewSelectionProps) {
    const [dynamicView, setDynamicView] = useState(false);
    return (
        <Modal isOpen={(props.id !== undefined || props.isBulkPrint)}>
            <ModalHeader className="text-uppercase">
                View Selection
            </ModalHeader>
            <ModalBody className="p-3">
                <div className="mb-3">
                    <div className="form-check form-switch d-flex justify-content-between">
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"> Would you like to open in Dynamic View</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={dynamicView ?? false} onChange={(e) => { setDynamicView(e.target.checked); }} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-secondary" onClick={() => goHandler()} title="Go"><BootStrapIcon.Arrow90degRight size={16} />&nbsp;Go</button>
                <button type="button" className="btn btn-outline-secondary" onClick={() => props.resetData(viewSectionAction.cancel)} title="Cancel"><BootStrapIcon.DashCircleFill size={16} />&nbsp;Cancel</button>
            </ModalFooter>
        </Modal>
    )

    function goHandler() {
        if (!props.isBulkPrint) {
            if (props.id) {
                let url = window.location.protocol + '//' + window.location.host;
                url = dynamicView ? url + "/dynamic" : url;
                url = url + `?id=${props.id}`
                window.open(url, "_blank");
            }

        }
        props.resetData(dynamicView ? viewSectionAction.dynamic : viewSectionAction.classic);
        setDynamicView(false);
    }
}