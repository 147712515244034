import { IProps } from "../../models/feam-entities";

export default function useFeamFormArrayFieldControlProps<T>(
  props: IProps.IFeamFormArrayControlProps<T>
) {
  const id = `${props.feamArraySelector}.${[props.feamItemIndex]}.${
    props.feamSelector
  }`;
  /**@type {string|string[]|number} */

  let value =
    //@ts-ignore
    props.formProps.values[props.feamArraySelector][props.feamItemIndex][
      props.feamSelector
    ] || "";
  //@ts-ignore
  // if (props.type==="date") {
  //   value = value?.split('T')[0];
  // }
  // Remove all extra props and return standard props to be spread to input
  const standardProps = { ...props };
  delete standardProps.formProps;
  delete standardProps.feamSelector;
  //@ts-ignore
  delete standardProps.feamItemIndex;
  //@ts-ignore
  delete standardProps.feamArraySelector;
  return ([, ,] = [id, value, standardProps]);
}
