import { avionicsType, leadsTypes, mechanicsTypes, regularPay, supervisorsTypes, overtimePay } from "../../../models/constants";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { sumOfTimes } from "../../../models/feam-utils";

export default function HoursSummary(props: IProps.IHoursSummaryProps<FeamEntities.ID407Data>) {
  return (
    <div className="card">
      <div className="card-body m-0 p-0 header-col  rounded">
        <table className="table m-0 p-0 dynamic-tail-table">
          <thead className="header-col">
            <tr className="text-uppercase header-col">
              <th scope="col">Position</th>
              <th scope="col">RT</th>
              <th scope="col">OT</th>
              <th scope="col">Other</th>
              <th scope="col">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row" className="text-uppercase fw-bold">MECHANICS</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => mechanicsTypes.includes(x.workTitleCode) && regularPay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => mechanicsTypes.includes(x.workTitleCode) && overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => mechanicsTypes.includes(x.workTitleCode) && !regularPay.includes(x.payCode) && !overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => mechanicsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
              )}</td>
            </tr>
            <tr>
              <td scope="row" className="text-uppercase fw-bold">AVIONICS</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => avionicsType.includes(x.workTitleCode) && regularPay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => avionicsType.includes(x.workTitleCode) && overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => avionicsType.includes(x.workTitleCode) && !regularPay.includes(x.payCode) && !overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => avionicsType.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
              )}</td>
            </tr>
            <tr>
              <td scope="row" className="text-uppercase fw-bold">SUPERVISORS</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => supervisorsTypes.includes(x.workTitleCode) && regularPay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => supervisorsTypes.includes(x.workTitleCode) && overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => supervisorsTypes.includes(x.workTitleCode) && !regularPay.includes(x.payCode) && !overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => supervisorsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
              )}</td>
            </tr>
            <tr>
              <td scope="row" className="text-uppercase fw-bold">LEADS</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => leadsTypes.includes(x.workTitleCode) && regularPay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => leadsTypes.includes(x.workTitleCode) && overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => leadsTypes.includes(x.workTitleCode) && !regularPay.includes(x.payCode) && !overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => leadsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
              )}</td>
            </tr>
            <tr>
              <td scope="row" className="text-uppercase fw-bold">OTHER</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => !(mechanicsTypes.includes(x.workTitleCode) || supervisorsTypes.includes(x.workTitleCode) || avionicsType.includes(x.workTitleCode) || leadsTypes.includes(x.workTitleCode)) && regularPay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => !(mechanicsTypes.includes(x.workTitleCode) || supervisorsTypes.includes(x.workTitleCode) || avionicsType.includes(x.workTitleCode) || leadsTypes.includes(x.workTitleCode)) && overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => !(mechanicsTypes.includes(x.workTitleCode) || supervisorsTypes.includes(x.workTitleCode) || avionicsType.includes(x.workTitleCode) || leadsTypes.includes(x.workTitleCode)) && !regularPay.includes(x.payCode) && !overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
              )}</td>
              <td>{sumOfTimes(
                //@ts-ignore
                props.formProps.values.mechanicsAssigneds?.filter(x => !(mechanicsTypes.includes(x.workTitleCode) || supervisorsTypes.includes(x.workTitleCode) || avionicsType.includes(x.workTitleCode) || leadsTypes.includes(x.workTitleCode))).flatMap(x => x.shiftHours)
              )}</td>
            </tr>

            <tr>
              <td scope="row" className="text-uppercase fw-bold" colSpan={4}>TOTAL</td>
              <td className="fw-bold">{props.formProps.values.totalSum}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}