import { IProps } from "../../../models/feam-entities";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import OutlinedInputClasses from '@mui/material/OutlinedInput/outlinedInputClasses'
import { useFeamFormControlProps } from '../../hooks';
import dayjs from "dayjs";
export default function FeamFormTimeFieldInput<T>(props: IProps.IFeamFormInputProps<T>) {
    const [id, value, standardProps] = useFeamFormControlProps(props);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
                {...standardProps}
                id={id}
                name={id}
                value={dayjs('2023-06-20T' + (value || (props.value ?? '--:--')))}

                onChange={changeHandler}
                format="HH:mm"
                slotProps={{
                    textField: {
                        sx: {
                            borderRadius: 2,
                            fieldset: { borderRadius: 2 },
                            [`.${OutlinedInputClasses.root}`]: {
                                height: 22,
                                width: 68,
                                fontSize: 'small'
                            },
                            "& .MuiInputBase-root": {
                                "& fieldset.MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#dee2e6",
                                },
                                "& input.MuiInputBase-input": {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 1,
                                    paddingRight: 1
                                }
                            },

                        },
                    },
                }}
                className={props.className}
            />
        </LocalizationProvider>
    )

    function changeHandler(e: any) {
        if (e) {
            const hours = e.hour() < 10 ? '0' + e.hour() : e.hour();
            const minute = e.minute() < 10 ? '0' + e.minute() : e.minute();
            props.formProps?.setFieldValue(id, hours + ':' + minute)
        } else {
            props.formProps?.setFieldValue(id, '')
        }
    }
}