import { IProps } from "../../../../models/feam-entities";

export default function WorkAccomplishedOfSummaryReport(props: IProps.IWorkAccomplishedOfSummaryReportProps) {
    return (
        <div><div className="d-flex mt-1 mb-1 d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
            WORK ACCOMPLISHED:
        </div>
            <div className="d-flex d-inline-flex flex-row gap-3 w-100 p-1 mb-3" >
                <div className="d-flex flex-row flex-fill gap-3">
                    <span className="fw-bold">Repair Station:</span>
                    <input type="checkbox" checked={props.summaryReport.repairStation ?? false} className="form-check-input" disabled={true} />
                </div>
                <div className="d-flex flex-fill flex-row  gap-3">
                    <span className="d-flex fw-bold">Air Carrier:</span>
                    <input type="checkbox" checked={props.summaryReport.airCarrier ?? false} className="form-check-input" disabled={true} />
                </div>
            </div></div>
    )
}