import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class UserRoleTypeService {
  #rootPath: string = "d407userroletype";

  async get(): Promise<FeamEntities.ID407userRoleType[]> {
    try {
      const response = await apiClient.get<FeamEntities.ID407userRoleType[]>(
        `${this.#rootPath}`,
      );
      return response.data ?? [];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const userRoleTypeService = new UserRoleTypeService();
export default userRoleTypeService;
