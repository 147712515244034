import { Fragment } from "react";
import { IProps } from "../../../../models/feam-entities";
import { NoRecordFound } from "../../common";

export default function TailSummary(props: IProps.ITailSummary) {
    /** @ts-ignore */
    const tailNumbersKeys = Object.keys(props.rdata.tailNumbers);
    const tailNumbersKeysCount = tailNumbersKeys.length;
    return (
        <div className="d-flex flex-column border w-100 rounded mt-3">
            <div className="d-flex mb-1 d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
                <span>Tail Summary:</span>
            </div>
            <div className="w-100 p-1">
                { /*@ts-ignore next-line*/}
                {(!props.rdata.tailNumbers || Object.keys(props.rdata.tailNumbers).length === 0) && <NoRecordFound message={"No Data found"} />}
                {props.rdata.tailNumbers && Object.keys(props.rdata.tailNumbers).length > 0 && <table className="table">
                    <thead>
                        <tr>
                            <th>Tail/AC#</th>
                            <th>Count</th>
                            <th>Tail/AC#</th>
                            <th>Count</th>
                            <th>Tail/AC#</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        { /*@ts-ignore next-line*/}

                        {tailNumbersKeys.map((k: string, index: number) => {
                            return (
                                <Fragment key={`${k}_${index}`}>
                                    {(index % 3 === 0) && (
                                        <tr>
                                            <td>{k}</td>
                                            { /*@ts-ignore next-line*/}
                                            <td>{props.rdata.tailNumbers[k]?.length}</td>
                                            { /*@ts-ignore next-line*/}
                                            <td> {tailNumbersKeysCount > index + 1 && tailNumbersKeys[index + 1]}</td>
                                            { /*@ts-ignore next-line*/}
                                            <td>{tailNumbersKeysCount > index + 1 && props.rdata.tailNumbers[tailNumbersKeys[index + 1]]?.length}</td>
                                            <td> {tailNumbersKeysCount > index + 2 && tailNumbersKeys[index + 2]}</td>
                                            { /*@ts-ignore next-line*/}
                                            <td>{tailNumbersKeysCount > index + 2 && props.rdata.tailNumbers[tailNumbersKeys[index + 2]]?.length}</td>
                                        </tr>)}
                                </Fragment>
                            )
                        })
                        }
                    </tbody>
                </table>}
            </div>
        </div>
    )
}