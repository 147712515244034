import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class CobblestoneContractsBillingInfoService {
  #rootPath: string = "cobblestonecontractsbillinginfo";
  async searchCobblestoneContractsBillingInfo(
    filter: FeamEntities.IWorkSheetFilter
  ): Promise<FeamEntities.ICobblestoneContractsBillingInfo[]> {
    try {
      const response = await apiClient.get<
        FeamEntities.ICobblestoneContractsBillingInfo[]
      >(`${this.#rootPath}/search`, {
        params: filter,
      });
      return response.data ?? [];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const cobblestoneContractsBillingInfoService =
  new CobblestoneContractsBillingInfoService();
export default cobblestoneContractsBillingInfoService;
