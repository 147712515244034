import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormArrayAutoCompleteInputForEmployee, FeamFormArrayFieldInput, FeamFormArrayFieldSelect } from "../../common";
import * as BootStrapIcon from 'react-bootstrap-icons';

export default function CwsMechanicsAssignedForAogForm(props: IProps.ICwsMechanicsAssignedForAogFormProps<FeamEntities.IMechanics>) {
    const { formProps, deleteHandler, users, workTitles, payTypes } = props;
    return (
        <table className="table table-ws">
        <thead>
            <tr className="text-uppercase">
                <th scope="col" style={{ width: "150px" }}>NAME</th>
                <th scope="col" style={{ width: "90px" }} className="text-nowrap">Start Date</th>
                <th scope="col" style={{ width: "90px" }} className="text-nowrap">End Date</th>
                <th scope="col" style={{ width: "50px" }}>Hours</th>
                <th scope="col" style={{ width: "100px" }}>Pay</th>
                <th scope="col" style={{ width: "100px" }}>Position</th>
                <th scope="col" style={{ width: "20px" }}></th>
            </tr>
        </thead>
        <tbody>
            {formProps.values.mechanics?.map((_: FeamEntities.IMechanicsAssigned, index: number) => {
                return (
                    <tr key={`mechanicsAssigned_${index}`}>
                        <td>
                            {/**@ts-ignore */}
                            <FeamFormArrayAutoCompleteInputForEmployee formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="mechanicName" className="customer-worksheet-form-control" data={[...users]} onchangeHandler={props.onAutoCompleteChangeHandler} />
                        </td>
                        <td >
                            {/**@ts-ignore */}
                            <FeamFormArrayFieldInput type="datetime-local" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftStartDate" onBlur={(e) => { setTimeDiff('mechanics', e.target.value, formProps.values.mechanics[index].shiftEndDate, index); formProps.handleBlur(e) }} className="customer-worksheet-form-tcontrol" max={formProps.values.mechanics[index].shiftEndDate} style={{width:"150px"}}/>
                        </td>
                        <td>
                            {/**@ts-ignore */}
                            <FeamFormArrayFieldInput type="datetime-local" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftEndDate" onBlur={(e) => { setTimeDiff('mechanics',formProps.values.mechanics[index].shiftStartDate,e.target.value, index); formProps.handleBlur(e) }} className="customer-worksheet-form-tcontrol"  min={formProps.values.mechanics[index]?.shiftStartDate} style={{width:"150px"}}/>
                        </td>
                        <td>
                            {/**@ts-ignore */}
                            <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftHours" className="customer-worksheet-form-tcontrol" readOnly={true} />
                        </td>
                        <td>
                            {/**@ts-ignore */}
                            <FeamFormArrayFieldSelect defaultText="Pay" data={[...payTypes]} formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="payCode" className="customer-worksheet-form-control" />
                        </td>
                        <td>
                            {/**@ts-ignore */}
                            <FeamFormArrayFieldSelect defaultText="Role" data={[...workTitles]} formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="workTitleCode" className="customer-worksheet-form-control" />
                        </td>
                        <td>
                            <td className="text-end">
                                <div className="d-flex d-inline-flex gap-1 align-items-center">
                                    <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(index)} title="Delete Mechanics" />
                                </div>
                            </td>
                        </td>
                    </tr>
                )
            })}
        </tbody>
    </table>
    )
 
    function setTimeDiff(arraySelector: string,start: string, end: string, index: number) {
        if ((!start || !end)) {
            if (start && !end) {
                formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftEndDate"}`, start);
            }
            formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, "");
            return;
        }
        let startDate = new Date(start);
        let endDate = new Date(end);
        let diff = endDate.getTime() - startDate.getTime();
        const hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(diff / 1000 / 60);
        const hm = (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
        formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, hm);
    }
}