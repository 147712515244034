import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useFeamFormControlProps } from '../../hooks';
import { IDropdown, IProps } from '../../../models/feam-entities';
import { FilterOptionsState } from '@mui/base';
import { autoCompleteStyle } from '../../../models/autoCompleteStyle';

const filter = createFilterOptions<IDropdown>()

export default function FeamFormAutoCompleteInput<T>(props: IProps.IFeamFormAutoCompleteInputProps<T>) {
    const [id, value, standardProps] = useFeamFormControlProps<IProps.IFeamFormAutoInputProps<T>>(props);
    const { formProps, data } = props;
    // @ts-ignore
    delete standardProps.data;
    // @ts-ignore
    delete standardProps.disableAdd;
    // @ts-ignore
    delete standardProps.customOnChange
    return (
        <Autocomplete
            // @ts-ignore
            value={formProps ? (value ?? '') : props.value ?? ''}
            // @ts-ignore
            onChange={changeHandler}
            filterOptions={filterHandler}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            readOnly={props.readOnly}
            id={id}
            options={data}
            // @ts-ignore
            getOptionLabel={(option) => {
                if (typeof option === 'string') return option;
                if (option.value) return option.value;
                return option.text;
            }}
            renderOption={(props, option) => <li {...props} className='feamAutoComplete'>{option.text}</li>}
            sx={autoCompleteStyle}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} className={`form-control ${props.className}`} placeholder={props.placeholder} />
            )}
        />

    )
    function filterHandler(options: IDropdown[], params: FilterOptionsState<IDropdown>) {
        const filtered = filter(options, params);
        if (!props.disableAdd) {
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.value);
            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    value: inputValue,
                    text: `Add "${inputValue}"`,
                });
            }
        }
        return filtered;
    }

    function changeHandler(event: React.SyntheticEvent, newValue: IDropdown) {

        if (typeof newValue === 'string') {
            // @ts-ignore
            if (formProps) {
                formProps.setFieldValue(id, newValue);
            } else if (props.customOnChange) {
                props.customOnChange(newValue);
            }
        } else if (newValue && newValue.value) {

            // Create a new value from the user input
            if (formProps) {
                formProps.setFieldValue(id, newValue.value);
            } else if (props.customOnChange) {
                // @ts-ignore
                props.customOnChange(props.textAsValue ? newValue.text : newValue.value);
            }
        } else {
            if (formProps) {
                formProps.setFieldValue(id, newValue);
            } else if (props.customOnChange) {
                // @ts-ignore
                props.customOnChange(newValue);
            }
        }
    }
}
