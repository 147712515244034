import { useMsal } from "@azure/msal-react";
import logo from "./assets/images/logo.png";
import "./FeamNavBar.css";
import * as BootStrapIcon from "react-bootstrap-icons";
import { feamUserInfo } from "./models/constants";
import * as utils from "./models/feam-utils";
import { useState } from "react";
import { FeamEntities } from "./models/feam-entities";

export default function FeamNavBar() {
  const { instance, accounts } = useMsal();
  const [user, setUser] = useState<FeamEntities.ID407userRole | undefined>(localStorage.getItem(feamUserInfo) ? JSON.parse(localStorage.getItem(feamUserInfo) ?? '') : undefined);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark feam-navbar feam-navbar-bg-color">
      <div className="container-fluid ">
        <a
          className="navbar-brand"
          href={`${process.env.REACT_APP_REDIRECT_URI}/`}
        >
          <img src={logo} alt="" width="162" height="65" className="" />
        </a>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent "
        >
          <ul className="navbar-nav me-auto mb-0 mb-lg-0 ms-5 gap-3">
            {!utils.isBillingApprover(user) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive(" ") ? "active" : ""}`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/`}
                  rel="noreferrer"
                >
                  D407Classic
                </a>
              </li>
            )}
            {!utils.isBillingApprover(user) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive("dynamic") ? "active" : ""}`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/dynamic`}
                  rel="noreferrer"
                >
                  D407Dynamic
                </a>
              </li>
            )}

            <li className="nav-item">
              <a
                className={`nav-link text-uppercase ${isActive("search") ? "active" : ""
                  }`}
                aria-current="page"
                href={`${process.env.REACT_APP_REDIRECT_URI}/search`}
                rel="noreferrer"
              >
                Search
              </a>
            </li>
            {utils.isSuperAdmin(user) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive("reports") ? "active" : ""}`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/reports`}
                  rel="noreferrer"
                >
                  Reports
                </a>
              </li>
            )}
            {(utils.isSuperAdmin(user) || utils.isBillingApprover(user)) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive("billing") ? "active" : ""
                    }`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/billing`}
                  rel="noreferrer"
                >
                  Billing
                </a>
              </li>
            )}
            {(utils.isSuperAdmin(user) || utils.isBillingApprover(user)) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive("status") ? "active" : ""
                    }`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/status`}
                  rel="noreferrer"
                >
                  Status
                </a>
              </li>
            )}
            {utils.isSuperAdmin(user) && (
              <li className="nav-item">
                <a
                  className={`nav-link text-uppercase ${isActive("users") ? "active" : ""
                    }`}
                  aria-current="page"
                  href={`${process.env.REACT_APP_REDIRECT_URI}/users`}
                  rel="noreferrer"
                >
                  Users
                </a>
              </li>
            )}
            <li className="nav-item">
              <a
                className={`nav-link text-uppercase ${isActive("help") ? "active" : ""}`}
                href={`${process.env.REACT_APP_REDIRECT_URI}/help`}
                rel="noreferrer"
              >
                Help
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <span className="text-white">Welcomes: {accounts[0].name}</span>
          <a
            role="button"
            className="ms-3"
            title="Sign Out"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              signOutHandler();
            }}
            style={{ color: "#ff690d" }}
            rel="noreferrer"
          >
            <span>
              <BootStrapIcon.BoxArrowRight size={24} className="me-1" />
              Sign Out
            </span>
          </a>
        </div>
      </div>
    </nav>
  );

  function signOutHandler() {
    if (window.confirm(`Are you sure! You want to Sign Out.`)) {
      instance.logoutRedirect({ account: instance.getActiveAccount() });
      localStorage.setItem(feamUserInfo, "");
    }
  }

  function isActive(page: string) {
    const path = window.location.pathname;
    if (
      page === " " &&
      !path.includes("search") &&
      !path.includes("help") &&
      !path.includes("billing") &&
      !path.includes("status") &&
      !path.includes("reports") &&
      !path.includes("users") &&
      !path.includes("dynamic")
    ) {
      return true;
    }
    if (path.includes(page)) {
      return true;
    }
  }
}
