import { IProps } from "../../../models/feam-entities";

export default function ContractsInfo(props: IProps.IContractsInfoProps) {
  return (
    <div className="p-1 ps-2 w-100">
      <table className="table w-100">
        <thead>
          <tr>
            <th className="text-nowrap">D407 Customer Name</th>
            <th className="text-nowrap">Contract Title</th>
            <th className="text-nowrap">Location Name</th>
            <th className="text-nowrap">Status Name</th>
          </tr>
        </thead>
        <tbody>
          {props.contractsInfos.map((h, i) => {
            return (
              <tr key={`contractInfo_${i}`}>
                <td>{h.d407CustomerName}</td>
                <td>{h.cobblestoneContractTitle}</td>
                <td>{h.cobblestoneLocationName}</td>
                <td>{h.cobblestoneStatusName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
