import { useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormAutoCompleteInput, FeamFormSelect } from "../../common";
import * as BootStrapIcon from "react-bootstrap-icons";

export default function UsersManagementSearch(props: IProps.IUsersManagementSearchProps) {
    const [filter, setFilter] = useState<FeamEntities.IUserManagementFilter>({ employeeCode: '', stationId: undefined, userRoleTypeId: undefined, isInActive: false })
    return (
        <div className="mt-3  pb-3 feam-advance-search">
            <div id="searchSection" className="d-flex justify-content-between align-items-center header-col w-100 p-2 fw-bold rounded text-uppercase">
                <span>Search & Add User Roles</span>
                <div className="form-check d-inline-flex gap-2 pe-2 align-items-center">
                    <input type="checkbox" name="includeAogOss" checked={filter.isInActive ?? false} onChange={isInActiveCheckedHandler} className="form-check-input mb-1" />
                    <label className="customer-worksheet-label form-check-label pt-1">
                        Deleted User Role
                    </label>
                </div>
            </div>
            <div className="d-flex d-inline-flex justify-content-start gap-3 w-100">
                <div className="input-group w-100 mt-2 ps-2 pe-3 search-auto-input" title="">
                    <FeamFormAutoCompleteInput  data={props.users} placeholder="--By User--" className="search-auto-input" value={filter.employeeCode} customOnChange={(e) => { setFilter((prev) => ({ ...prev, employeeCode: e })) }} disableAdd={true} textAsValue={true} />
                    <FeamFormSelect defaultText="By User Role Type" data={[...props.userRoleTypes]} value={filter.userRoleTypeId?.toString()} onChange={(e) => { setFilter((prev) => ({ ...prev, userRoleTypeId: e.target.value ? +e.target.value : undefined })) }} className="customer-worksheet-form-select" />
                    <FeamFormSelect defaultText="By Station" data={[...props.stations]} value={filter.stationId ? filter.stationId.toString() : ''} onChange={(e) => { setFilter((prev) => ({ ...prev, stationId: e.target.value ? +e.target.value : undefined })) }} className="customer-worksheet-form-select" hideLoader={false} />
                    <button type="button" title="Search" className="btn btn-secondary ms-1" onClick={() => searchHandler()}>
                        {!props.searching ? <BootStrapIcon.Search size={16} /> : (<>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" ></span> Searching...
                        </>)}
                    </button>
                    {!filter.isInActive && <button type="button" title="Add User Role" className={`btn btn-primary d-flex flex-inline align-items-center`} onClick={props.onAdd} disabled={props.searching}><BootStrapIcon.PersonAdd size={16} /></button>}
                    <button type="button" title="Clear" className="btn btn-outline-secondary text-uppercase d-flex m-0 padding-2 justify-content-center align-items-center" onClick={() => clearHandler()}>
                        <BootStrapIcon.Eraser size={16} />
                    </button>

                </div>
            </div>

        </div>
    )

    async function isInActiveCheckedHandler(e: React.ChangeEvent<HTMLInputElement>) {
        props.onSearch({ employeeCode: filter.employeeCode ? filter.employeeCode.split('-')[0].trim() : '', stationId: filter.stationId, userRoleTypeId: filter.userRoleTypeId, isInActive: e.target.checked });
        setFilter((prev) => ({
            ...prev,
            isInActive: e.target.checked,
        }))
    }

    async function searchHandler() {
        await props.onSearch({ employeeCode: filter.employeeCode ? filter.employeeCode.split('-')[0].trim() : '', stationId: filter.stationId, userRoleTypeId: filter.userRoleTypeId, isInActive: filter.isInActive });
    }
    function clearHandler() {
        props.onClear();
        setFilter({ employeeCode: '', stationId: undefined, userRoleTypeId: undefined, isInActive: false });
    }
}
