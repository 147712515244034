import { Fragment } from "react";
import { IProps } from "../../../../models/feam-entities";
import { workTitles } from "../../../../models/constants";
import { sumOfTimes } from "../../../../models/feam-utils";
import { NoRecordFound } from "../../common";
export default function MechanicWorkDetail(props: IProps.IMechanicWorkDetail) {
    return (
        <div className="d-flex flex-column border w-100 rounded mt-3">
            <div className="d-flex mb-1 d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
                <span>{workTitles.mechanics}:</span>
                <span>{props.mechanicsDetail && props.mechanicsDetail.length > 0 ? sumOfTimes([sumOfTimes(props.mechanicsDetail.flatMap(x => x.reg ?? "00:00")), sumOfTimes(props.mechanicsDetail.flatMap(x => x.ot ?? "00:00"))]) : "00:00"} hrs</span>
            </div>
            <div className="w-100 p-1">
                { /*@ts-ignore next-line*/}
                {(!props.mechanicsDetail || props.mechanicsDetail.length === 0) && <NoRecordFound message={"No Data found"} />}
                {props.mechanicsDetail && props.mechanicsDetail.length > 0 && <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>REG</th>
                            <th className="ps-2">OT</th>
                            <th className="ps-3">Name</th>
                            <th>REG</th>
                            <th className="ps-2">OT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.mechanicsDetail.map((wDetail, mwindex) => {
                            const isLastRecord = props.mechanicsDetail.length - 1 === mwindex;
                            return (
                                <Fragment key={`mDetail_${mwindex}`}>
                                    {(mwindex % 2 === 0) && (
                                        <tr >
                                            <td>{wDetail.name}</td>
                                            <td>{wDetail.reg ?? "00:00"}</td>
                                            <td className="ps-2">{wDetail.ot ?? "00:00"}</td>
                                            <td className="ps-3"> {!isLastRecord ? props.mechanicsDetail[mwindex + 1].name : ''}</td>
                                            <td>{!isLastRecord ? (props.mechanicsDetail[mwindex + 1].reg ?? "00:00") : ''}</td>
                                            <td className="ps-2">{!isLastRecord ? (props.mechanicsDetail[mwindex + 1].ot ?? "00:00") : ''}</td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                        <tr>
                            <td className="fw-bold text-uppercase" colSpan={4}>TOTAL</td>
                            <td className="fw-bold text-uppercase">{sumOfTimes(props.mechanicsDetail.flatMap(x => x.reg ?? "00:00"))}</td>
                            <td className="fw-bold text-uppercase">{sumOfTimes(props.mechanicsDetail.flatMap(x => x.ot ?? "00:00"))}</td>
                        </tr>
                    </tbody>
                </table>}
            </div>
        </div>
    )
}