import { useEffect, useState } from "react";
import { FeamEntities, IDropdown, IProps } from "../../../models/feam-entities";
import { FeamFormInput, FeamFormSelect } from "../../common";
import { customerService, customerWorksheetService } from "../../../services";
import stationService from "../../../services/station-service";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { Fade } from "reactstrap";
import { useTokenState } from "../../../RequestInterceptors";

export default function CwsSearchSection(props: IProps.ICwsSearchSection<FeamEntities.ID407Data>) {
  const [dropdownData, setDropdownData] = useState<{ customers: IDropdown[], stations: IDropdown[] }>({ customers: [], stations: [] });
  const [searchFilter, setSearchFilter] = useState<FeamEntities.IWorkSheetFilter>({ tail: '', customerId: undefined, stationId: undefined })
  const [customerWorksheetIds, setCustomerWorksheetIds] = useState<IDropdown[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchStatus, setSearchStatus] = useState<"Found" | "NotFound" | undefined>(undefined);
  const { isTokenSet } = useTokenState();
  useEffect(() => {
    if (isTokenSet && (dropdownData.customers.length === 0 || dropdownData.stations.length === 0)) {
      const fetchData = async () => {
        const result = await Promise.all([customerService.getAll(), stationService.getAll()]);
        setDropdownData({ customers: result[0], stations: result[1] })
      }
      fetchData();
    }
  }, [isTokenSet])

  useEffect(() => {
    if (searching) {
      const fetchData = async () => {
        const data = await customerWorksheetService.searchCustomerWorksheet(searchFilter);
        setCustomerWorksheetIds(data);
        setSearchStatus(data.length === 0 ? "NotFound" : "Found");
        props.formProps.resetForm({ values: props.resetForm() })
        setSearching(false);
      };
      fetchData();
    }
  }, [searching]);

  return (
    <div className="mt-3 gap-1">
      <div className="d-flex gap-3 m-0 mb-1 p-0">
        <button
          type="button"
          className="btn btn-light-secondary btn-block w-100 text-uppercase m-0 padding-1  justify-content-center align-items-center"
          onClick={() => setShowSearch(p => !p)}
          aria-controls="search"
          aria-expanded={showSearch}>
          <BootStrapIcon.Search size={16} /> {!showSearch ? " Show Search" : " Hide Search"}
        </button>
        <button type="button" title="Clear" className="btn btn-light-secondary text-uppercase d-flex m-0 padding-1 justify-content-center align-items-center" onClick={() => clear()}> <BootStrapIcon.Eraser size={16} />Clear</button>
      </div>
      {showSearch && <Fade in={showSearch}>
        <div id="searchSection" className="d-flex justify-content-start align-items-center header-col w-100 p-1 fw-bold mb-2 rounded text-uppercase">Search</div>
        <div className="d-flex d-inline-flex justify-content-start gap-3 w-100 mb-3">
          <div className="input-group w-75">
            <FeamFormSelect defaultText="By Customer" data={[...dropdownData.customers]} value={searchFilter.customerId} onChange={(e) => setSearchFilter((prev) => ({ ...prev, customerId: e.target.value ? +e.target.value : undefined }))} className="customer-worksheet-form-select" hideLoader={true} />
            <FeamFormInput placeholder="By AC/Tail Number..." className="form-control customer-worksheet-search-control" value={searchFilter.tail} onChange={(e) => setSearchFilter((prev) => ({ ...prev, tail: e.target.value }))} />
            <FeamFormSelect defaultText="By Station" data={[...dropdownData.stations]} value={searchFilter.stationId} onChange={(e) => setSearchFilter((prev) => ({ ...prev, stationId: +e.target.value }))} className="customer-worksheet-form-select" hideLoader={true} />
            <button type="button" title="Search" className="btn btn-secondary" onClick={searchHandler}>{!searching ? <BootStrapIcon.Search size={16} /> : (<>  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Searching...</>)}</button>
          </div>
          <div className="d-inline-flex w-25">
            <FeamFormSelect defaultText={searchStatus === undefined ? "Search Station" : searchStatus === "NotFound" ? "No Record Found" : "Select Id"} data={customerWorksheetIds} onChange={(e) => customerWorksheetById(e.target.value)} className={`customer-worksheet-form-select ${searchStatus === undefined ? "" : searchStatus === "NotFound" ? 'feam-customerD407-search-notfound-result-bg-color' : "feam-customerD407-search-result-bg-color"}`} hideLoader={true} value={props.workSheetId} />
          </div>
        </div>
      </Fade>}
    </div>
  )

  function searchHandler() {
    if (!searchFilter.customerId && !searchFilter.tail) {
      alert("The search requires customer or tail.");
      return;
    }
    setSearching(true);
  }

  async function customerWorksheetById(value: string) {
    await props.customerWorksheetById(value);
  }
  function clear() {
    props.onClear();
    setCustomerWorksheetIds([]);
    setSearchStatus(undefined);
    setSearchFilter({ tail: '', customerId: undefined, stationId: undefined });
  }
}