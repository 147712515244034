import { FeamEntities, IDropdown } from "../models/feam-entities";
import feamCache from "./cache";
import apiClient from "./http-common";

class StationService {
  #rootPath: string = "station";
  #key: string = "feam_stations";

  async getAll(): Promise<IDropdown[]> {
    try {
      if (feamCache.has(this.#key)) {
        return feamCache.get(this.#key);
      }
      const res = await apiClient.get<FeamEntities.IStation[]>(this.#rootPath);

      const stations = res.data.map<IDropdown>(
        (d: FeamEntities.IStation): IDropdown => {
          return {
            text: d.stationCode,
            value: d.id?.toString(),
          };
        },
      );
      if (stations.length > 0) {
        feamCache.set(this.#key, [...stations]);
      }
      return stations;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
  async add(station: FeamEntities.IStation): Promise<void> {
    if (!station.stationCode) return;
    try {
      if (feamCache.has(this.#key)) {
        const stations = feamCache.get(this.#key);
        if (
          !stations.find(
            (x: IDropdown) =>
              x.value?.trim().toLocaleLowerCase() ===
              station.stationCode?.trim().toLocaleLowerCase(),
          )
        ) {
          await apiClient.post(this.#rootPath, station);
          stations.push({
            text: station.stationCode,
            value: station.stationCode,
          });
          feamCache.set(this.#key, [...stations]);
        }
      }
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
  }
}

const stationService = new StationService();
export default stationService;
