import { FeamEntities, IProps } from "../../../models/feam-entities";
import { viewSectionAction } from "../../../models/constants";
import CustomerWorkSheet from "../old-version/CustomerWorkSheet";
import { DynamicWorkSheet } from "../customer-worksheet-dynamic";

export default function BulkPrint(props: IProps.IBulkPrintProps) {
    return (
        <>
            {props.d407Data.map((data: FeamEntities.ID407Data, index) => {
                return (
                    <div key={`bulkPrint_${index}`}>
                        {props.selectedView === viewSectionAction.classic && <CustomerWorkSheet d407Data={data} />}
                        {props.selectedView === viewSectionAction.dynamic && <DynamicWorkSheet d407Data={data} />}
                        {index <= props.d407Data.length - 2 && <div className="pagebreak"></div>}
                    </div>)
            })

            }
        </>
    )
}