import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class CobblestoneContractsServiceTypeService {
  #rootPath: string = "cobblestonecontractsservicetype";
  async searchCobblestoneContractsServiceType(
    filter: FeamEntities.IWorkSheetFilter
  ): Promise<FeamEntities.ICobblestoneContractsServiceType[]> {
    try {
      const response = await apiClient.get<
        FeamEntities.ICobblestoneContractsServiceType[]
      >(`${this.#rootPath}/search`, {
        params: filter,
      });
      return response.data ?? [];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const cobblestoneContractsServiceTypeService =
  new CobblestoneContractsServiceTypeService();
export default cobblestoneContractsServiceTypeService;
