import { useEffect, useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { useServiceType } from "./BillingReviewContainer";
import { NoRecordFound } from "../common";

export default function BillingInfo(props: IProps.IBillingInfosProps) {
  const { serviceType, setServiceType } = useServiceType();
  const [filteredBillingInfos, setFilteredBillingInfos] = useState<
    FeamEntities.ICobblestoneContractsBillingInfo[]
  >([]);
  useEffect(() => {
    if (serviceType) {
      setFilteredBillingInfos(
        props.billingInfos.filter(
          (f) => f.cobblestoneServiceType === serviceType
        )
      );
    } else {
      setFilteredBillingInfos([]);
    }
  }, [serviceType]);

  return (
    <>
      {filteredBillingInfos.length === 0 && (
        <NoRecordFound message={"No Data found"} />
      )}
      {filteredBillingInfos.length > 0 && (
        <div className="p-1 ps-2">
          <table className="table ">
            <thead>
              <tr>
                <th className="text-nowrap">Billing Information</th>
                <th>UOM</th>
              </tr>
            </thead>
            <tbody>
              {filteredBillingInfos &&
                filteredBillingInfos.map((h, i) => {
                  return (
                    <tr key={`billingInfo_${i}`}>
                      <td>{h.cobblestoneBillingInfo}</td>
                      <td>{h.cobblestoneUoM}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
