import { FieldArray, FieldArrayRenderProps } from "formik";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormInput } from "../../common";
export default function CwsWorkAccomplished(props: IProps.ICwsWorkApplished<FeamEntities.ITailDetail>) {
    const { formProps, arraySelector } = props;
    const rowIndex = 0;
    return (
        <FieldArray
            name={arraySelector}
            render={(_: FieldArrayRenderProps) =>
            (
                <>
                    {/*//@ts-ignore */}
                    <div className={`${formProps.values.multiTail ? "" : "mb-2"} w-100`}>
                        {/*//@ts-ignore */}
                        <div className={`fw-bold ${formProps.values.multiTail ? "" : "mb-2"} header-col w-100 p-1 fw-bold rounded text-uppercase`}>
                            Work Accomplished
                        </div>
                        <div className="w-100 d-flex flex-row justify-content-start align-items-center feam-gap-5-25 ps-2" >
                            <div className="d-flex d-inline-flex">
                                <div className="d-flex align-items-center">
                                    <div className="form-check d-inline-flex gap-2">
                                        {/*//@ts-ignore */}
                                        <input type="checkbox" id="repairStation" name="repairStation" checked={formProps.values.repairStation ?? false} onChange={formProps.handleChange} className="form-check-input" />
                                        <label className="customer-worksheet-label form-check-label text-nowrap pt-1" htmlFor="repairStation" >
                                            Repair Station
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex d-inline-flex">
                                <div className="d-flex align-items-center customer-worksheet-singletail-control-width">
                                    <div className="form-check d-inline-flex gap-1">
                                        {/*//@ts-ignore */}
                                        <input type="checkbox" id="airCarrier" name="airCarrier" checked={formProps.values.airCarrier ?? false} onChange={formProps.handleChange} className="form-check-input" />
                                        <label className="customer-worksheet-label form-check-label text-nowrap pt-1" htmlFor="airCarrier">
                                            Air Carrier
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/*//@ts-ignore */}
                            {formProps.values.multiTail === false && <div className="d-flex d-inline-flex">
                                <div className="d-flex align-items-center">
                                    <div className="form-check d-inline-flex gap-1">
                                        {/*//@ts-ignore */}
                                        <input type="checkbox" name={`${arraySelector}.${rowIndex}.ron`} checked={formProps.values.tailDetails[rowIndex]?.ron ?? false} onChange={formProps.handleChange} className="form-check-input" />
                                        <label className="customer-worksheet-label form-check-label pt-1" >
                                            RON
                                        </label>
                                    </div>
                                </div>
                            </div>}
                            <div className="d-flex d-inline-flex gap-4">
                                <div className="d-flex align-items-center gap-3">
                                    <label className=" align-items-center text-nowrap" style={{ padding: "2px 0px 2px 0px" }}>Check Type:</label>
                                    <div className="d-flex justify-content-between gap-4">
                                        <div className="form-check d-inline-flex">
                                            {/*//@ts-ignore */}
                                            <input type="checkbox" id="dailyCheck" name="dailyCheck" checked={formProps.values.checkType === "Daily"} onChange={(e) => {
                                                formProps.setFieldValue('checkType', e.target.checked ? 'Daily' : undefined);
                                                formProps.handleChange(e);
                                            }} className="form-check-input" />
                                            <label className="customer-worksheet-label form-check-label ps-1 pt-1" >
                                                Daily
                                            </label>
                                        </div>
                                        <div className="form-check d-inline-flex">
                                            {/*//@ts-ignore */}
                                            <input type="checkbox" id="transitCheck" name="transitCheck" checked={formProps.values.checkType === "Transit"} onChange={(e) => {
                                                formProps.setFieldValue('checkType', e.target.checked ? 'Transit' : undefined);
                                                formProps.handleChange(e);
                                            }} className="form-check-input" />
                                            <label className="customer-worksheet-label form-check-label ps-1 pt-1" >
                                                Transit
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex d-inline-flex">
                                <div className="d-flex customer-worksheet-singletail-label-control-width" style={{ padding: "3px 0px 3px 0px" }}>
                                    {/*//@ts-ignore */}
                                    <FeamFormInput formProps={formProps} feamSelector="workDone" className="customer-worksheet-form-control" style={{ width: "120px" }} />
                                </div>
                            </div>
                            {/*//@ts-ignore */}
                            {formProps.values.multiTail === false && <div className="d-flex  d-inline-flex">
                                <div className="d-flex align-items-center">
                                    <div className="form-check d-inline-flex gap-1">
                                        {/*//@ts-ignore */}
                                        <input type="checkbox" name={`${arraySelector}.${rowIndex}.towing`} checked={formProps.values.tailDetails[rowIndex]?.towing ?? false} onChange={formProps.handleChange} className="form-check-input" />
                                        <label className="customer-worksheet-label form-check-label pt-1" >
                                            Towing
                                        </label>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </>
            )} />
    )

}