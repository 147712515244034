import { IProps } from "../../models/feam-entities";

export default function useFeamFormControlProps<
  T extends IProps.IFeamFormControlProps<any>
>(props: IProps.IFeamFormControlProps<any>): [string, string, T] {
  const id = props.feamSelector ?? "";
  //The split condition is use to get value of object type property (Only one level)
  const value = props.formProps?.values[props.feamSelector ?? ""] || "";

  // Remove all extra props and return standard props to be spread to input
  const standardProps = { ...props };

  delete standardProps.formProps;
  delete standardProps.feamSelector;
  // @ts-ignore
  return ([, ,] = [id, value, standardProps]);
}
