import { useState } from "react";
import { IProps } from "../../../models/feam-entities";
import ServiceInfoArea from "./ServiceInfoArea";

export default function ServiceInfo(props: IProps.IServiceInfosProps) {
  const [toggle, setToggle] = useState(false);

  return (
    <ul className="feam-tree-view">
      <li>
        <span
          className={`caret feam-font-size-sm ${toggle ? "caret-down" : ""}`}
          onClick={() => {
            setToggle((p) => !p);
          }}
        >
          {props.cobblestoneContractServicesType}
        </span>

        {toggle &&
          props.areas?.map((a, ai) => {
            return (
              <ServiceInfoArea
                key={`area_${ai}`}
                cobblestoneArea={a.cobblestoneArea}
                serviceTypes={a.serviceTypes}
              />
            );
          })}
      </li>
    </ul>
  );
}
