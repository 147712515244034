import { Fragment } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";

export default function MaterialSummary(props: IProps.IMaterialSummaryProps<FeamEntities.ID407Data>) {
  return (
    <div className="card">
      <div className="card-body m-0 p-0 header-col  rounded">
        <table className="table m-0 p-0 dynamic-tail-table">
          <thead className="header-col">
            <tr className="text-uppercase header-col">
              <th scope="col">AC/Tail</th>
              <th scope="col">Oils</th>
              <th scope="col">Hydraulic Fluid</th>
              <th scope="col">APU</th>
              <th scope="col">Nitrogen</th>
              <th scope="col">Oxygen</th>
              <th scope="col">Other</th>
            </tr>
          </thead>
          <tbody>
          {props.formProps.values.tailDetails && props.formProps.values.tailDetails?.map((item, i) =>
            <tr key={`materialSummary_${i}`}>
              <td >{item.tail}</td>
              <td>{(+(item.oilEngine1 ?? 0)) + (+(item.oilEngine2 ?? 0)) + ((+(item.oilEngine3 ?? 0))) + ((+(item.oilEngine4 ?? 0)))}</td>
              <td>{(+(item.hydEngine1 ?? 0)) + (+(item.hydEngine2 ?? 0)) + ((+(item.hydEngine3 ?? 0))) + ((+(item.hydEngine4 ?? 0)))}</td>
              <td>{item.apu ? item.apu : 0}</td>
              <td>{item.materialsNitrogen ? item.materialsNitrogen : 0}</td>
              <td>{item.materialsOxygen ? item.materialsOxygen : 0}</td>
              <td>{item.materialsOther}</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </div>
  )
}