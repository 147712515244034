import { useEffect, useState } from "react";
import {
  FeamEntities,
  IDropdown,
  IMultiSelectDropdown,
  IProps,
} from "../../../models/feam-entities";
import { FeamFormInput, FeamFormSelect } from "../../common";
import {
  approvedByService,
  cobblestoneContractsServiceTypeService,
  customerService,
  customerWorksheetService,
  weekNumberService,
} from "../../../services";
import stationService from "../../../services/station-service";
import * as BootStrapIcon from "react-bootstrap-icons";
import { useTokenState } from "../../../RequestInterceptors";
import { MultiSelect } from "react-multi-select-component";
import * as utils from "../../../models/feam-utils";
import { processingStatusType, searchType } from "../../../models/constants";

interface ISearchFilterTypeForState {
  tail: string;
  customerId?: number;
  stationId?: number;
  start?: string;
  end?: string;
  d407Id?: string;
  jobNumber?: string;
  maintenanceTypeId?: number;
  approvedBy?: string;
  weekNumber?: IMultiSelectDropdown[];
  serviceType?: IMultiSelectDropdown[];
  includeAogOss?: boolean;
  statusIds?: IMultiSelectDropdown[];
}
interface IDropdownDataForState {
  customers: IDropdown[];
  stations: IDropdown[];
  approvedBy: IDropdown[];
  weekNumbers: IMultiSelectDropdown[];
  serviceTypes: IMultiSelectDropdown[];
  processingStatuses: IMultiSelectDropdown[];
}

export default function CwsAdvancedSearchSection(
  props: IProps.ICwsAdvanceSearchSectionProps
) {
  const [searchFilter, setSearchFilter] = useState<ISearchFilterTypeForState>({
    tail: "",
    customerId: undefined,
    stationId: undefined,
    statusIds: []
  });
  const [dropdownData, setDropdownData] = useState<IDropdownDataForState>({
    customers: [],
    stations: [],
    approvedBy: [],
    weekNumbers: [],
    serviceTypes: [],
    processingStatuses: []
  });
  const { isTokenSet } = useTokenState();
  useEffect(() => {
    if (
      isTokenSet &&
      (dropdownData.customers.length === 0 ||
        dropdownData.stations.length === 0)
    ) {
      const fetchData = async () => {
        await fetchGeneralData();
      };
      fetchData();
    }
  }, [isTokenSet]);

  useEffect(() => {
    if (searchFilter.customerId && searchFilter.stationId && props.searchType === searchType.billedReview) {
      const serviceTypes = async () => {
        await getServiceTypes();
      };
      serviceTypes();
    }
  }, [searchFilter.customerId, searchFilter.stationId]);
  return (
    <div className="mt-3 gap-1 pb-3 feam-advance-search">
      <div
        id="searchSection"
        className="d-flex justify-content-between align-items-center header-col w-100 p-2 fw-bold rounded text-uppercase"
      >
        <span>Search</span>
        {props.searchType === searchType.billedReview && (
          <div className="form-check d-inline-flex gap-2 pe-2 align-items-center">
            <input
              type="checkbox"
              name="includeAogOss"
              checked={searchFilter.includeAogOss ?? false}
              onChange={(e) =>
                setSearchFilter((prev) => ({
                  ...prev,
                  includeAogOss: e.target.checked,
                }))
              }
              className="form-check-input mb-1"
            />
            <label className="customer-worksheet-label form-check-label pt-1">
              AOG/OSS
            </label>
          </div>
        )}
      </div>
      <div className="d-flex d-inline-flex justify-content-start gap-3 w-100">
        <div
          className="input-group w-100 mt-2 ps-2 pe-3"
          title={
            searchFilter.weekNumber && searchFilter.weekNumber.length > 0
              ? searchFilter.weekNumber.map((x) => x.label).join(",")
              : ""
          }
        >
          {(props.searchType === searchType.billedReview || props.searchType === searchType.reports ||
            props.searchType === searchType.advancedSearch
          ) && (
              <MultiSelect
                options={[...dropdownData.weekNumbers]}
                value={searchFilter.weekNumber ?? []}
                //@ts-ignore
                onChange={(e) =>
                  setSearchFilter((prev) => ({ ...prev, weekNumber: e }))
                }
                labelledBy="By Week No."
                className="w-120 customer-worksheet-search-control"
                overrideStrings={{ selectSomeItems: "--By Week No.--" }}
              />
            )}
          <span className="input-group-text customer-worksheet-search-control">Start Date</span>
          <FeamFormInput
            type="date"
            value={searchFilter.start}
            onChange={(e) =>
              setSearchFilter((prev) => ({ ...prev, start: e.target.value }))
            }
            className="customer-worksheet-form-control"
            placeholder="By Start Date"
          />
          <span className="input-group-text customer-worksheet-search-control">End Date</span>
          <FeamFormInput
            type="date"
            value={searchFilter.end}
            onChange={(e) =>
              setSearchFilter((prev) => ({ ...prev, end: e.target.value }))
            }
            className="customer-worksheet-form-control"
            placeholder="By End Date"
            min={searchFilter.start}
          />
          <FeamFormSelect
            defaultText="By Customer"
            data={[...dropdownData.customers]}
            value={searchFilter.customerId}
            onChange={(e) =>
              setSearchFilter((prev) => ({ ...prev, customerId: e.target.value ? +e.target.value : undefined }))
            }
            className="customer-worksheet-form-select"
            hideLoader={false}
          />
          {!(props.searchType === searchType.billedReview || props.searchType === searchType.reports || props.searchType === searchType.billingStatus) && (
            <>
              <FeamFormInput
                placeholder="By AC/Tail Number..."
                className="form-control customer-worksheet-search-control"
                value={searchFilter.tail}
                onChange={(e) =>
                  setSearchFilter((prev) => ({ ...prev, tail: e.target.value }))
                }
              />
              <FeamFormInput
                placeholder="By D407Id..."
                className="form-control customer-worksheet-search-control"
                value={searchFilter.d407Id}
                onChange={(e) =>
                  setSearchFilter((prev) => ({
                    ...prev,
                    d407Id: e.target.value,
                  }))
                }
              />
              <FeamFormInput
                placeholder="By Job#..."
                className="form-control customer-worksheet-search-control"
                value={searchFilter.jobNumber}
                onChange={(e) =>
                  setSearchFilter((prev) => ({
                    ...prev,
                    jobNumber: e.target.value,
                  }))
                }
              />
              <FeamFormSelect
                defaultText="By Maintenance Type"
                data={[...props.maintenanceTypesForDropdown]}
                value={searchFilter.maintenanceTypeId}
                onChange={(e) =>
                  setSearchFilter((prev) => ({
                    ...prev,
                    maintenanceTypeId:
                      e.target.value == "" ? undefined : +e.target.value,
                  }))
                }
                className="customer-worksheet-form-select"
                hideLoader={false}
              />
            </>
          )}
          <FeamFormSelect
            defaultText="By Station"
            data={[...dropdownData.stations]}
            value={searchFilter.stationId}
            onChange={(e) =>
              setSearchFilter((prev) => ({
                ...prev,
                stationId: e.target.value ? +e.target.value : undefined,
              }))
            }
            className="customer-worksheet-form-select"
            hideLoader={false}
          />
          {props.searchType === searchType.billedReview && (
            <MultiSelect
              options={[...dropdownData.serviceTypes]}
              value={searchFilter.serviceType ?? []}
              //@ts-ignore
              onChange={(e) =>
                setSearchFilter((prev) => ({ ...prev, serviceType: e }))
              }
              labelledBy="By Service Type"
              className="w-250 customer-worksheet-search-control"
              overrideStrings={{ selectSomeItems: "--By Service Type--" }}
            />
          )}
          {(props.searchType === searchType.billedReview || props.searchType === searchType.reports) && (
            <FeamFormSelect
              defaultText="By ApprovedBy"
              data={[...dropdownData.approvedBy]}
              value={searchFilter.approvedBy}
              onChange={(e) =>
                setSearchFilter((prev) => ({
                  ...prev,
                  approvedBy: e.target.value,
                }))
              }
              className="customer-worksheet-form-select"
              hideLoader={false}
            />
          )}
          {props.searchType === searchType.advancedSearch && (
            <MultiSelect
              options={[...dropdownData.processingStatuses]}
              value={searchFilter.statusIds ?? []}
              //@ts-ignore
              onChange={(e) => {
                setSearchFilter((prev) => ({
                  ...prev,
                  statusIds: e,
                }));
              }
              }
              labelledBy="By Status"
              className="w-120 customer-worksheet-search-control"
              overrideStrings={{ selectSomeItems: "--By Status--" }}
            />
          )}
          <button
            type="button"
            title="Search"
            className="btn btn-secondary ms-1"
            onClick={() => searchHandler()}
          >
            {!props.searching ? (
              <BootStrapIcon.Search size={16} />
            ) : (
              <>
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Searching...
              </>
            )}
          </button>
          <button
            type="button"
            title="Clear"
            className="btn btn-outline-secondary text-uppercase d-flex m-0 padding-2 justify-content-center align-items-center"
            onClick={() => clearHandler()}
          >
            <BootStrapIcon.Eraser size={16} />
          </button>
        </div>
      </div>
    </div>
  );

  function searchHandler() {
    props.onSearch(getFilter());
  }

  function clearHandler() {
    props.onClear();
    setSearchFilter({
      tail: "",
      customerId: undefined,
      stationId: undefined,
      start: undefined,
      end: undefined,
      d407Id: undefined,
      jobNumber: undefined,
      maintenanceTypeId: undefined,
      approvedBy: undefined,
      weekNumber: undefined,
      serviceType: undefined,
      statusIds: []
    });
  }

  async function fetchGeneralData() {
    const processingTypes: IMultiSelectDropdown[] = [
      { label: "Approved", value: processingStatusType.approved.toString() },
      {
        label: "Marked for Billing",
        value: processingStatusType.markedforBilling.toString(),
      },
      { label: "Pending for Approval", value: "PendingForApproval" },
    ];
    const result = await Promise.all([
      customerService.getAll(),
      stationService.getAll(),
      approvedByService.getAll(),
      props.searchType === searchType.billedReview || props.searchType === searchType.reports || props.searchType === searchType.advancedSearch
        ? weekNumberService.getAll()
        : [],
      props.searchType === searchType.billedReview ? customerWorksheetService.getServiceTypes() : [],
    ]);

    setDropdownData({
      customers: result[0],
      stations: result[1],
      approvedBy: result[2],
      weekNumbers: result[3],
      serviceTypes: result[4],
      processingStatuses: props.searchType === searchType.advancedSearch ? processingTypes : []
    });
  }

  async function getServiceTypes() {
    const response =
      await cobblestoneContractsServiceTypeService.searchCobblestoneContractsServiceType(
        getFilter()
      );
    const dropdownData = response
      .filter((f) => f.cobblestoneServiceType)
      .map<IMultiSelectDropdown>(
        (
          d: FeamEntities.ICobblestoneContractsServiceType
        ): IMultiSelectDropdown => {
          return {
            label: d.cobblestoneServiceType ?? "",
            value: d.cobblestoneServiceType ?? "",
          };
        }
      );
    setSearchFilter((prev) => ({ ...prev, serviceType: undefined }));
    setDropdownData((prev) => ({
      ...prev,
      serviceTypes: dropdownData,
    }));
  }

  function getFilter() {
    const filter: FeamEntities.IWorkSheetFilter = {
      tail: searchFilter.tail,
      customerId: searchFilter.customerId ?? undefined,
      stationId: searchFilter.stationId,
      startDate: searchFilter.start
        ? utils.formatDateTimeForApi(
          new Date(utils.getDateOnTheBasisOfZone(searchFilter.start)),
          true
        )
        : undefined,
      endDate: searchFilter.end
        ? utils.formatDateTimeForApi(
          new Date(utils.getDateOnTheBasisOfZone(searchFilter.end)),
          false,
          true
        )
        : undefined,
      d407Id: searchFilter.d407Id ? searchFilter.d407Id : undefined,
      jobNumber: searchFilter.jobNumber ? searchFilter.jobNumber : undefined,
      maintenanceTypeId: searchFilter.maintenanceTypeId
        ? searchFilter.maintenanceTypeId
        : undefined,
      approvedBy: searchFilter.approvedBy ? searchFilter.approvedBy : undefined,
      weekNumber:
        searchFilter.weekNumber && searchFilter.weekNumber.length > 0
          ? searchFilter.weekNumber.map((m) => m.value).join(",")
          : undefined,
      serviceTypes:
        searchFilter.serviceType && searchFilter.serviceType.length > 0
          ? searchFilter.serviceType.map((m) => m.value).join(",")
          : undefined,
      includeAogOss: searchFilter.includeAogOss ?? false,
      statusIds: searchFilter.statusIds && searchFilter.statusIds.length > 0
        ? searchFilter.statusIds.map((m) => m.value).join(",")
        : undefined,
    };
    return filter;
  }
}
