import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from "react-bootstrap-icons";
import { useState } from "react";
import { printAction } from "../../../models/constants";

export default function AdvancedSearchPrintDropdown(props: IProps.IAdvancedSearchPrintDropdownProps) {
    const [actionToggle, setActionToggle] = useState(false);
    return (
        <>
            <Dropdown
                isOpen={actionToggle}
                toggle={() => {
                    setActionToggle((p) => !p);
                }}
            >
                <DropdownToggle color="" title="Print">
                    <BootStrapIcon.Printer size={16} />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        onClick={(e) => { props.onActionClick(printAction.report) }}>
                        <div className="d-inline-flex gap-3 align-items-center">
                            <BootStrapIcon.FileBarGraph
                                size={16}
                                className="text-primary"
                            />
                            <span>{printAction.report}</span>
                        </div>
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => { props.onActionClick(printAction.summaryReports) }}>
                        <div className="d-inline-flex gap-3 align-items-center">
                            <BootStrapIcon.Files size={16} className="text-info" />
                            <span>{printAction.summaryReports}</span>
                        </div>
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => { props.onActionClick(printAction.d407Forms) }}>
                        <div className="d-inline-flex gap-3 align-items-center">
                            <BootStrapIcon.JournalText size={16} className="text-success" />
                            <span>{printAction.d407Forms}</span>
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

        </>
    )
}