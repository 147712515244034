import { resultStatusType } from "../models/constants";
import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class ProcessingService {
  #rootPath = "processing";

  async Create(
    data: FeamEntities.IProcessing,
  ): Promise<FeamEntities.IProcessing | undefined> {
    try {
      const reponse = await apiClient.post<FeamEntities.IProcessing>(
        this.#rootPath,
        data,
      );
      return reponse.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return undefined;
  }

  async getProcesses(
    filter: FeamEntities.IWorkSheetFilter,
  ): Promise<FeamEntities.IProcessing[]> {
    let data: FeamEntities.IProcessing[] = [];
    try {
      const response = await apiClient.get<FeamEntities.IProcessing[]>(
        `${this.#rootPath}/processes`,
        {
          params: filter,
        },
      );
      return response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return data;
  }

  async deleteByIdAsync(processingId: number): Promise<any> {
    try {
      const response = await apiClient.get<any>(
        `${this.#rootPath}/deletebyId`,
        {
          params: { processingId: processingId },
        },
      );
      return response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return resultStatusType.failed;
  }
}

const processingService = new ProcessingService();
export default processingService;
