import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, redirectUri } from "./authConfig";
import { apiClient, userRoleService } from "./services";
import { useEffect, useState, createContext } from "react";
import { apiConfigMiddleWare } from "./services/http-common";
import { FeamEntities, IProps } from "./models/feam-entities";
import { useBetween } from "use-between";
import { useFeamSetupToken } from "./components/hooks";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { feamUserInfo } from "./models/constants";

export const FeamAuthContext = createContext<{ user: FeamEntities.ID407userRole | undefined }>({ user: undefined });
export const useTokenState = () => useBetween(useFeamSetupToken);
export default function RequestInterceptors(props: IProps.IRequestInterceptorProps) {
    //@ts-ignore
    const [user, setUser] = useState<FeamEntities.ID407userRole | undefined>(localStorage.getItem(feamUserInfo) ? JSON.parse(localStorage.getItem(feamUserInfo)) : undefined);
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { isTokenSet, setIsTokenSet } = useTokenState();
    useEffect(() => {
        if (isAuthenticated) {
            const setToken = async () => {
                await setAppClient();
            }
            setToken();
        }
    }, [isAuthenticated]);

    return <>
        <FeamAuthContext.Provider value={{ user: user }}>
            <LoadingOverlayWrapper className="feam-postion-fixed" active={!isTokenSet} text="Loading Content..." spinner>
                {props.children}
            </LoadingOverlayWrapper>
        </FeamAuthContext.Provider>
    </>

    async function setAppClient() {
        apiClient.interceptors.request.use(
            async (request) => {
                const dateTime = new Date();
                dateTime.setSeconds(dateTime.getSeconds() - 5);
                if (apiConfigMiddleWare.expireOn === -1 || apiConfigMiddleWare.expireOn <= dateTime.getTime()) {
                    const response = await instance
                        .acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0] ?? "",
                        });
                    apiConfigMiddleWare.accessToken = response.accessToken;
                    apiConfigMiddleWare.expireOn = response.expiresOn == null ? -1 : response.expiresOn.getTime();
                }
                //@ts-ignore
                request.headers['Authorization'] = "Bearer " + apiConfigMiddleWare.accessToken;
                //@ts-ignore
                request.headers["Content-Type"] = "application/json";
                //@ts-ignore
                request.headers["Access-Control-Allow-Origin"] = redirectUri;
                return request;
            },
            (error) => {
                Promise.reject(error);
            }

        );
        await setAccessToken();
        setIsTokenSet(true);
        const userInfo = await userRoleService.setUserRolesByEmailId(accounts[0].username);
        // @ts-ignore
        setUser(userInfo);

    }

    async function setAccessToken() {
        if (accounts[0] !== null) {
            apiConfigMiddleWare.accessToken = accounts[0].idToken ?? '';
            apiConfigMiddleWare.expireOn = accounts[0].idTokenClaims == null ? -1 : accounts[0].idTokenClaims.exp ?? -1;
        }
    }
}