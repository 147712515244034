import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormArrayFieldInput, FeamFormArrayTimeFieldInput } from "../../common";
import CwsMultiTailDescriptionTextArea from "./CwsMultiTailDescriptionTextArea";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS } from "react-device-detect";
export default function CwsMultiTailFields(props: IProps.ICwsMultiTailFieldsProps<FeamEntities.ITailDetail>) {
  const { isPrint } = usePrint();

  const { formProps, arraySelector, itemIndex } = props;
  return (
    <div key={`multiTail_${itemIndex}`}>
      <div className={`d-flex d-inline-flex customer-worksheet-multiletail-gap ${isPrint && isMobile && isIOS ? "mb-0" : "mb-1"}`}>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            {/*//@ts-ignore */}
            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="tail" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="flightNum" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="gateNum" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            {/*//@ts-ignore */}
            {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].eta)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="eta" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
            {/*//@ts-ignore */}
            {isPrint && !formProps.values[arraySelector][itemIndex].eta && <input type="text" className="form-control customer-worksheet-form-control" />}
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            {/*//@ts-ignore */}
            {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].ata)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="ata" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
            {/*//@ts-ignore */}
            {isPrint && !formProps.values[arraySelector][itemIndex].ata && <input type="text" className="form-control customer-worksheet-form-control" />}
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            {/*//@ts-ignore */}
            {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].etd)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="etd" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
            {/*//@ts-ignore */}
            {isPrint && !formProps.values[arraySelector][itemIndex].etd && <input type="text" className="form-control customer-worksheet-form-control" />}
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            {/*//@ts-ignore */}
            {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].atd)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="atd" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
            {/*//@ts-ignore */}
            {isPrint && !formProps.values[arraySelector][itemIndex].atd && <input type="text" className="form-control customer-worksheet-form-control" />}
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-control-width">
            <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="gu" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="apu" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine1" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine2" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine3" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine4" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine1" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine2" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine3" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine4" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="materialsNitrogen" className="customer-worksheet-form-control" />
          </div>
        </div>
        <div className="d-flex justify-content-start ">
          <div className="customer-worksheet-multiletail-min-control-width">
            <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="materialsOxygen" className="customer-worksheet-form-control" />
          </div>
        </div>
      </div>
      <div className={`d-flex d-inline-flex w-100 ${isPrint && isMobile && isIOS ? "feam-mb-2" : "mb-1"}`}>
        <label className="d-flex flex-shrink-0 customer-worksheet-label customer-worksheet-label">DESC</label>
        <div className="d-flex d-inline-flex customer-worksheet-form-control w-100 gap-2">
          {/*//@ts-ignore */}
          <CwsMultiTailDescriptionTextArea arraySelector={arraySelector} itemIndex={itemIndex} value={formProps.values.tailDetails[itemIndex].workDetails[0]?.description ?? ''} formProps={formProps} />
          <div className="d-flex d-inline-flex">
            <div className="d-flex align-items-center">
              <div className="form-check d-inline-flex gap-1">
                {/*//@ts-ignore */}
                <input type="checkbox" name={`${arraySelector}.${itemIndex}.ron`} checked={formProps.values.tailDetails[itemIndex]?.ron ?? false} onChange={formProps.handleChange} className="form-check-input" />
                <label className="customer-worksheet-label form-check-label pt-1" >
                  RON
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex  d-inline-flex">
            <div className="d-flex align-items-center">
              <div className="form-check d-inline-flex gap-1">
                {/*//@ts-ignore */}
                <input type="checkbox" name={`${arraySelector}.${itemIndex}.towing`} checked={formProps.values.tailDetails[itemIndex]?.towing ?? false} onChange={formProps.handleChange} className="form-check-input" />
                <label className="customer-worksheet-label form-check-label pt-1">
                  Towing
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}