import { useEffect } from "react";
import { pageTitle } from "../../models/constants";
import { ReportContainer } from "../customer-worksheet/reports";
import "./FeamMain.css";

export default function ReportPage() {
  useEffect(() => { document.title = pageTitle.reports }, [])
  return (
    <ReportContainer />
  )
}