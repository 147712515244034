import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class BillingReviewDataService {
  #rootPath: string = "billingreviewdata";
  async searchBillingReviewData(
    filter: FeamEntities.IWorkSheetFilter,
  ): Promise<FeamEntities.IBillingReviewData[]> {
    try {
      const response = await apiClient.get<FeamEntities.IBillingReviewData[]>(
        `${this.#rootPath}/search`,
        {
          params: filter,
        },
      );
      return response.data ?? [];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const billingReviewDataService = new BillingReviewDataService();
export default billingReviewDataService;
