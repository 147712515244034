import { IProps } from "../../../models/feam-entities";
import { useFeamFormArrayFieldControlProps } from "../../hooks";

export default function FeamFormArrayFieldSelect<T>(props: IProps.IFeamFormArrayFieldSelectProps<T>) {
  const [id, value, standardProps] = useFeamFormArrayFieldControlProps(props);
  // @ts-ignore
  delete standardProps.defaultText;
  // @ts-ignore
  delete standardProps.data;
  return (
    <>
      {props.data.length === 0 && <div className="d-flex justify-content-center"><div className="spinner-grow spinner-grow-sm text-secondary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div></div>}
      {props.data.length > 0 && <select {...standardProps} id={id} name={id} value={value || (props.value ?? '')} onChange={props.onChange || props.formProps?.handleChange} className={`form-select ${props.className}`}>
        <option value="">{!props.defaultText ? "--Select--" : `--${props.defaultText}--`}</option>
        {props.data?.map((d, i) => {
          return <option key={`${id}_${i}`} value={d.value}>{d.value==(value || (props.value ?? ''))?d.text?.split('-')[0]:d.text}</option>
        })}
      </select>}
    </>
  );
}