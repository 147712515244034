import { FeamEntities, IDropdown } from "../models/feam-entities";
import apiClient from "./http-common";

class WorkTypeService {
  #rootPath: string = "worktype";
  #key: string = "feam_worktypes";

  async getAll(): Promise<IDropdown[]> {
    try {
      const res = await apiClient.get<FeamEntities.IWorkType[]>(this.#rootPath);
      const worktypes = res.data.map<IDropdown>(
        (d: FeamEntities.IWorkType): IDropdown => {
          return { text: d.workTypeCatName, value: d.workTypeId.toString() };
        }
      );
      return worktypes;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const workTypeService = new WorkTypeService();
export default workTypeService;
