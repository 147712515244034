import * as BootStrapIcon from 'react-bootstrap-icons';
import "./FeamFooter.css"

export default function FeamFooter() {
    return (
        <footer className="text-center feam-footer m-0 p-0 w-100">
            <div className="container p-0">
                <section className="mb-0">
                    <a className="btn btn-link btn-floating btn-lg feam-footer-link" href="https://www.linkedin.com/company/feamaero/" role="button" target="_blank"  rel="noreferrer"><BootStrapIcon.Linkedin size={16} /></a>
                    <a className="btn btn-link btn-floating btn-lg feam-footer-link" href="https://www.instagram.com/feam.aero/" role="button" target="_blank"  rel="noreferrer"><BootStrapIcon.Instagram size={16} /></a>
                    <a className="btn btn-link btn-floating btn-lg feam-footer-link" href="https://twitter.com/_FEAM" role="button" target="_blank"  rel="noreferrer"><BootStrapIcon.Twitter size={16} /></a>
                    <a className="btn btn-link btn-floating btn-lg feam-footer-link" href="https://www.youtube.com/channel/UCl16TghcK8pcwepyP0wb0eA" role="button" target="_blank"  rel="noreferrer"><BootStrapIcon.Youtube size={16} /></a>
                    <a className="btn btn-link btn-floating btn-lg feam-footer-link" href="https://login.microsoftonline.com/53f11c89-8b36-4335-a938-584c48f23787/oauth2/authorize?client_id=00000003-0000-0ff1-ce00-000000000000&response_mode=form_post&protectedtoken=true&response_type=code%20id_token&resource=00000003-0000-0ff1-ce00-000000000000&scope=openid&nonce=687844C9645714E57A920570E097E3F928CD8ADF98D4B32F-4A16954929AA7A32E016073362B139F3C47B636BAAE5126CEF2A6ED6FDF7641D&redirect_uri=https%3A%2F%2Ffeam.sharepoint.com%2F_forms%2Fdefault.aspx&state=OD0w&claims=%7B%22id_token%22%3A%7B%22xms_cc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D&wsucxt=1&cobrandid=11bd8083-87e0-41b5-bb78-0bc43c8a8e8a&client-request-id=2f002da0-9084-c000-ca7b-e518571feba8" role="button" target="_blank"  rel="noreferrer"><BootStrapIcon.Microsoft size={16} /></a>
                </section>
            </div>
            <div className="text-center pb-0" >
                ©&nbsp;{(new Date()).getFullYear()} Copyright:&nbsp;<a className='feam-footer-copy-link' href="https://feam.aero/" target="_blank"  rel="noreferrer">feam.aero</a>
            </div>
        </footer>
    )
}