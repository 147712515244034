import React from "react";
import { processingStatusType } from "../../../models/constants";
import { IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from 'react-bootstrap-icons';

function CustomerWorksheetStatusIcon(props: IProps.ICustomerWorksheetStatusIconProps) {
    return (
        <>
            {!props.status && <BootStrapIcon.HourglassSplit size={16} className={"text-secondary"} />}
            {props.status && props.status === processingStatusType.approved && <BootStrapIcon.CheckCircle size={16} className={"text-primary"} />}
            {props.status && props.status === processingStatusType.markedforBilling && <BootStrapIcon.BookmarkCheck size={16} className={"text-success"} />}
        </>
    )
}
const CustomerWorksheetStatusIconMemo = React.memo(CustomerWorksheetStatusIcon, (p, n) => p.status === n.status);

export default CustomerWorksheetStatusIconMemo;