import { FieldArray, FieldArrayRenderProps } from "formik";
import { FeamEntities, IDropdown, IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { Fragment, useEffect, useState } from "react";
import { workTypeService } from "../../../services";
import workStatusTypeService from "../../../services/workstatustype-service";
import { FeamFormArrayFieldInput, FeamFormArrayFieldTextArea, FeamFormArrayFieldSelect } from "../../common";
import { NoRecordFound } from "../common";

export default function CswDynamicTailDescForm(props: IProps.ICswDynamicTailDescForm<FeamEntities.ITailDetail>) {
    const [work, setWork] = useState<{ workTypes: IDropdown[], workStatusTypes: IDropdown[] }>({ workTypes: [], workStatusTypes: [] });
    const { formProps, maintenanceTypeId,maintenanceTypes } = props;

    useEffect(() => {
        const fetchData = async () => {
            const result = await Promise.all([workTypeService.getAll(), workStatusTypeService.getAll()]);
            setWork({ workTypes: result[0], workStatusTypes: result[1] });
        }
        fetchData()
    }, []);

    return (
        <FieldArray
            name="workDetails"
            render={(arrayHelper: FieldArrayRenderProps) =>
            (
                <>
                    <div className="d-flex  justify-content-between align-items-center header-col w-100 p-1 fw-bold mb-2 rounded text-uppercase">
                        <span> {maintenanceTypeId && maintenanceTypes.find(x=>x.maintenanceTypeId?.toString()===maintenanceTypeId.toString())? "Courses completed details" : "WORK DETAILS"}</span>
                        <BootStrapIcon.PlusCircleFill role="button" className="text-secondary" size={20} onClick={() => { arrayHelper.push({}) }} title="Add Description" />
                    </div>
                    {(!formProps.values.workDetails || formProps.values.workDetails.length === 0) && (
                        <NoRecordFound message="No Work Description found" className="mb-3" />
                    )}
                    {formProps.values.workDetails !== undefined && formProps.values.workDetails.length > 0 && (<table className="table">
                        <thead>
                            <tr className="text-uppercase">
                                <th scope="col" style={{ width: "150px" }}>NAME</th>
                                <th scope="col" style={{ width: "90px" }}>Ref#</th>
                                <th scope="col">Description</th>
                                <th scope="col" style={{ width: "90px" }}>Time</th>
                                <th scope="col" style={{ width: "125px" }}>Status</th>
                                <th style={{ width: "25px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                formProps.values.workDetails?.map((w: FeamEntities.IWorkDetail, i: number) => {
                                    return (
                                        <Fragment key={`workdesc_${i}`}>
                                            <tr>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldSelect formProps={formProps} feamItemIndex={i} feamArraySelector="workDetails" feamSelector="workTypeId" data={[...work.workTypes]} className="customer-worksheet-form-select" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput formProps={formProps} feamArraySelector="workDetails" feamItemIndex={i} feamSelector="refNumber" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldTextArea formProps={formProps} feamArraySelector="workDetails" feamItemIndex={i} feamSelector="description" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldInput type="number" min={0} formProps={formProps} feamArraySelector="workDetails" feamItemIndex={i} feamSelector="totalTime" className="customer-worksheet-form-control" />
                                                </td>
                                                <td>
                                                    {/**@ts-ignore */}
                                                    <FeamFormArrayFieldSelect formProps={formProps} feamItemIndex={i} feamArraySelector="workDetails" feamSelector="status" data={[...work.workStatusTypes]} className="customer-worksheet-form-select" />
                                                </td>
                                                <td className="text-end">
                                                    <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => { arrayHelper.remove(i) }} title="Delete Description" />
                                                </td>
                                            </tr>
                                        </Fragment>)
                                })}
                        </tbody>

                    </table>)}
                </>)} />
    )
}