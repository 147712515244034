import { Fragment, useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from "react-bootstrap-icons";
import * as factories from "../../../models/factories";
import processingService from "../../../services/processing-service";
import { sumOf } from "../../../models/feam-utils";
import { processingStatusType } from "../../../models/constants";
import { NoRecordFound } from "../common";
import { useFeamAuth } from "../../hooks";
import * as utils from "./../../../models/feam-utils";

export default function BillingReviewStation(
  props: IProps.IBillingReviewStationProps
) {
  const [submitting, setSubmitting] = useState(false);
  const feamAuth = useFeamAuth();
  return (
    <>
      <div
        id="searchSection"
        className="d-flex justify-content-between align-items-center header-col w-100 p-2 pt-1 pb-1 fw-bold rounded text-uppercase "
      >
        <span>Invoice Summary</span>
        {(utils.isSuperAdmin(feamAuth.user) ||
          utils.isBillingApprover(feamAuth.user)) && (
            <button
              type="button"
              className="btn btn-secondary text-uppercase feam-title-btn-padding justify-content-center align-items-center"
              onClick={() => {
                handleSendForProcessing(props.d407BillingReview);
              }}
              title="Send for Processing"
              disabled={submitting || isProcessingDisable()}
            >
              {!submitting ? (
                <BootStrapIcon.SendPlusFill size={16} />
              ) : (
                <>
                  {" "}
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Sending for Process...
                </>
              )}
            </button>
          )}
      </div>
      {!props.d407BillingReview.workTitleBillingDetail ||
        (props.d407BillingReview.workTitleBillingDetail.length === 0 && props.tailMaterialsQBItemData.length === 0 && (
          <div style={{ minHeight: "100px" }}>
            <NoRecordFound message={"No Data found"} />
          </div>
        ))}
      {((props.d407BillingReview.workTitleBillingDetail &&
        props.d407BillingReview.workTitleBillingDetail.length > 0) || (props.tailMaterialsQBItemData &&
          props.tailMaterialsQBItemData.length > 0)) && (
          <div className="p-2 overflow-auto">
            <table className="table ">
              <thead>
                <tr>
                  <th className="text-nowrap">Charges</th>
                  {props.d407BillingReview &&
                    props.d407BillingReview.weekNumber &&
                    props.d407BillingReview.weekNumber.map((mItem, im) => {
                      return (
                        <Fragment key={`${mItem}_${im}`}>
                          <th className="text-end">{mItem}</th>
                        </Fragment>
                      );
                    })}
                  <th className="text-nowrap text-end">Total</th>
                  {(utils.isSuperAdmin(feamAuth.user) ||
                    utils.isBillingApprover(feamAuth.user)) && (
                      <th className="text-nowrap text-end">
                        Total Extended Price in USD
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                {props.d407BillingReview.workTitleBillingDetail &&
                  props.d407BillingReview.workTitleBillingDetail.map(
                    (w, wi) => {
                      return (
                        <tr key={`workTitle_${wi}`}>
                          <td className="d-flex gap-3">
                            <span>{w.workTitle}</span>
                            {!w.selectedQuickBooksItem ? (
                              <span className="text-danger fst-italic">
                                The corresponding item doesn't map with Quick
                                Book item. You can't process this item.
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          {props.d407BillingReview &&
                            props.d407BillingReview.weekNumber &&
                            props.d407BillingReview.weekNumber.map(
                              (mItem, im) => {
                                return (
                                  <Fragment key={`${mItem}_${im}`}>
                                    <td className="text-end">
                                      {getHours(
                                        w.weekNumberAndHours ?? [],
                                        mItem
                                      )}
                                    </td>
                                  </Fragment>
                                );
                              }
                            )}
                          <td className="text-end">
                            {w.totalHours ? w.totalHours.toFixed(2) : 0}
                          </td>
                          {(utils.isSuperAdmin(feamAuth.user) ||
                            utils.isBillingApprover(feamAuth.user)) && (
                              <td className="text-end">
                                $
                                {w.totalExtendedPrice
                                  ? w.totalExtendedPrice.toFixed(2)
                                  : 0}
                              </td>
                            )}
                        </tr>
                      );
                    }
                  )}
                {props.tailMaterialsQBItemData &&
                  props.tailMaterialsQBItemData.length > 0 && (
                    <tr key={`qbItem_nitro_oxy`}>
                      <td className="d-flex gap-3">
                        <span>Nitrogen Oxygen</span>
                      </td>
                      {props.d407BillingReview &&
                        props.d407BillingReview.weekNumber &&
                        props.d407BillingReview.weekNumber.map((mItem, im) => {
                          return (
                            <Fragment key={`${mItem}_${im}`}>
                              <td className="text-end">
                                {(props.tailMaterialsQBItemData.find(
                                  (f) => f.weekNum === mItem
                                )
                                  ? sumOf(props.tailMaterialsQBItemData.filter(
                                    (f) => f.weekNum === mItem
                                  )?.map(m => +(m.totalMaterials ?? 0)))
                                  : 0
                                )?.toFixed(2)}
                              </td>
                            </Fragment>
                          );
                        })}
                      <td className="text-end">
                        {sumOf(
                          props.tailMaterialsQBItemData.map(
                            (m) => +(m.totalMaterials ?? 0)
                          )
                        ).toFixed(2)}
                      </td>
                      {(utils.isSuperAdmin(feamAuth.user) ||
                        utils.isBillingApprover(feamAuth.user)) && (
                          <td className="text-end">
                            $
                            {sumOf(
                              props.tailMaterialsQBItemData.map(
                                (m) => +(m.extendedPrice ?? 0)
                              )
                            ).toFixed(2)}
                          </td>
                        )}
                    </tr>
                  )}
                <tr>
                  <td className="fw-bold">Total</td>
                  {props.d407BillingReview &&
                    props.d407BillingReview.weekNumber &&
                    props.d407BillingReview.weekNumber.map((mItem, im) => {
                      return (
                        <Fragment key={`${mItem}_${im}`}>
                          <td className="fw-bold text-end">
                            {
                              //@ts-ignore next-line
                              getWeekHoursTotal(
                                props.d407BillingReview
                                  .workTitleBillingDetail ?? [],
                                mItem
                              ).toFixed(2)
                            }
                          </td>
                        </Fragment>
                      );
                    })}
                  <td className="fw-bold text-end">
                    {
                      //@ts-ignore next-line
                      getTotalHours(
                        props.d407BillingReview.workTitleBillingDetail ?? []
                      ).toFixed(2)
                    }
                  </td>
                  {(utils.isSuperAdmin(feamAuth.user) ||
                    utils.isBillingApprover(feamAuth.user)) && (
                      <td className="fw-bold text-end">
                        {" "}
                        $
                        {
                          //@ts-ignore next-line
                          getTotalExtendedPrice(
                            props.d407BillingReview.workTitleBillingDetail ?? []
                          ).toFixed(2)
                        }
                      </td>
                    )}
                </tr>
              </tbody>
            </table>
          </div>
        )}
    </>
  );
  function getHours(weekNumber: string[], wNo: string) {
    if (weekNumber.length === 0) return 0;
    const weekNoAndHours: string[] = weekNumber.filter((x) =>
      x.startsWith(wNo)
    );
    const hours = weekNoAndHours.map((item) => {
      return +item.split("|")[1];
    });
    const totalHours = hours.reduce(
      (accumulator: number, currentValue: number) => {
        return accumulator + currentValue ?? 0;
      },
      0
    );
    return totalHours.toFixed(2);
  }
  async function handleSendForProcessing(
    d407BillingReview: FeamEntities.ID407BillingReview
  ) {
    if (submitting) return;
    setSubmitting(true);
    const processing = factories.getProcessing();
    processing.stationId = d407BillingReview.stationId;
    processing.customerId = d407BillingReview.customerId;
    processing.data = JSON.stringify(d407BillingReview.workTitleBillingDetail);
    processing.status = processingStatusType.queue;
    processing.createdBy = feamAuth.user?.fullName;
    processing.weekNumber = d407BillingReview.weekNumber?.join(",");
    processing.approvedBy = d407BillingReview.approvedBy;
    processing.hoursDetail = JSON.stringify(props.billingReviewData);
    processing.tailMaterialDetail = JSON.stringify(props.tailMaterialsData);
    processing.tailMaterialQBItemData = JSON.stringify(props.tailMaterialsQBItemData);
    const result = await processingService.Create(processing);
    if (result && result.invoiceNumber) {
      alert(
        `Request has been sent successfully for billing process and Invoice number is # ${result.invoiceNumber}`
      );
    }
    setSubmitting(false);
  }

  function getTotalHours(
    billingDetail: FeamEntities.ID407WorkTitleBillingDetail[]
  ) {
    const totalHours = billingDetail.map(
      (m: FeamEntities.ID407WorkTitleBillingDetail) => m.totalHours
    );
    //@ts-ignore next-line
    return (sumOf(totalHours) + sumOf(props.tailMaterialsQBItemData.map((m) => +(m.totalMaterials ?? 0)))
    );
  }

  function getTotalExtendedPrice(
    billingDetail: FeamEntities.ID407WorkTitleBillingDetail[]
  ) {
    const totalExtendedPrice = billingDetail.map(
      (m: FeamEntities.ID407WorkTitleBillingDetail) => m.totalExtendedPrice
    );
    const totalQBItemExtendedPrice = props.tailMaterialsQBItemData.map(
      (m: FeamEntities.IBillingTailMaterialsDataQBItem) => m.extendedPrice
    );
    //@ts-ignore next-line
    return sumOf(totalExtendedPrice) + sumOf(totalQBItemExtendedPrice);
  }

  function getWeekHoursTotal(
    billingDetail: FeamEntities.ID407WorkTitleBillingDetail[],
    weekNumber: string
  ) {
    const weekNumberAndHours = billingDetail.flatMap((m) =>
      m.weekNumberAndHours?.filter((x) => x.startsWith(weekNumber))
    );
    //@ts-ignore next-line
    const totalHours = weekNumberAndHours.map((x) => +x?.split("|")[1]);
    const nitroOxyTotal = props.tailMaterialsQBItemData.find(
      (f) => f.weekNum === weekNumber
    )
      ? props.tailMaterialsQBItemData.find((f) => f.weekNum === weekNumber)
        ?.totalMaterials
      : 0;
    //@ts-ignore next-line
    return sumOf(totalHours) + nitroOxyTotal;
  }
  function isProcessingDisable() {
    return (
      props.d407BillingReview.workTitleBillingDetail &&
      (props.d407BillingReview.workTitleBillingDetail.findIndex(
        (x) => !x.selectedQuickBooksItem
      ) !== -1 ||
        props.d407BillingReview.workTitleBillingDetail?.length === 0)
    );
  }
}
