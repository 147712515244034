import { useEffect, useState } from "react";
import { FeamEntities, IDropdown } from "../../../models/feam-entities";
import { customerWorksheetService } from "../../../services";
import { formatDate } from "../../../models/feam-utils";
import useSortableData from "../../hooks/useSortableData";
import maintenanceTypeService from "../../../services/maintenancetype-service";
import { useTokenState } from "../../../RequestInterceptors";
import { CwsAdvancedSearchSection, CwsGeneralHeader, D407FormViewSelection, NoRecordFound } from "../common";
import { printAction, processingStatusType, resultStatusType, searchType, viewSectionAction } from "../../../models/constants";
import AdvancedSearchActionDropdown from "./AdvancedSearchActionDropdown";
import { useFeamAuth } from "../../hooks";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS } from "react-device-detect";
import CustomerWorksheetStatusIconMemo from "./CustomerWorksheetStatusIcon";
import * as utils from "../../../models/feam-utils";
import AdvancedSearchPrintDropdown from "./AdvancedSearchPrintDropdown";
import BulkPrint from "./BulkPrint";
import { getSummaryReportFormatData, SummaryReport } from "../reports";


export default function CwsAdvancedSearchContainer() {
    const [data, setData] = useState<FeamEntities.ID407Data[]>([])
    const [searching, setSearching] = useState(false);
    const [id, setId] = useState(undefined);
    const [maintenanceTypeData, setMaintenanceTypeData] = useState<{ maintenanceTypes: FeamEntities.IMaintenanceType[], maintenanceTypesDropdown: IDropdown[] }>({ maintenanceTypes: [], maintenanceTypesDropdown: [] });
    const [checkedAll, SetCheckedAll] = useState(false);
    const [actionProcessing, SetActionProcessing] = useState<boolean>(false)
    const [checkedIds, setCheckedIds] = useState<number[]>([]);
    const [d407DataCollection, setD407DataCollection] = useState<FeamEntities.ID407Data[]>([]);
    const [adFilter, setAdFilter] = useState<FeamEntities.IWorkSheetFilter | undefined>(undefined);
    const [d407FormPrint, setD407FormPrint] = useState<{ option: boolean, print: boolean, loadingD407Data: boolean }>({ option: false, print: false, loadingD407Data: false });
    const [summaryReportData, setSummaryReportData] = useState<FeamEntities.ISummaryReport[]>([]);
    const [summaryReportLoading, setSummaryReportLoading] = useState(false);
    const [printPreview, setPrintPreview] = useState(false);
    const { user } = useFeamAuth();
    const { isTokenSet } = useTokenState();
    const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);
    const [selectedView, setSelectedView] = useState<string | undefined>(undefined);
    const { isPrint, setIsPrint } = usePrint();
    const { items, requestSort, sortConfig } = useSortableData(data, { key: "d407id", direction: "descending" });
    useEffect(() => {
        if (isTokenSet && (maintenanceTypeData.maintenanceTypes.length === 0)) {
            const fetchData = async () => {
                const result = await maintenanceTypeService.getAll();
                const dropdownData = result.map<IDropdown>(
                    (d: FeamEntities.IMaintenanceType): IDropdown => {
                        return { text: d.name, value: d.maintenanceTypeId?.toString() };
                    }
                );
                setMaintenanceTypeData({ maintenanceTypes: result, maintenanceTypesDropdown: dropdownData })
            }
            fetchData();
        }
    }, [isTokenSet])

    useEffect(() => {
        if (isPrint) {
            window.scrollTo(0, 0);
            setPrintPreview(true);
            setTimeout(() => {
                setTimeout(() => {
                    printHandler();
                }, 200)
                setPrintPreview(false);

            }, (d407FormPrint.print || summaryReportData.length > 0) ? getDelayTime() : 700);
        }
    }, [isPrint])

    useEffect(() => {
        if (d407FormPrint.loadingD407Data) {
            const fatchData = async () => {
                await loadD407Data();
            }
            fatchData();
        }
    }, [d407FormPrint.loadingD407Data])

    useEffect(() => {
        //@ts-ignore
        const statusIds = [...new Set(data.filter((f) => checkedIds.includes(f.d407id)).map((m) => m.status))];
        //@ts-ignore
        setSelectedStatusIds(statusIds);
    }, [checkedIds.length])
    return (
        <>
            <LoadingOverlayWrapper className="feam-postion-fixed" active={!d407FormPrint.loadingD407Data && !summaryReportLoading && !printPreview ? (actionProcessing ? true : false) : true} text={!d407FormPrint.loadingD407Data && !summaryReportLoading && !printPreview ? "Customer worksheets is updating..." : "Loading Content"} spinner>
                <>
                    <div className={`${!isPrint ? "container cws-advance-search feam-m-5" : "mt-0 mb-0 pb-0 pt-0"}`} style={{ display: d407FormPrint.print || summaryReportData.length > 0 ? "none" : "block" }}>
                        <div style={{ display: isPrint ? "none" : "block" }}>
                            <CwsGeneralHeader title="Advanced Search" />
                            <CwsAdvancedSearchSection onSearch={searchHandler} onClear={clearHandler} searching={searching} maintenanceTypesForDropdown={maintenanceTypeData.maintenanceTypesDropdown} searchType={searchType.advancedSearch} />
                        </div>
                        {isPrint && <div className="d-flex fs-4 fw-bold flex-fill justify-content-center text-uppercase m-3">
                            <span>Station Process Report</span>
                        </div>}
                        {items.length === 0 && <NoRecordFound message={"No Data found"} />}
                        {items.length > 0 &&
                            <div className="ps-3  pe-3">
                                <table className="table table-striped advanced-search-table">
                                    <thead>
                                        <tr className="text-uppercase">
                                            <th scope="col" className="no-wordwrap p-0"><button
                                                type="button"
                                                onClick={() => requestSort('d407id')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 text-nowrap ${getClassNamesFor('d407id')}`}>D407 ID</button></th>
                                            <th scope="col" className="no-wordwrap p-0"><button
                                                type="button"
                                                onClick={() => requestSort('entryDate')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0  text-nowrap " ${getClassNamesFor('entryDate')} ${isPrint ? "ps-2" : ""}`}>Entry Date</button></th>
                                            <th scope="col" className="p-0"><button
                                                type="button"
                                                onClick={() => requestSort('customerId')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('customerId')} ${isPrint ? "ps-2" : ""}`}>Customer</button></th>
                                            <th scope="col" className="p-0"><button
                                                type="button"
                                                onClick={() => requestSort('maintenanceTypeId')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 text-nowrap ${getClassNamesFor('maintenanceTypeId')} ${isPrint ? "ps-2" : ""}`}>Maintenance Type </button></th>
                                            <th scope="col" className="p-0"><button
                                                type="button"
                                                onClick={() => requestSort('stationCode')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('stationId')} ${isPrint ? "ps-2" : ""}`}>Station</button></th>
                                            <th scope="col" className="no-wordwrap p-0"><button
                                                type="button"
                                                onClick={() => requestSort('dateCompleted')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 text-nowrap ps-2 ${getClassNamesFor('dateCompleted')}`}>Completion Date</button></th>
                                            <th scope="col" className="no-wordwrap p-0"><button
                                                type="button"
                                                onClick={() => requestSort('preparedBy')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 text-nowrap ${getClassNamesFor('preparedBy')} ${isPrint ? "ps-2" : ""}`}>Prepared By</button></th>
                                            <th scope="col" className="no-wordwrap p-0"><button
                                                type="button"
                                                onClick={() => requestSort('approvedBy')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 text-nowrap ${getClassNamesFor('approvedBy')} ${isPrint ? "ps-2" : ""}`}>Approved By</button></th>
                                            <th scope="col" className="p-0 pe-2"><button
                                                type="button"
                                                onClick={() => requestSort('statusName')}
                                                className={`fw-bold bg-transparent text-dark d-inline-flex justify-content-center align-items-center border-0 w-100 m-0 p-0 ${getClassNamesFor('statusName')} ${isPrint ? "ps-2" : ""}`}>Status</button></th>

                                            {isAllowAction() && <th scope="col" className="p-0 ps-2">
                                                <>
                                                    {!isPrint && <div className="d-inline-flex align-items-center w-100 ps-1 gap-1">
                                                        <div className="form-check d-inline-flex align-items-center mt-1">
                                                            <input
                                                                type="checkbox"
                                                                name="allcheckbox"
                                                                checked={checkedAll}
                                                                onChange={checkedAllHandler}
                                                                className="form-check-input feam-solid-border"
                                                                title="Check/UnCheck All" />
                                                        </ div>
                                                        <div className="mt-1">
                                                            <AdvancedSearchPrintDropdown onActionClick={printActionHandler} />
                                                        </div>
                                                        <div className="mt-1">
                                                            <AdvancedSearchActionDropdown onActionClick={actionClickHandler} selectedStatusIds={selectedStatusIds} />
                                                        </div>
                                                    </div>}
                                                </>
                                            </th>}
                                        </tr >
                                    </thead >
                                    <tbody>
                                        {items?.map((item: FeamEntities.ID407Data, index) => {
                                            return (
                                                <tr key={`${item.d407id}_${index}`}>
                                                    <td className="text-start text-nowrap gap-1">
                                                        <CustomerWorksheetStatusIconMemo status={item.status} />&nbsp;
                                                        <a className="link-offset-2 link-underline link-underline-opacity-100" href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                //@ts-ignore 
                                                                setId(item.d407id?.toString());
                                                            }}>{item.d407id}</a></td>
                                                    {/** @ts-ignore */}
                                                    <td className="text-nowrap">{formatDate(item.entryDate)}</td>
                                                    <td>{item.customer}</td>
                                                    <td>{!item.maintenanceTypeId ? '' : maintenanceTypeData.maintenanceTypes.find(x => x.maintenanceTypeId === item.maintenanceTypeId)?.name}</td>
                                                    <td>{item.stationCode}</td>
                                                    {/** @ts-ignore */}
                                                    <td className="text-nowrap">{formatDate(item.dateCompleted)}</td>
                                                    <td>{item.preparedBy}</td>
                                                    <td>{item.approvedBy}</td>
                                                    <td className="">
                                                        <div className="text-nowrap d-flex d-inline-flex align-items-center justify-content-center">
                                                            <span className={`d-flex justify-content-center align-items-center text-nowrap feam-process-status-size rounded-pill text-white p-1 pt-0 pb-0 ${getLabelStatusColor(item.status)}`}>{item.status ? item.statusName : "Pending for Approval"}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    {isAllowAction() && <td className={!isPrint ? "text-start" : "text-center"}>
                                                        <div className="form-check d-inline-flex align-items-center feam-checkbox-table-align">
                                                            <input
                                                                type="checkbox"
                                                                name={item.d407id?.toString()}
                                                                checked={getCheckedStatus(item.d407id ?? 0)}
                                                                onChange={(e) => { checkedOrUnCheckedStatusHandler(e, item.d407id ?? 0) }}
                                                                className="form-check-input mb-1 feam-solid-border"
                                                            />
                                                        </ div>
                                                    </td>}
                                                </tr>)
                                        })
                                        }
                                    </tbody>
                                </table ></div >}
                    </div>


                    <D407FormViewSelection id={id} resetData={resetDataHandler} isBulkPrint={d407FormPrint.option} />

                </>
            </LoadingOverlayWrapper >
            {isPrint && summaryReportData.length > 0 && <SummaryReport reportData={summaryReportData} />}
            {d407FormPrint.print && !d407FormPrint.option && <BulkPrint d407Data={[...d407DataCollection]} selectedView={selectedView ?? ""} />}
        </ >

    )

    function clearHandler() {
        setData([]);
        setAdFilter(undefined);
        setCheckedIds([]);
        SetCheckedAll(false);
    }

    function resetDataHandler(e: string) {
        if (e === viewSectionAction.cancel) {
            setId(undefined);
            setD407FormPrint({ option: false, print: false, loadingD407Data: false });
        } else {
            if (d407FormPrint.option) {

                setSelectedView(e);
                setD407FormPrint(prv => ({ option: false, print: false, loadingD407Data: true }))
            } else {
                setId(undefined);
            }

        }

    }

    async function searchHandler(filter: FeamEntities.IWorkSheetFilter | undefined) {
        if (!filter) return;

        if (filter.weekNumber && (filter.startDate || filter.endDate)) {
            alert("Please select week number or date range.");
            return;
        }
        if (!filter.stationId && !filter.tail && !filter.d407Id) {
            alert("Please select station.");
            return;
        }
        setSearching(true);
        const result = await customerWorksheetService.advanceSearchForCustomerWorksheet(filter);
        setData(result);
        setSearching(false);
        setAdFilter(filter);
        SetCheckedAll(false);
        setCheckedIds([]);
    }
    function getClassNamesFor(name: string) {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    async function actionClickHandler(actionType?: number, name?: string) {
        if (checkedIds.length === 0) {
            alert('Please select at least one customer worksheet.');
            return;
        }
        //@ts-ignore 
        if (selectedStatusIds && selectedStatusIds.length > 0) {
            let canProcess = true;
            let message =
                `The selected items cannot be moved to ${name} status.
         Need to follow below steps:
         1. Approved: Pending for Approval => Approved
         2. Unapproved: Approved => Unapproved
         3. Marked for billing: Approved => Mark for Billing
         4. Un-marked for billing: Mark for Billing => Un-mark for Billing
            `
            //@ts-ignore 
            if (!selectedStatusIds[0] && ((actionType !== processingStatusType.approved && actionType !== processingStatusType.delete) || name === "Un-Mark for Billing")) {
                canProcess = false;
            }
            //@ts-ignore 
            else if (selectedStatusIds[0] === processingStatusType.approved && actionType && actionType !== processingStatusType.markedforBilling && actionType !== processingStatusType.delete) {
                canProcess = false;
            }
            //@ts-ignore 
            else if (selectedStatusIds[0] === processingStatusType.markedforBilling && actionType !== processingStatusType.approved) {
                canProcess = false;
            }
            if (!canProcess) {
                alert(message);
                return;
            }
        }
        if (window.confirm(`Are you sure! Do you want to ${name} customer worksheet's.`)) {
            SetActionProcessing(true);
            const result = await customerWorksheetService.updateStatusAsync({ ids: checkedIds, processStatusType: actionType, updatedBy: user?.fullName ?? "" });
            if (result === resultStatusType.success) {
                SetActionProcessing(false);
                window.alert(`Records have been ${getActionNameAfterAction(actionType)} successfully.`);
                await searchHandler(adFilter);
                setCheckedIds([]);
                SetCheckedAll(false);
            } else {
                SetActionProcessing(false);
                window.alert("Records has been saved successfully.");
            }
        }
    }

    function getLabelStatusColor(status?: number) {
        if (!status) return "bg-secondary"
        switch (+status) {
            case processingStatusType.approved: return "bg-primary";
            case processingStatusType.markedforBilling: return "bg-success";
        }
    }

    function checkedAllHandler(e: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = e.target.checked;
        if (!isChecked) {
            setCheckedIds([]);
            setSelectedStatusIds([]);
        } else {
            const ids = data.map(x => x.d407id);
            //@ts-ignore
            setCheckedIds(ids);
        }
        SetCheckedAll(isChecked);
    }

    function getCheckedStatus(d407Id: number) {
        if (d407Id === 0) return false;
        const isExist = checkedIds?.find(x => x === d407Id);
        return !isExist ? false : true;
    }

    function checkedOrUnCheckedStatusHandler(e: React.ChangeEvent<HTMLInputElement>, d407Id: number) {
        if (d407Id === 0) return;
        if (e.target.checked) {
            if (data.length === checkedIds.length + 1) {
                SetCheckedAll(true);
            }
            setCheckedIds([...checkedIds, d407Id]);
        } else {
            const ids = checkedIds.filter((x) => x !== d407Id);
            setCheckedIds(ids);
            SetCheckedAll(false);
        }
    }

    function getActionNameAfterAction(actionType?: number) {
        if (!actionType) {
            return "Pending for approval";
        }
        else if (actionType === processingStatusType.delete) {
            return "Deleted";
        }
        else if (actionType === processingStatusType.markedforBilling) {
            return "Marked for Billing";
        }
        else if (actionType === processingStatusType.approved) {
            return "Approved";
        }
    }
    async function resetAfterPrint(e: any) {
        setIsPrint(false);
        setD407FormPrint({ option: false, print: false, loadingD407Data: false });
        setSummaryReportData([]);
    }

    function printHandler() {
        window.addEventListener(isMobile && !isIOS ? "focus" : "afterprint", resetAfterPrint);
        window.print();
        return () => {
            window.removeEventListener(isMobile && !isIOS ? "focus" : "afterprint", resetAfterPrint);
        }
    }

    function isAllowAction(): boolean {
        //@ts-ignore
        return (((utils.isStationAdmin(user) || utils.isStationApprover(user)) && user?.stationIds?.includes(adFilter?.stationId ?? 0)) || utils.isBillingApprover(user) || utils.isSuperAdmin(user))
    }

    async function printActionHandler(e: string) {
        if (e === printAction.report) {
            setIsPrint(true);
        } else if (e === printAction.d407Forms) {
            if (checkedIds.length === 0) {
                alert('Please select at least one customer work sheet.');
                return;
            }

            if (checkedIds.length > 10) {
                alert("Please select upto ten customer worksheets'.")
                return;
            }
            setD407FormPrint({ option: true, print: false, loadingD407Data: false });
        } else if (e === printAction.summaryReports) {
            if (checkedIds.length === 0) {
                alert('Please select at least one customer work sheet.');
                return;
            }
            setSummaryReportLoading(true);
            //@ts-ignore
            const result = await customerWorksheetService.summaryReportOfCustomerWorksheet(adFilter);
            //@ts-ignore
            const filterResults = result.filter(x => checkedIds.includes(x.d407id));
            const data: FeamEntities.ISummaryReport[] = getSummaryReportFormatData(filterResults);
            setSummaryReportData(data);
            setSummaryReportLoading(false);
            setIsPrint(true);
        }
    }

    async function loadD407Data() {
        const promises: Promise<FeamEntities.ID407Data>[] = [];
        checkedIds.forEach(id => {
            //@ts-ignore
            promises.push(customerWorksheetService.getByIdAsync(id));
        });
        const collection = await Promise.all(promises);
        setIsPrint(true);
        //@ts-ignore
        setD407DataCollection(collection);
        setD407FormPrint({ option: false, print: true, loadingD407Data: false });
    }

    function getDelayTime() {
        return checkedIds.length * 3500;
    }
}

