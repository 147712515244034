import { FeamEntities, IDropdown } from "../models/feam-entities";
import feamCache from "./cache";
import apiClient from "./http-common";

class CustomerService {
  #rootPath: string = "customer";
  #key: string = "feam_customers";
  async getAll(): Promise<IDropdown[]> {
    try {
      if (feamCache.has(this.#key)) {
        return feamCache.get(this.#key);
      }
      const res = await apiClient.get<FeamEntities.ICustomer[]>(this.#rootPath);
      const customers = res.data.map<IDropdown>((d) => {
        return { text: d.companyName, value: d.id?.toString() };
      });
      if (!feamCache.has(this.#key)) {
        feamCache.set(this.#key, [...customers]);
      }
      return customers;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }

  async add(customer: FeamEntities.ICustomer): Promise<void> {
    try {
      if (feamCache.has(this.#key)) {
        const customers = feamCache.get(this.#key);
        if (
          !customers.find(
            (x: IDropdown) =>
              x.value?.trim().toLocaleLowerCase() ===
              customer.companyName?.trim().toLocaleLowerCase()
          )
        ) {
          await apiClient.post(this.#rootPath, customer);
          customers.push({
            text: customer.companyName,
            value: customer.companyName,
          });
          feamCache.set(this.#key, [...customers]);
        }
      }
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
  }
}

const customerService = new CustomerService();
export default customerService;
