import { IProps } from "../../../models/feam-entities";
import { formatDateForDateControl, formatDateTimeForDateTimeControl } from "../../../models/feam-utils";
import { useFeamFormArrayFieldControlProps } from "../../hooks";

export default function FeamFormArrayFieldInput<T>(props: IProps.IFeamFormArrayFieldInputProps<T>) {
  const [id, value, standardProps] = useFeamFormArrayFieldControlProps(props);

  let val: string | Date = value;
  if (props.type === 'date' && val) {
    val = formatDateForDateControl(val);
  }
  if (props.type === 'datetime-local' && val) {
    //@ts-ignore
    val = formatDateTimeForDateTimeControl(val);
  }
  //@ts-ignore
  delete standardProps.isNumeric;

  return (
    //@ts-ignore
    <input {...standardProps} id={id} name={id} placeholder=" " value={val || (props.value ?? '')} autoComplete="off" onKeyDown={keyPressHandler} onChange={props.onChange || props.formProps?.handleChange} type={props.type ?? "text"} className={`form-control ${props.className} ${props.type && props.type.includes('date') ? "text-start" : ""}`} />
  );

  function keyPressHandler(e: React.KeyboardEvent<HTMLInputElement>) {
    //@ts-ignore
    if (props.isNumeric && isNaN(e.key) && e.key !== 'Backspace' && e.key !== '.' && e.key !== 'Tab') {
      e.preventDefault();
    }
  }
}