import { Autocomplete, FilterOptionsState, TextField, createFilterOptions } from "@mui/material";
import { useFeamFormArrayFieldControlProps } from "../../hooks";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { autoCompleteStyle } from "../../../models/autoCompleteStyle";

const filter = createFilterOptions<FeamEntities.IPaylocityEmployeeForAutoInput>()

export default function FeamFormArrayAutoCompleteInputForEmployee(props: IProps.IFeamFormArrayAutoCompleteInputForEmployeeProps<FeamEntities.IPaylocityEmployeeForAutoInput>) {
    const [id, value, standardProps] = useFeamFormArrayFieldControlProps(props);
    const { data } = props;
    // @ts-ignore
    delete standardProps.data;
    // @ts-ignore
    delete standardProps.onchangeHandler;
    return (
        <Autocomplete
            value={value ?? ''}
            // @ts-ignore
            onChange={changeHandler}
            // @ts-ignore
            filterOptions={filterHandler}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            openOnFocus
            readOnly={props.readOnly}
            id={id}
            options={data}
            // @ts-ignore
            getOptionLabel={(option) => {
                if (typeof option === 'string') return option ?? '';
                if (typeof option === 'object' && option && option.value) return option.text;
                //return option?.text ?? "";
            }}
            renderOption={(props, option) => <li {...props} className='feamAutoComplete'>{option.text}</li>}
            sx={autoCompleteStyle}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} className={`form-control ${props.className}`} placeholder={props.placeholder} />
            )}
        />

    )
    function filterHandler(options: FeamEntities.IPaylocityEmployeeForAutoInput[], params: FilterOptionsState<FeamEntities.IPaylocityEmployeeForAutoInput>) {
        const filtered = filter(options, params);
        const { inputValue } = params;
        if (inputValue !== '') {
            filtered.push({
                value: inputValue,
                text: `Add "${inputValue}"`,
            });
        }
        return filtered;
    }

    function changeHandler(event: React.SyntheticEvent, newValue: FeamEntities.IPaylocityEmployeeForAutoInput) {
        props.onchangeHandler(id, newValue)
    }
}