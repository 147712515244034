import { FeamEntities, IProps } from "../../../models/feam-entities";
import HoursSummary from "./HoursSummary";
import MaterialSummary from "./MaterialSummary";
import NoRecordFound from "./NoRecordFound";
import { useSharedState } from "../customer-worksheet-dynamic/DynamicWorkSheet";

export default function SummaryContainer(props: IProps.ISummaryContainerProps<FeamEntities.ID407Data>) {
  //Don't remove useSharedState. It's reloading page.
  const {} = useSharedState();

  return (
    <div className="card m-0 p-0">
      <div className="card-header text-uppercase header-col fw-bold m-0 p-1">Summary of Hours and Materials</div>
      <div className="card-body m-0 p-1">
        {props.formProps.values.tailDetails?.length === 0 && <NoRecordFound message={"No Summary found"} />}
        {props.formProps.values.tailDetails && props.formProps.values.tailDetails.length > 0 && <div className="row g-3">
          <div className="col-sm-6">
            <HoursSummary formProps={props.formProps} />
          </div>
          <div className="col-sm-6">
            <MaterialSummary formProps={props.formProps} />
          </div>
        </div>}
      </div>
    </div>
  )
}