import { IProps } from "../../../../models/feam-entities";
import { sumOfTimes } from "../../../../models/feam-utils";
import { NoRecordFound } from "../../common";

export default function MechanicAssignedWorkDetail(props: IProps.IMechanicAssignedWorkDetailProps) {
    return (
        <>
            <div className="d-flex mb-1 d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
                <span>{props.workTitle}:</span>
                <span>{props.mechanicsAssignedDetail && props.mechanicsAssignedDetail.length > 0 ? sumOfTimes([sumOfTimes(props.mechanicsAssignedDetail.flatMap(x => x.reg ?? "00:00")), sumOfTimes(props.mechanicsAssignedDetail.flatMap(x => x.ot ?? "00:00"))]) : "00:00"} hrs</span>
            </div>
            <div className="w-100 p-1">
                { /*@ts-ignore next-line*/}
                {(!props.mechanicsAssignedDetail || props.mechanicsAssignedDetail.length === 0) && <NoRecordFound message={"No Data found"} />}
                {props.mechanicsAssignedDetail && props.mechanicsAssignedDetail.length > 0 && <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>REG</th>
                            <th className="ps-2">OT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.mechanicsAssignedDetail.map((wDetail, mwindex) => {
                            return (<tr key={`mDetail_${mwindex}`}>
                                <td>{wDetail.name}</td>
                                <td>{wDetail.reg ?? "00:00"}</td>
                                <td>{wDetail.ot ?? "00:00"}</td>
                            </tr>);
                        })}
                        <tr>
                            <td className="fw-bold text-uppercase">TOTAL</td>
                            <td className="fw-bold text-uppercase">{sumOfTimes(props.mechanicsAssignedDetail.flatMap(x => x.reg ?? "00:00"))}</td>
                            <td className="fw-bold text-uppercase">{sumOfTimes(props.mechanicsAssignedDetail.flatMap(x => x.ot ?? "00:00"))}</td>
                        </tr>
                    </tbody>
                </table>}
            </div>
        </>
    )
}