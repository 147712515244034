import { IProps } from "../../../models/feam-entities";

export default function CwsGeneralHeader(props: IProps.ICwsGeneralHeaderProps) {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center customer-worksheet-header mb-3">
      <div className="customer-worksheet-logo-container">
        {/* <img alt="FEAM Aero" src={logo} className="customer-worksheet-logo" /> */}
      </div>
      <div className="d-flex fs-4 fw-bold flex-fill justify-content-center text-uppercase">
        <span>{props.title}</span>
      </div>
      <div className={`d-flex flex-column customer-worksheet-version-container`}>
        <span className="fs-6 fw-bold">FEAM-407</span>
        <span className="customer-worksheet-version-fontsize">Web Version V5</span>
        <span className="customer-worksheet-version-fontsize">8-01-2024</span>
      </div>
    </div>
  )
}