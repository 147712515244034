import { FeamEntities, IDropdown } from "../models/feam-entities";
import apiClient from "./http-common";

class PayTypeService {
  #rootPath: string = "paytype";
  #key: string = "feam_payTypes";
  async getAll(): Promise<IDropdown[]> {
    try {
      const res = await apiClient.get<FeamEntities.IPayType[]>(this.#rootPath);
      const payTypes = res.data.map<IDropdown>((d) => {
        return {
          text: d.payTypeCode + "-" + d.payTypeName,
          value: d.payTypeCode,
        };
      });
      return payTypes;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const payTypeService = new PayTypeService();
export default payTypeService;
