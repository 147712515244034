import { useState } from "react";
import entryGif from "../../../assets/images/1-How-to-Enter-a-D407.gif";
import entryMultiTailGif from "../../../assets/images/5-How-to-Enter-a-MultiTail-D407.gif";
import printAndSaveGif from "../../../assets/images/2-How-to-Print-N-Save-D407.gif";
import updateGif from "../../../assets/images/4-How-to-Update-a-D407.gif";
import searchGif from "../../../assets/images/3-How-to-Search-for-D407.gif";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GuideVideo from "./GuideVideo";
import "./ClassicHelp.css";
import CwsGeneralHeader from "../common/CwsGeneralHeader";

export default function ClassicHelp() {

    const [guide, setGuide] = useState<"enter" | "save" | "update" | "search" | "multitail" | undefined>(undefined);
    return (
        <div className="container cws-advance-search customer-container-width customer-container-width feam-m-5" >
           <CwsGeneralHeader title="D407 Form Help"/>
            <div className="d-flex align-content-start flex-wrap p-5 gap-3">
                <div className="card customer-worksheet-card mb-3" role="button" onClick={() => { setGuide("enter") }}>
                    <div className="card-body d-flex justify-content-center">
                        <p className="p-0 m-0 fw-bold">How to Enter a D407</p>
                    </div>
                    <div className="customer-worksheet-card-img rounded">
                        <img src={entryGif} className="card-img-bottom" alt="How to enter a D407" />
                    </div>
                </div>
                <div className="card customer-worksheet-card mb-3" role="button" onClick={() => { setGuide("multitail") }}>
                    <div className="card-body d-flex justify-content-center">
                        <p className="p-0 m-0 fw-bold">How to Enter a MultiTail D407</p>
                    </div>
                    <div className="customer-worksheet-card-img rounded">
                        <img src={entryMultiTailGif} className="card-img-bottom" alt="How to Enter a MultiTail D407" />
                    </div>
                </div>
                <div className="card customer-worksheet-card mb-3" role="button" onClick={() => { setGuide("save") }}>
                    <div className="card-body d-flex justify-content-center">
                        <p className="p-0 m-0 fw-bold">How to Print and Save D407</p>
                    </div>
                    <div className="customer-worksheet-card-img rounded">
                        <img src={printAndSaveGif} className="card-img-bottom" alt="How to print and save D407" />
                    </div>
                </div>
                <div className="card customer-worksheet-card mb-3" role="button" onClick={() => { setGuide("update") }}>
                    <div className="card-body d-flex justify-content-center">
                        <p className="p-0 m-0 fw-bold">How to Update a D407</p>
                    </div>
                    <div className="customer-worksheet-card-img rounded">
                        <img src={updateGif} className="card-img-bottom" alt="How to update a D407" />
                    </div>
                </div>
                <div className="card customer-worksheet-card mb-3" role="button" onClick={() => { setGuide("search") }}>
                    <div className="card-body d-flex justify-content-center">
                        <p className="p-0 m-0 fw-bold">How to Search for D407</p>
                    </div>
                    <div className="customer-worksheet-card-img rounded">
                        <img src={searchGif} className="card-img-bottom" alt="How to search for D407" />
                    </div>
                </div>
                <Modal isOpen={guide !== undefined} size="lg">
                    <ModalHeader className="fw-bold">{guide !== undefined && getGuideTitle()}</ModalHeader>
                    <ModalBody className="p-3" >
                        {guide !== undefined && <GuideVideo value={guide} />}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-outline-secondary" onClick={() => setGuide(undefined)}>Close</button >
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )

    function getGuideTitle() {
        if (!guide) return;
        switch (guide) {
            case "enter": return 'How to Enter a D407';
            case "save": return 'How to Print & Save D407';
            case "update": return 'How to Update a D407';
            case "search": return 'How to Search for D407';
            case "multitail": return 'How to Enter a MultiTail D407';
        }
    }
}