import { useEffect, useState } from "react";
import { FeamEntities, IDropdown } from "../../../models/feam-entities";
import { useTokenState } from "../../../RequestInterceptors";
import { CwsAdvancedSearchSection, CwsGeneralHeader } from "../common";
import "./BillingReview.css";
import { NoRecordFound } from "../common";
import {
  billingReviewDataService,
  maintenanceTypeService,
  cobblestoneContractsActiveService,
  cobblestoneContractsBillingInfoService,
  cobblestoneContractsServiceTypeService,
  billingTailMaterialQBItemDataService,
} from "../../../services";
import { sumOf } from "../../../models/feam-utils";
import ContractsInfo from "./ContractsInfo";
import billingTailMaterialDataService from "../../../services/billingtailmaterialsdata-service";
import BillingReviewWorkDetail from "./BillingReviewWorkDetail";
import { useFeamServiceType } from "../../hooks";
import { useBetween } from "use-between";
import TailMaterialDetailView from "./TailMaterialDetailView";
import { searchType } from "../../../models/constants";
import HourTailSummaryContainer from "./HourTailSummaryContainer";
import ServiceBillingInfoContainer from "./ServiceBillingInfoContainer";

export const useServiceType = () => useBetween(useFeamServiceType);

export default function BillingReviewContainer() {
  const { serviceType, setServiceType } = useServiceType();
  const [data, setData] = useState<FeamEntities.ID407BillingReview>();
  const [generalData, setGeneralData] = useState<{
    activeContracts: FeamEntities.ICobblestoneContractsActive[];
    billingInfos: FeamEntities.ICobblestoneContractsBillingInfo[];
    serviceTypes: FeamEntities.IContractServiceType[];
    tailMaterialData: FeamEntities.ITailMaterialsData[];
    tailMaterialDetail: FeamEntities.IBillingTailMaterialsData[];
    tailMaterialDataQBItem: FeamEntities.IBillingTailMaterialsDataQBItem[];
  }>({
    activeContracts: [],
    billingInfos: [],
    serviceTypes: [],
    tailMaterialData: [],
    tailMaterialDetail: [],
    tailMaterialDataQBItem: []
  });
  const [searching, setSearching] = useState(false);
  const [maintenanceTypeData, setMaintenanceTypeData] = useState<{
    maintenanceTypes: FeamEntities.IMaintenanceType[];
    maintenanceTypesDropdown: IDropdown[];
  }>({ maintenanceTypes: [], maintenanceTypesDropdown: [] });
  const { isTokenSet } = useTokenState();

  useEffect(() => {
    if (isTokenSet && maintenanceTypeData.maintenanceTypes.length === 0) {
      const fetchData = async () => {
        const result = await maintenanceTypeService.getAll();
        const dropdownData = result.map<IDropdown>(
          (d: FeamEntities.IMaintenanceType): IDropdown => {
            return { text: d.name, value: d.maintenanceTypeId?.toString() };
          }
        );
        setMaintenanceTypeData({
          maintenanceTypes: result,
          maintenanceTypesDropdown: dropdownData,
        });
      };
      fetchData();
    }
  }, [isTokenSet]);
  return (
    <div className="container cws-advance-search feam-m-5 w-100">
      <CwsGeneralHeader title="Billing Review" />
      <CwsAdvancedSearchSection
        onSearch={searchHandler}
        onClear={advanceSearchClearHandler}
        searching={searching}
        maintenanceTypesForDropdown={
          maintenanceTypeData.maintenanceTypesDropdown
        }
        searchType={searchType.billedReview}
      />
      {!data && <NoRecordFound message={"No Data found"} />}
      {data && (
        <div className="ps-3 pe-3 d-grid gap-3">
          <div className="d-flex d-flex-row w-100 gap-3 ">
            <div className="border rounded mb-3 w-100">
              <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 fw-bold rounded text-uppercase">
                <span>Contracts Info</span>
              </div>
              <div style={{ minHeight: "50px" }}>
                {generalData.activeContracts.length === 0 && (
                  <NoRecordFound message={"No Data found"} />
                )}
                {generalData.activeContracts.length > 0 && (
                  <ContractsInfo
                    contractsInfos={generalData.activeContracts ?? []}
                  />
                )}
              </div>
            </div>
          </div>
          <ServiceBillingInfoContainer serviceTypes={generalData.serviceTypes} billingInfos={generalData.billingInfos} />
          <div className="pt-0 d-grid gap-3">

            <HourTailSummaryContainer d407BillingReview={data} billingReviewData={[...(data.billingReviewData ?? [])]} tailMaterialsData={[...(generalData.tailMaterialDetail ?? [])]} tailMaterialDetailsData={[...(generalData.tailMaterialData ?? [])]} tailMaterialsQBItemData={[...(generalData.tailMaterialDataQBItem ?? [])]} />
            <>
              <BillingReviewWorkDetail
                billingReviewData={[...(data.billingReviewData ?? [])]}
              />
              <TailMaterialDetailView
                tailMaterialsData={[...(generalData.tailMaterialDetail ?? [])]}
              />
            </>
          </div>
        </div>
      )}
    </div>
  );

  function advanceSearchClearHandler() {
    setServiceType(undefined);
    setData(undefined);
  }

  async function searchHandler(filter: FeamEntities.IWorkSheetFilter) {
    if (
      ((!filter.weekNumber ||
        filter.weekNumber.length === 0) && (!filter.startDate || !filter.endDate)) ||
      !filter.customerId ||
      !filter.stationId
    ) {
      alert(
        "Please select week number or date range, customer and station. All are required fields."
      );
      return;
    }
    setSearching(true);

    const [reviewData, contracts, billings, services, tailMaterialData, tailMaterialQBItemData] =
      await Promise.all([
        billingReviewDataService.searchBillingReviewData(filter),
        cobblestoneContractsActiveService.searchCobblestoneContractsActive(
          filter
        ),
        cobblestoneContractsBillingInfoService.searchCobblestoneContractsBillingInfo(
          filter
        ),
        cobblestoneContractsServiceTypeService.searchCobblestoneContractsServiceType(
          filter
        ),
        billingTailMaterialDataService.searchBillingTailMaterialData(filter),
        billingTailMaterialQBItemDataService.searchBillingTailMaterialDataQBItem(filter)
      ]);

    //@ts-ignore next-line
    const groupByWorkTitles = Object.groupBy([...reviewData], (x) => {
      return x.workTitleName ?? "Other";
    });
    //@ts-ignore next-line
    const groupByTailMaterialData = Object.groupBy([...tailMaterialData], (x) =>
      (x.tail ?? "").trim()
    );
    const workTitleBillingDetail: FeamEntities.ID407WorkTitleBillingDetail[] =
      [];
    Object.keys(groupByWorkTitles).forEach((key) => {
      const items = groupByWorkTitles[key] ?? [];
      if (items.length > 0) {
        const totalHours = items.map(
          (m: FeamEntities.IBillingReviewData) => m.hours
        );
        const weekNumberAndHours = items.map(
          (m: FeamEntities.IBillingReviewData) => m.weekNum + "|" + m.hours
        );
        const extendedPrice = items.map(
          (m: FeamEntities.IBillingReviewData) =>
            +(m.extendedPrice ?? 0).toFixed(2)
        );
        workTitleBillingDetail.push({
          workTitle: key,
          //@ts-ignore next-line
          totalHours: sumOf(totalHours),
          totalExtendedPrice: sumOf(extendedPrice),
          weekNumberAndHours: weekNumberAndHours,
          quickBookPrince: items[0].quickBooksPrice,
          qbcustomerName: items[0].qbcustomerName,
          selectedQuickBooksItem: items[0].selectedQuickBooksItem,
        });
      }
    });

    const sumOfTailMaterialData: FeamEntities.ITailMaterialsData[] = [];
    Object.keys(groupByTailMaterialData).forEach((key) => {
      if (!key) return;
      sumOfTailMaterialData.push({
        tail: key,
        hydEngine1: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine1 ?? 0)
          )
        ).toString(),
        hydEngine2: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine2 ?? 0)
          )
        ).toString(),
        hydEngine3: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine3 ?? 0)
          )
        ).toString(),
        hydEngine4: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine4 ?? 0)
          )
        ).toString(),
        oilEngine1: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine1 ?? 0)
          )
        ).toString(),
        oilEngine2: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine2 ?? 0)
          )
        ).toString(),
        oilEngine3: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine3 ?? 0)
          )
        ).toString(),
        oilEngine4: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine4 ?? 0)
          )
        ).toString(),
        materialsOxygen: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.materialsOxygen ?? 0)
          )
        ).toString(),
        materialsNitrogen: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.materialsNitrogen ?? 0)
          )
        ).toString(),
        towing: groupByTailMaterialData[key]?.find(
          (x: FeamEntities.IBillingTailMaterialsData) => x.towing === true
        )
          ? 1
          : 0,
      });
    });

    const d407BillingReviews: FeamEntities.ID407BillingReview = {
      stationId: filter.stationId,
      customerId: filter.customerId,
      weekNumber: filter.weekNumber?.split(",").sort(),
      billingReviewData: reviewData,
      approvedBy: filter.approvedBy,
      workTitleBillingDetail: workTitleBillingDetail,
    };

    //@ts-ignore next-line
    const groupByContractServiceTypes = Object.groupBy([...services], (x) => {
      return (
        (x.cobblestoneContractServicesType ?? "").trim() +
        "|" +
        (x.cobblestoneArea ?? "").trim()
      );
    });
    const contractsServices: FeamEntities.IContractServiceType[] = [];
    Object.keys(groupByContractServiceTypes).forEach((key) => {
      const areaSplit = key.split("|");
      const ct: FeamEntities.ICobblestoneContractsServiceType[] =
        groupByContractServiceTypes[key] ?? [];
      const contractsServiceAreas: FeamEntities.IContractServiceTypeByArea[] =
        [];
      const contractsServiceTypes: FeamEntities.IContractServiceTypes[] = [];

      ct.forEach((contractType) => {
        contractsServiceTypes.push({
          cobblestoneServiceType: contractType.cobblestoneServiceType,
          cobblestoneRateUSD: contractType.cobblestoneRateUSD,
          hasBillingInfo: billings?.some(
            (s) =>
              s.cobblestoneServiceType === contractType.cobblestoneServiceType
          ),
        });
      });
      if (
        !contractsServiceAreas.find((s) => s.cobblestoneArea !== areaSplit[1])
      ) {
        contractsServiceAreas.push({
          cobblestoneArea: areaSplit[1].trim(),
          serviceTypes: contractsServiceTypes ?? [],
        });
      }
      const contractServiceType = contractsServices.find(
        (s) =>
          (!s.cobblestoneContractServicesType && areaSplit[0] === "") ||
          (s.cobblestoneContractServicesType &&
            s.cobblestoneContractServicesType.trim() === areaSplit[0])
      );
      if (!contractServiceType) {
        contractsServices.push({
          cobblestoneContractServicesType: areaSplit[0].trim(),
          areas: contractsServiceAreas,
        });
      } else {
        contractServiceType.areas?.push(...contractsServiceAreas);
      }
    });
    setData(d407BillingReviews);
    setServiceType(undefined);
    setGeneralData({
      activeContracts: contracts,
      billingInfos: billings,
      serviceTypes: contractsServices,
      tailMaterialData: sumOfTailMaterialData,
      tailMaterialDetail: tailMaterialData,
      tailMaterialDataQBItem: tailMaterialQBItemData
    });
    setSearching(false);
  }
}
