import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormArrayAutoCompleteInputForEmployee, FeamFormArrayTimeFieldInput, FeamFormArrayFieldInput, FeamFormArrayFieldSelect } from "../../common";
import * as BootStrapIcon from 'react-bootstrap-icons';

export default function CwsMechanicsAssignedWithOutWSForm(props: IProps.ICwsMechanicsAssignedWithOutWSFormProps<FeamEntities.IMechanics>) {
    const { formProps, deleteHandler, users, workTitles, payTypes } = props;
    return (
        <table className="table">
            <thead>
                <tr className="text-uppercase">
                    <th scope="col" style={{ width: "150px" }}>NAME</th>
                    <th scope="col" style={{ width: "90px" }}>IN</th>
                    <th scope="col" style={{ width: "90px" }}>OUT</th>
                    <th scope="col" style={{ width: "50px" }}>Hours</th>
                    <th scope="col" style={{ width: "100px" }}>Pay</th>
                    <th scope="col" style={{ width: "100px" }}>Position</th>
                    <th scope="col" style={{ width: "20px" }}></th>
                </tr>
            </thead>
            <tbody>
                {formProps.values.mechanics?.map((_: FeamEntities.IMechanicsAssigned, index: number) => {
                    return (
                        <tr key={`mechanicsAssigned_${index}`}>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayAutoCompleteInputForEmployee formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="mechanicName" className="customer-worksheet-form-control" data={[...users]} onchangeHandler={props.onAutoCompleteChangeHandler} />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftStartTime" onBlur={(e) => { setTimeDiff('mechanics', e.target.value ?? 'hh:mm', formProps.values.mechanics[index].shiftEndTime ?? 'hh:mm', index); formProps.handleBlur(e) }} className="form-control" />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftEndTime" onBlur={(e) => { setTimeDiff('mechanics', formProps.values.mechanics[index]?.shiftStartTime ?? 'hh:mm', e.target.value ?? 'hh:mm', index); formProps.handleBlur(e) }} className="form-control" />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftHours" className="customer-worksheet-form-tcontrol" readOnly={true} />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldSelect defaultText="Pay" data={[...payTypes]} formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="payCode" className="customer-worksheet-form-control" />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldSelect defaultText="Role" data={[...workTitles]} formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="workTitleCode" className="customer-worksheet-form-control" />
                            </td>
                            <td className="text-end">
                                <div className="d-flex d-inline-flex gap-1 align-items-center">
                                    <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(index)} title="Delete Mechanics" />
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )

    function setTimeDiff(arraySelector: string, start: string, end: string, index: number) {
        if ((!start && !end) || start == '' || end == '' || (start === "00:00" && end === "00:00") || (start.includes("hh") || start.includes("mm") || end.includes("hh") || end.includes("mm"))) {
            formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, "");
            return;
        }

        const startCollection = start?.split(":");
        const endCollection = end?.split(":");
        const startDate = new Date(0, 0, 0, +startCollection[0], +startCollection[1], 0);
        const endDate = new Date(0, 0, 0, +endCollection[0], +endCollection[1], 0);
        let diff = endDate.getTime() - startDate.getTime();
        let hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(diff / 1000 / 60);

        // If using time pickers with 24 hours format, add the below line get exact hours
        if (hours < 0)
            hours = hours + 24;

        const hm = (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
        formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, hm);
    }
}