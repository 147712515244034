import { IProps } from "../../../models/feam-entities";
import { sumOf } from "../../../models/feam-utils";
import { NoRecordFound } from "../common";

export default function TailMaterialView(props: IProps.ITailMaterialViewProps) {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 fw-bold rounded text-uppercase">
        <span>Tail Material Summary</span>
      </div>
      <div style={{ minHeight: "100px" }}>
        {props.data.length === 0 && <NoRecordFound message={"No Data found"} />}
        {props.data.length > 0 && (
          <div className="p-1 ps-2">
            <table className="table ">
              <thead>
                <tr>
                  <th>Tail</th>
                  <th className="text-nowrap">HYD Engine1</th>
                  <th className="text-nowrap">HYD Engine2</th>
                  <th className="text-nowrap">HYD Engine3</th>
                  <th className="text-nowrap">HYD Engine4</th>
                  <th className="text-nowrap">OIL Engine1</th>
                  <th className="text-nowrap">OIL Engine2</th>
                  <th className="text-nowrap">OIL Engine3</th>
                  <th className="text-nowrap">OIL Engine4</th>
                  <th className="text-nowrap">Towing</th>
                  <th className="text-nowrap">Oxygen</th>
                  <th className="text-nowrap">Nitrogen</th>
                </tr>
              </thead>
              <tbody>
                {props.data.map((h, i) => {
                  return (
                    <tr key={`tail_material_view_${i}`}>
                      <td>{h.tail}</td>
                      <td>{h.hydEngine1}</td>
                      <td>{h.hydEngine2}</td>
                      <td>{h.hydEngine3}</td>
                      <td>{h.hydEngine4}</td>
                      <td>{h.oilEngine1}</td>
                      <td>{h.oilEngine2}</td>
                      <td>{h.oilEngine3}</td>
                      <td>{h.oilEngine4}</td>
                      <td>{h.towing}</td>
                      <td>{h.materialsOxygen}</td>
                      <td>{h.materialsNitrogen}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="fw-bold">Total</td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.hydEngine1 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.hydEngine2 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.hydEngine3 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.hydEngine4 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.oilEngine1 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.oilEngine2 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.oilEngine3 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.oilEngine4 ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(props.data.map((h) => h.towing ?? 0)).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(
                      props.data.map((h) => +(h.materialsOxygen ?? 0))
                    ).toString()}
                  </td>
                  <td className="fw-bold">
                    {sumOf(props.data.map((h) =>+(h.materialsNitrogen ?? 0))).toString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
