import { MultiSelect } from "react-multi-select-component";
import { FeamEntities, IMultiSelectDropdown, IProps } from "../../../models/feam-entities";
import { useEffect, useState } from "react";

export default function UserManagementStations(props: IProps.IUserManagementStationsProps<FeamEntities.ID407userRole>) {
    const [stations, setStations] = useState<IMultiSelectDropdown[]>([])
    useEffect(() => {
        if (stations.length === 0 && props.formProps.values.d407userRoleStationMappers &&
            props.formProps.values.d407userRoleStationMappers.length > 0) {

            const oldStations: IMultiSelectDropdown[] = [];
            props.formProps.values.d407userRoleStationMappers.forEach((st) => {
                const station: IMultiSelectDropdown | undefined = props.multiStations.find(x => x.value.toString() === st.stationId?.toString());
                if (station)
                    oldStations.push(station)
            })
            setStations(oldStations)
        }
    }, [])

    useEffect(() => {
        if (props.formProps.values.d407userRoleTypeMappers && props.formProps.values.d407userRoleTypeMappers?.length > 0
            && props.formProps.values.d407userRoleTypeMappers[0].d407userRoleTypeId === 1

        ) {
            setStations([]);
        }
    }, [props.formProps.values.d407userRoleTypeMappers])
    return (
        <>
            <MultiSelect
                options={props.multiStations}
                value={stations ?? []}
                onChange={handleChange}
                labelledBy="Stations"
                disabled={props.disable}
                overrideStrings={{ selectSomeItems: "--Select Stations--" }}
            />
        </>
    )

    function handleChange(e: IMultiSelectDropdown[]) {
        if (e.length === 0) {
            props.formProps.setFieldValue("d407userRoleStationMappers", [])
        } else {
            const newStations: FeamEntities.ID407userRoleStationMapper[] = [];
            e.forEach((i) => newStations.push({ d407userRoleId: 0, stationId: +i.value }));
            props.formProps.setFieldValue("d407userRoleStationMappers", newStations)
        }
        setStations(e);
    }
}