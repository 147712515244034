import { FieldArray, FieldArrayRenderProps } from "formik";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormArrayFieldInput, FeamFormArrayTimeFieldInput, FeamFormInput } from "../../common";
import CwsWorkAccomplished from "../common/CwsWorkAccomplished";
import { usePrint } from "../../../PageLayout";
export default function CwsSingleTail(props: IProps.ICwsSingleTail<FeamEntities.ITailDetail>) {
  const { formProps, arraySelector, maintenanceTypes } = props;
  const { isPrint } = usePrint();

  const rowIndex = 0;
  return (
    <FieldArray
      name={arraySelector}
      render={(_: FieldArrayRenderProps) =>
      (
        <>
          {/*//@ts-ignore */}
          <div className="mb-1 w-100">
            {/*//@ts-ignore */}
            <div className={`${formProps.values.multiTail ? "" : "mb-1"} header-col w-100 p-1 fw-bold rounded text-uppercase`}>FLIGHT INFO</div>
            <div className="d-flex flex-row justify-content-start align-items-center gx-3 ps-2 mb-1 gap-5" >
              <div className="d-flex d-inline-flex gap-1">
                <label className="customer-worksheet-label col-form-label customer-worksheet-singletail-label-control-width">A/C Registration:</label>
                <div className="customD407-control-with-107">
                  <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="tail" className="customer-worksheet-form-control" />
                </div>
                <div className="customD407-control-with-98 ">
                </div>
              </div>
              <div className="d-flex d-inline-flex gap-1 ">
                <label className="customer-worksheet-label col-form-label customer-worksheet-singletail-label-control-width">Flight:</label>
                <div className="customD407-control-with-107">
                  <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="flightNum" className="customer-worksheet-form-control" />
                </div>
                <div className="customD407-control-with-98">
                </div>
              </div>
              <div className="d-flex d-inline-flex gap-1">
                <label className="customer-worksheet-label col-form-label customD407-control-with-67 ">Gate:</label>
                <div className="customD407-control-with-107">
                  <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="gateNum" className="customer-worksheet-form-control" />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center gx-3 ps-2 mb-1 gap-5" >
              <div className="d-flex d-inline-flex gap-1">
                <label className="customer-worksheet-label col-form-label customer-worksheet-singletail-label-control-width">Scheduled Arrival:</label>
                <div className="customD407-control-with-107">
                  {/*//@ts-ignore */}
                  {(!isPrint || (isPrint && formProps.values[arraySelector][rowIndex].eta)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="eta" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control flightInfo" />}
                  {/*//@ts-ignore */}
                  {isPrint && !formProps.values[arraySelector][rowIndex].eta && <input type="text" className="form-control customer-worksheet-form-control" />}
                </div>
                <div className="customD407-control-with-98">
                </div>
              </div>
              <div className="d-flex d-inline-flex gap-1">
                <label className="customer-worksheet-label col-form-label customer-worksheet-singletail-label-control-width">Actual Arrival:</label>
                <div className="customD407-control-with-107">
                  {/*//@ts-ignore */}
                  {(!isPrint || (isPrint && formProps.values[arraySelector][rowIndex].ata)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="ata" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control flightInfo" />}
                  {/*//@ts-ignore */}
                  {isPrint && !formProps.values[arraySelector][rowIndex].ata && <input type="text" className="form-control customer-worksheet-form-control" />}
                </div>
                <div className="customD407-control-with-98">
                </div>
              </div>
              <div className="d-flex  d-inline-flex gap-1">
                <label className="customer-worksheet-label col-form-label customD407-control-with-67">GU:</label>
                <div className="customD407-control-with-107">
                  <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="gu" className="customer-worksheet-form-control" />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center gx-3 ps-2 mb-1 gap-5" >
              <div className="d-flex  d-inline-flex gap-1">
                <label className="customer-worksheet-label col-form-label customer-worksheet-singletail-label-control-width">Scheduled Departure:</label>
                <div className="customD407-control-with-107">
                  {/*//@ts-ignore */}
                  {(!isPrint || (isPrint && formProps.values[arraySelector][rowIndex].etd)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="etd" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control flightInfo" />}
                  {/*//@ts-ignore */}
                  {isPrint && !formProps.values[arraySelector][rowIndex].etd && <input type="text" className="form-control customer-worksheet-form-control" />}
                </div>
                <div className="customD407-control-with-98">
                </div>
              </div>
              <div className="d-flex d-inline-flex gap-1">
                <label className="customer-worksheet-label col-form-label customer-worksheet-singletail-label-control-width">Actual Departure:</label>
                <div className="customD407-control-with-107">
                  {/*//@ts-ignore */}
                  {(!isPrint || (isPrint && formProps.values[arraySelector][rowIndex].atd)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="atd" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control flightInfo" />}
                  {/*//@ts-ignore */}
                  {isPrint && !formProps.values[arraySelector][rowIndex].atd && <input type="text" className="form-control customer-worksheet-form-control" />}
                </div>
              </div>
            </div>
          </div>
          {/*//@ts-ignore */}
          <div className={`${formProps.values.multiTail ? "" : "mb-1"} w-100`}>
            {/*//@ts-ignore */}
            <div className={` header-col w-100 p-1 fw-bold rounded text-uppercase`}>MATERIALS</div>
            <div className="d-flex flex-column ps-2 gx-3 mb-0">
              <div className="d-flex d-inline-flex gap-4">
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  APU
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  O1
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  O2
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  O3
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  O4
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  H1
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  H2
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  H3
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  H4
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  Ni
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                  Ox
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width pe-3">
                  OTHER
                </div>
              </div>
              <div className="d-flex d-inline-flex gap-4 mb-0">
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="apu" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="oilEngine1" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="oilEngine2" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="oilEngine3" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="oilEngine4" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="hydEngine1" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="hydEngine2" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="hydEngine3" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="hydEngine4" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="materialsNitrogen" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-dynamic-tail-min-control-width">
                    <FeamFormArrayFieldInput type="text" isNumeric formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="materialsOxygen" className="customer-worksheet-form-control" />
                  </div>
                </div>
                <div className="d-flex justify-content-start pe-3">
                  <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={rowIndex} feamSelector="materialsOther" className="customer-worksheet-form-control" />
                </div>
              </div>
            </div>

          </div>
          <CwsWorkAccomplished formProps={formProps} arraySelector="tailDetails" />
          {/*//@ts-ignore */}
          <div className={`${formProps.values.multiTail ? "" : "mb-2"}`}>
            {/*//@ts-ignore */}
            <div className={`fw-bold ${formProps.values.multiTail ? "" : "mb-2"} header-col w-100 p-1 fw-bold rounded text-uppercase`}>
              {/*//@ts-ignore */}
              {formProps.values.maintenanceTypeId && maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.isCourseType ? "Courses completed details" : "WORK DETAILS"}</div>
            <div className="d-flex flex-column ms-2 mt-2">
              {/*//@ts-ignore */}
              {formProps.values.tailDetails[rowIndex].workDetails.map((w, index) => {
                const id = `${arraySelector}.${[rowIndex]}.workDetails.${[index]}.description`;
                {/*//@ts-ignore */ }
                return <input key={`workDetail_${index}`} type="text" name={id} id={id} value={formProps.values.tailDetails[0]?.workDetails[index]?.description ?? ''} onChange={formProps.handleChange} className="form-control customer-worksheet-form-control" />
              })}
            </div>
          </div>


        </>
      )} />
  )

}