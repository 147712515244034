import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class BillingTailMaterialDataService {
  #rootPath: string = "billingtailmaterialsdata";
  async searchBillingTailMaterialData(
    filter: FeamEntities.IWorkSheetFilter
  ): Promise<FeamEntities.IBillingTailMaterialsData[]> {
    try {
      const response = await apiClient.get<FeamEntities.IBillingTailMaterialsData[]>(
        `${this.#rootPath}/search`,
        {
          params: filter,
        }
      );
      return response.data ?? [];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const billingTailMaterialDataService = new BillingTailMaterialDataService();
export default billingTailMaterialDataService;
