import { IProps } from "../../../models/feam-entities";
import ServiceInfo from "./ServiceInfo";

export default function ServiceInfoContainer(
  props: IProps.IServiceInfoContractProps
) {
  return (
    <div className="ps-2">
      {props.serviceTypes?.map((c, index) => {
        return (
          <ServiceInfo
            key={`contract_${index}`}
            cobblestoneContractServicesType={c.cobblestoneContractServicesType}
            areas={c.areas}
          />
        );
      })}
    </div>
  );
}
