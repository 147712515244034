import { Autocomplete, FilterOptionsState, TextField, createFilterOptions, styled } from "@mui/material";
import { useFeamFormArrayFieldControlProps } from "../../hooks";
import { IDropdown, IProps } from "../../../models/feam-entities";
import { autoCompleteStyle } from "../../../models/autoCompleteStyle";

const filter = createFilterOptions<IDropdown>()
export default function FeamFormArrayAutoCompleteInput<T>(props: IProps.IFeamFormArrayAutoCompleteInputProps<T>) {
    const [id, value, standardProps] = useFeamFormArrayFieldControlProps(props);
    const { formProps, data } = props;
    // @ts-ignore
    delete standardProps.data;
    return (
        <Autocomplete
            size={"small"}
            value={value ?? ''}
            // @ts-ignore
            onChange={changeHandler}
            filterOptions={filterHandler}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            readOnly={props.readOnly}
            id={id}
            options={data}
            // @ts-ignore
            getOptionLabel={(option) => {
                if (typeof option === 'string') return option;
                if (option.value) return option.value;
                return option.text;
            }}
            renderOption={(props, option) => <li {...props} className='feamAutoComplete'>{option.text}</li>}
            sx={autoCompleteStyle}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} className={`form-control ${props.className}`} placeholder={props.placeholder} />
            )}
        />

    )
    function filterHandler(options: IDropdown[], params: FilterOptionsState<IDropdown>) {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.value);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                value: inputValue,
                text: `Add "${inputValue}"`,
            });
        }
        return filtered;
    }

    function changeHandler(event: React.SyntheticEvent, newValue: IDropdown) {
        if (typeof newValue === 'string') {
            // @ts-ignore
            formProps.setFieldValue(id, newValue);
        } else if (newValue && newValue.value) {
            // Create a new value from the user input
            // @ts-ignore
            formProps.setFieldValue(id, newValue.value);
        } else {
            // @ts-ignore
            formProps.setFieldValue(id, newValue);
        }
    }
}