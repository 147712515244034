import { IProps } from "../../../../models/feam-entities";

export default function MaterialsInfoOfSummaryReport(props: IProps.IMaterialsInfoOfSummaryReportProps) {
    const { summaryReport } = props;
    return (
        <>
            <div className="d-flex flex-column w-100">
                <div className="d-flex mb-1 d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
                    MATERIALS:
                </div>
                <table className="table mb-1">
                    <tbody>
                        <tr className="text-uppercase">
                            <td className="fw-bold">O1</td>
                            <td>{summaryReport.o1}</td>
                            <td className="fw-bold">H1</td>
                            <td>{summaryReport.h1}</td>
                        </tr>
                        <tr className="text-uppercase">
                            <td className="fw-bold">O2</td>
                            <td>{summaryReport.o2}</td>
                            <td className="fw-bold">H2</td>
                            <td>{summaryReport.h2}</td>
                        </tr>
                        <tr className="text-uppercase">
                            <td className="fw-bold">O3</td>
                            <td>{summaryReport.o3}</td>
                            <td className="fw-bold">H3</td>
                            <td>{summaryReport.h3}</td>
                        </tr>
                        <tr className="text-uppercase">
                            <td className="fw-bold">O4</td>
                            <td>{summaryReport.o4}</td>
                            <td className="fw-bold">H4</td>
                            <td>{summaryReport.h4}</td>
                        </tr>
                        <tr className="text-uppercase">
                            <td className="fw-bold">NITROGEN</td>
                            <td>{summaryReport.nitrogen ?? 0}</td>
                            <td className="fw-bold">OXYGEN</td>
                            <td>{summaryReport.oxygen ?? 0}</td>
                        </tr>
                        <tr className="text-uppercase">
                            <td className="fw-bold">OTHER</td>
                            <td>{summaryReport.other ?? 0}</td>
                            <td className="fw-bold">APU</td>
                            <td>{props.summaryReport.apu ?? 0}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}