import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormArrayFieldInput } from "../../common";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS } from "react-device-detect";

export default function CwsPersonalExpenses(props: IProps.ICwsPersonalExpensesProps<FeamEntities.ID407Data>) {
    const { isPrint } = usePrint();
    const { formProps } = props;
    if (!formProps.values.personalExpenses || formProps.values.personalExpenses.length === 0) {
        formProps.values.personalExpenses = [{}];
    }
    return (
        <div className={`${isPrint && isMobile && isIOS ? "mb-0" : "mb-3"} text-uppercase w-100`}>
            <div className="d-flex d-inline-flex align-items-center gx-3 p-0 ps-2 gap-3 mb-0 w-100" >
                <div className="d-flex d-inline-flex">
                    <span className="col-form-label fw-bold customer-worksheet-singletail-label-control-width text-uppercase text-nowrap">Personal Expenses</span>
                </div>
                <div className="d-flex d-inline-flex">
                    <div className="d-flex align-items-center gap-1">
                        <div className="form-check d-inline-flex gap-1  pe-0">
                            {/*//@ts-ignore */}
                            <input checked={!formProps.values.personalExpenses[0]?.baggage ? false : true} className="form-check-input" type="checkbox" disabled={true} />
                            <label className="customer-worksheet-label  col-form-label w-90" >
                                Baggage
                            </label>
                        </div>
                        <div className="customer-worksheet-form-control-width">
                            {/*//@ts-ignore */}
                            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector="personalExpenses" feamItemIndex={0} feamSelector="baggage" className="customer-worksheet-form-control" />
                        </div>
                    </div>
                </div>
                <div className="d-flex d-inline-flex">
                    <div className="d-flex align-items-center gap-1">
                        <div className="form-check d-inline-flex gap-1  pe-0">
                            {/*//@ts-ignore */}
                            <input checked={!formProps.values.personalExpenses[0]?.rentalFuel ? false : true} className="form-check-input" type="checkbox" disabled={true} />
                            <label className="customer-worksheet-label  col-form-label text-nowrap w-90" >
                                Rental Fuel
                            </label>
                        </div>
                        <div className="customer-worksheet-form-control-width">
                            {/*//@ts-ignore */}
                            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector="personalExpenses" feamItemIndex={0} feamSelector="rentalFuel" className="customer-worksheet-form-control" />
                        </div>
                    </div>
                </div>
                <div className="d-flex d-inline-flex">
                    <div className="d-flex align-items-center gap-1">
                        <div className="form-check d-inline-flex gap-1  pe-0">
                            {/*//@ts-ignore */}
                            <input checked={!formProps.values.personalExpenses[0]?.taxi ? false : true} className="form-check-input" type="checkbox" disabled={true} />
                            <label className="customer-worksheet-label  col-form-label w-90" >
                                Taxi
                            </label>
                        </div>
                        <div className="customer-worksheet-form-control-width">
                            {/*//@ts-ignore */}
                            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector="personalExpenses" feamItemIndex={0} feamSelector="taxi" className="customer-worksheet-form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex d-inline-flex align-items-center gx-3  p-0 ps-2 gap-3 mb-0 w-100" >
                <div className="d-flex  d-inline-flex">
                    {/** empty space */}
                    <span className="col-form-label fw-bold customer-worksheet-singletail-label-control-width text-uppercase text-nowrap"></span>
                </div>
                <div className="d-flex  d-inline-flex">
                    <div className="d-flex align-items-center gap-1">
                        <div className="form-check d-inline-flex gap-1 pe-0">
                            {/*//@ts-ignore */}
                            <input checked={!formProps.values.personalExpenses[0]?.parking ? false : true} className="form-check-input" type="checkbox" disabled={true} />
                            <label className="customer-worksheet-label  col-form-label w-90" >
                                Parking
                            </label>
                        </div>
                        <div className="customer-worksheet-form-control-width">
                            {/*//@ts-ignore */}
                            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector="personalExpenses" feamItemIndex={0} feamSelector="parking" className="customer-worksheet-form-control" />
                        </div>
                    </div>
                </div>
                <div className="d-flex  d-inline-flex">
                    <div className="d-flex align-items-center gap-1">
                        <div className="form-check d-inline-flex gap-1 pe-0">
                            {/*//@ts-ignore */}
                            <input checked={!formProps.values.personalExpenses[0]?.mileage ? false : true} className="form-check-input" type="checkbox" disabled={true} />
                            <label className="customer-worksheet-label  col-form-label text-nowrap w-90" >
                                Mileage
                            </label>
                        </div>
                        <div className="customer-worksheet-form-control-width">
                            {/*//@ts-ignore */}
                            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector="personalExpenses" feamItemIndex={0} feamSelector="mileage" className="customer-worksheet-form-control" />
                        </div>
                    </div>
                </div>
                <div className="d-flex  d-inline-flex">
                    <div className="d-flex align-items-center gap-1">
                        <div className="d-flex align-items-center form-check d-inline-flex gap-1 pe-0">
                            {/*//@ts-ignore */}
                            <input checked={!formProps.values.personalExpenses[0]?.other ? false : true} className="form-check-input" type="checkbox" onChange={(e) => setUnRegisterItems(p => ({ ...p, ni: e.target.checked }))} disabled={true} />
                            <label className="customer-worksheet-label  col-form-label w-90" >
                                Other
                            </label>
                        </div>
                        <div className="customer-worksheet-form-control-width">
                            {/*//@ts-ignore */}
                            <FeamFormArrayFieldInput formProps={formProps} feamArraySelector="personalExpenses" feamItemIndex={0} feamSelector="other" className="customer-worksheet-form-control" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}