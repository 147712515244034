import { FeamEntities, IProps } from "../../../models/feam-entities";
import {
  FeamFormInput,
  FeamFormTimeFieldInput,
} from "../../common";
import CswDynamicTailDescForm from "./CswDynamicTailDescForm";

export default function CswDynamicTailForm(
  props: IProps.ICswDynamicTailFormProps<FeamEntities.ITailDetail>
) {
  const { formProps, maintenanceTypeId, maintenanceTypes } = props;
  return (
    <fieldset
      className="customer-worksheet-tail-form"
      disabled={formProps.isSubmitting}
    >
      <div>
        <div className="mb-3">
          {formProps.errors.tail && formProps.touched.tail && (
            <div className="d-flex">
              <span className="d-flex justify-content-start text-danger">
                {formProps.errors.tail}
              </span>
            </div>
          )}
          <div className="d-flex d-inline-flex customer-worksheet-mgap customer-worksheet-multiletail-gap text-uppercase">
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
              TAIL
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
              FLIGHT#
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
              GATE
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
              Date
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
              ETA
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
              ATA
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
              ETD
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
              ATD
            </div>
            <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
              GU
            </div>
          </div>
          <div className="d-flex d-inline-flex customer-worksheet-multiletail-gap mb-1">
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-bg-control-width">
                <FeamFormInput
                  formProps={formProps}
                  feamSelector="tail"
                  className="customer-worksheet-form-control"
                  onBlur={formProps.handleBlur}
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-bg-control-width">
                <FeamFormInput
                  formProps={formProps}
                  feamSelector="flightNum"
                  className="customer-worksheet-form-control"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormInput
                  formProps={formProps}
                  feamSelector="gateNum"
                  className="customer-worksheet-form-control"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-bg-control-width">
                <FeamFormInput
                  type="date"
                  formProps={formProps}
                  feamSelector="arrivalDate"
                  className="customer-worksheet-form-control"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormTimeFieldInput
                  type="text"
                  formProps={formProps}
                  feamSelector="eta"
                  onBlur={(e) => {
                    formProps.handleBlur(e);
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormTimeFieldInput
                  type="text"
                  formProps={formProps}
                  feamSelector="ata"
                  onBlur={(e) => {
                    formProps.handleBlur(e);
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormTimeFieldInput
                  type="text"
                  formProps={formProps}
                  feamSelector="etd"
                  onBlur={(e) => {
                    formProps.handleBlur(e);
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormTimeFieldInput
                  type="text"
                  formProps={formProps}
                  feamSelector="atd"
                  onBlur={(e) => {
                    formProps.handleBlur(e);
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormInput type="text" formProps={formProps} feamSelector="gu" className="customer-worksheet-form-control" />
              </div>
            </div>
          </div>
          <div className="d-flex d-inline-flex align-items-center gap-2">
            <div className="form-check d-inline-flex gap-1">
              {/*//@ts-ignore */}
              <input
                type="checkbox"
                name="ron"
                checked={formProps.values.ron ?? false}
                onChange={formProps.handleChange}
                className="form-check-input"
              />
              <label className="customer-worksheet-label form-check-label pt-1">
                RON
              </label>
            </div>
            <div className="form-check d-inline-flex gap-1">
              {/*//@ts-ignore */}
              <input
                type="checkbox"
                name="towing"
                checked={formProps.values.towing ?? false}
                onChange={formProps.handleChange}
                className="form-check-input"
              />
              <label className="customer-worksheet-label form-check-label pt-1">
                Towing
              </label>
            </div>
          </div>
        </div>

        <div className="d-flex  d-flex justify-content-start align-items-center header-col w-100 p-1 fw-bold mb-2 rounded text-uppercase">
          <span className="text-uppercase fw-bold">Material</span>
        </div>
        <div className="d-flex d-inline-flex mb-3">
          <div className="d-flex flex-column">
            <div className="d-flex d-inline-flex customer-worksheet-mgap customer-worksheet-multiletail-gap">
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                APU
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                O1
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                O2
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                O3
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                O4
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                H1
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                H2
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                H3
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                H4
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                Ni
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                Ox
              </div>
              <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
                OTHER
              </div>
            </div>
            <div className="d-flex d-inline-flex customer-worksheet-multiletail-gap mb-3">
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="apu"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="oilEngine1"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="oilEngine2"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="oilEngine3"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="oilEngine4"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="hydEngine1"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="hydEngine2"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="hydEngine3"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="hydEngine4"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="materialsNitrogen"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start ">
                <div className="customer-worksheet-dynamic-tail-min-control-width">
                  <FeamFormInput
                    type="number"
                    min={0}
                    formProps={formProps}
                    feamSelector="materialsOxygen"
                    className="customer-worksheet-form-control"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <FeamFormInput
                  formProps={formProps}
                  feamSelector="materialsOther"
                  className="customer-worksheet-form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <CswDynamicTailDescForm
          formProps={formProps}
          maintenanceTypeId={maintenanceTypeId}
          maintenanceTypes={maintenanceTypes}
        />
      </div>
    </fieldset>
  );
}
