import { useFeamFormControlProps } from "../../hooks";
import { IProps } from "../../../models/feam-entities";

export default function FeamFormSelect<T>(props: IProps.IFeamSelectorProps<T>) {

    const [id, value, standardProps] = useFeamFormControlProps<IProps.IFeamSelectorProps<any>>(props);
    // @ts-ignore
    delete standardProps.defaultText;
    // @ts-ignore
    delete standardProps.data;
    delete standardProps.hideLoader;

    return (
        <>
            {(!props.hideLoader && props.data.length === 0) && <div className="d-flex justify-content-center"><div className="spinner-grow spinner-grow-sm text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div></div>}
            {(props.hideLoader || (!props.hideLoader && props.data.length > 0)) &&
                //@ts-ignore
                <select {...standardProps} id={id} name={id} value={value || (props.value ?? "")} onChange={props.onChange || props.formProps?.handleChange} className={`form-select ${props.className}`}>
                    <option value="">{!props.defaultText ? "--Select--" : `--${props.defaultText}--`}</option>
                    {props.data?.map((d, i) => {
                        return <option key={`${id}_${i}`} value={d.value}>{d.text}</option>
                    })}
                </select>}
        </>
    );
}

