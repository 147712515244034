import { useState } from "react";
import { IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from "react-bootstrap-icons";
import ServiceInfoContainer from "./ServiceInfoContainer";
import { NoRecordFound } from "../common";
import BillingInfo from "./BillingInfo";
import { useServiceType } from "./BillingReviewContainer";

export default function ServiceBillingInfoContainer(
  props: IProps.IServiceBillingInfoProps
) {
  const { serviceType, setServiceType } = useServiceType();
  const [showServiceAndBillingDetail, setShowServiceAndBillingDetail] = useState(false);
  return (
    <>
   <button
            type="button"
            className="btn btn-light-secondary btn-block w-100 text-uppercase m-0  p-2  feam-font-size-sm text-start align-items-center d-flex gap-2 fw-bold"
            onClick={() => setShowServiceAndBillingDetail((p) => !p)}
            aria-controls="Full Detail"
            aria-expanded={showServiceAndBillingDetail}
          >
            {!showServiceAndBillingDetail ? (
              <>
                {" "}
                <BootStrapIcon.ArrowsFullscreen size={16} /> Show Services Info
                & Billing Info
              </>
            ) : (
              <>
                <BootStrapIcon.FullscreenExit size={16} /> Hide Services Info &
                Billing Info
              </>
            )}
          </button>
          {showServiceAndBillingDetail && (
            <div className="d-flex d-flex-row w-100 gap-3 ">
              <div className="w-50 border rounded">
                <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 fw-bold rounded text-uppercase">
                  <span>Services Info</span>
                </div>
                <div style={{ minHeight: "100px" }}>
                  {props.serviceTypes.length === 0 && (
                    <NoRecordFound message={"No Data found"} />
                  )}
                  {props.serviceTypes.length > 0 && (
                    <ServiceInfoContainer
                      serviceTypes={props.serviceTypes ?? []}
                    />
                  )}
                </div>
              </div>
              <div className="w-50 border rounded">
                <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 pt-1 pb-1 fw-bold rounded text-uppercase">
                  <span>Billing Info</span>
                  <button
                    type="button"
                    title="Reset Service Type"
                    className="btn btn-secondary text-uppercase feam-title-btn-padding justify-content-center align-items-center"
                    onClick={() => setServiceType(undefined)}
                    disabled={!serviceType}
                  >
                    <BootStrapIcon.Eraser size={16} />
                  </button>
                </div>
                <div style={{ minHeight: "100px" }}>
                  {props.billingInfos.length === 0 && (
                    <NoRecordFound message={"No Data found"} />
                  )}
                  {props.billingInfos.length > 0 && (
                    <BillingInfo
                      billingInfos={props.billingInfos ?? []}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
  </>
  );
}
