import { useEffect } from "react"
import { pageTitle } from "../../models/constants"
import "./FeamMain.css";
import BillingReviewContainer from "../customer-worksheet/billing-review/BillingReviewContainer";

export default function BillingReviewPage() {
  useEffect(() => { document.title = pageTitle.billingReview }, [])
  return (
    <div className="d-flex w-100 m-0 p-0">
    <BillingReviewContainer />
    </div>
  )
}