import { FeamEntities, IProps } from "../../../models/feam-entities";
import { NoRecordFound } from "../common";
import * as BootStrapIcon from 'react-bootstrap-icons';

export default function UserManagementGrid(props: IProps.IUserManagementGridProps) {
    return (
        <div>
            {props.d407UserRoles.length === 0 && <NoRecordFound message={"No Data found"} />}
            {props.d407UserRoles.length > 0 && (
                <div className="ps-3  pe-3">
                    <table className="table table-striped advanced-search-table">
                        <thead>
                            <tr className="">
                                <th scope="col">Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Emp Code</th>
                                <th scope="col">Role Type</th>
                                <th scope="col">Stations</th>
                              { !props.filter.isInActive && <th scope="col"></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {props.d407UserRoles.map((m, i) => {
                                return (
                                    <tr key={`userRole_${i}`}>
                                        <td scope="col">{m.d407userRoleId}</td>
                                        <td scope="col" className="text-nowrap">{getFullName(m)}</td>
                                        <td scope="col">{m.userEmail}</td>
                                        <td scope="col" >{m.employeeCode}</td>
                                        <td scope="col" className="text-nowrap">{getRoleType(m)}</td>
                                        <td scope="col">{getStations(m)}</td>
                                        { !props.filter.isInActive && <td scope="col" className="text-nowrap">
                                            <BootStrapIcon.PencilSquare role="button" className="text-secondary" size={16} onClick={() => { props.onEdit(m) }} title="Edit" />
                                            <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => { props.onDelete(m) }} title="Delete" />
                                        </td>}
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>

            )}</div>
    )
    function getFullName(m: FeamEntities.ID407userRole): string {
        const employee = props.paylocityEmployees.find(x => x.employeeCode?.trim() === m.employeeCode?.trim());
        return employee?.firstName + " " + employee?.lastName;
    }

    function getRoleType(m: FeamEntities.ID407userRole): string | undefined {

        if (m.d407userRoleTypeMappers && m.d407userRoleTypeMappers?.length > 0) {
            //@ts-ignore
            const userRoleTypeId = m.d407userRoleTypeMappers[0].d407userRoleTypeId;
            const userRoleType = props.userRoleTypes.find(x => x.value === userRoleTypeId?.toString().trim())?.text;
            return userRoleType;
        }
        return "";
    }

    function getStations(m: FeamEntities.ID407userRole): string | undefined {
        //@ts-ignore
        const stationIds: string[] = m.d407userRoleStationMappers?.map(d => d.stationId.toString());
        if (stationIds.length === 0) {
            return "All station access";
        }
        //@ts-ignore
        const stations = props.stations.filter(x => stationIds.includes(x.value?.trim())).map(d => d.text).join(', ');
        return stations;
    }
}