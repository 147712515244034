import { resultStatusType } from "../models/constants";
import {
  FeamEntities,
  IDropdown,
  IMultiSelectDropdown,
} from "../models/feam-entities";
import { formatDate } from "../models/feam-utils";
import apiClient from "./http-common";

export interface IResultStatus {
  saved: string;
  updated: string;
  failed: string;
  print: string;
}
export const resultStatus: IResultStatus = {
  saved: "saved",
  updated: "updated",
  failed: "failed",
  print: "print",
};
class CustomerWorksheetService {
  #rootPath: string = "customerworksheet";
  async saveCustomerWorksheet(
    data: FeamEntities.ID407Data,
  ): Promise<{ status: string; id: number }> {
    try {
      if (!data.d407id) {
        let response = await apiClient.post<number>(this.#rootPath, data);
        return { status: resultStatus.saved, id: +response.data };
      } else {
        await apiClient.put(this.#rootPath, data);
        return { status: resultStatus.updated, id: data.d407id };
      }
    } catch (err: any) {
      console.log(err.response?.data || err);
      return { status: resultStatus.failed, id: -1 };
    }
  }
  async searchCustomerWorksheet(
    filter: FeamEntities.IWorkSheetFilter,
  ): Promise<IDropdown[]> {
    let dropdownData: IDropdown[] = [];
    try {
      const response = await apiClient.get<FeamEntities.ID407Data[]>(
        `${this.#rootPath}/search`,
        {
          params: filter,
        },
      );
      dropdownData = response.data.map<IDropdown>(
        (d: FeamEntities.ID407Data): IDropdown => {
          return {
            text: `Id: ${d.d407id} Station: ${d.stationCode} Date: ${formatDate(
              new Date(d.entryDate ?? ""),
            )}`,
            value: d.d407id?.toString(),
          };
        },
      );
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return dropdownData;
  }
  async advanceSearchForCustomerWorksheet(
    filter: FeamEntities.IWorkSheetFilter,
  ): Promise<FeamEntities.ID407Data[]> {
    let data: FeamEntities.ID407Data[] = [];
    try {
      const response = await apiClient.get<FeamEntities.ID407Data[]>(
        `${this.#rootPath}/search`,
        {
          params: filter,
        },
      );
      return response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return data;
  }

  async getByIdAsync(id: number): Promise<FeamEntities.ID407Data | undefined> {
    try {
      const response = await apiClient.get<FeamEntities.ID407Data>(
        `${this.#rootPath}/getbyId`,
        {
          params: { id: id },
        },
      );
      return response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return undefined;
  }

  async summaryReportOfCustomerWorksheet(
    filter: FeamEntities.IWorkSheetFilter,
  ): Promise<FeamEntities.ID407Data[]> {
    try {
      const response = await apiClient.get<FeamEntities.ID407Data[]>(
        `${this.#rootPath}/summmaryreport`,
        {
          params: filter,
        },
      );
      return response.data ?? [];
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }

  async updateStatusAsync(
    processUpdate: FeamEntities.IProcessUpdate,
  ): Promise<number> {
    try {
      const response = await apiClient.post<number>(
        `${this.#rootPath}/updatestatus`,
        processUpdate,
      );
      return +response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return resultStatusType.failed;
  }

  async getServiceTypes(): Promise<IMultiSelectDropdown[]> {
    try {
      const response = await apiClient.get<string[]>(
        `${this.#rootPath}/servicetypes`,
      );
      const dropdownData = response.data.map<IMultiSelectDropdown>(
        (d: string): IMultiSelectDropdown => {
          return { label: d, value: d };
        },
      );
      return dropdownData;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const customerWorksheetService = new CustomerWorksheetService();
export default customerWorksheetService;
