import { IProps } from "../../../../models/feam-entities";

export default function GeneralInfoOfSummaryReport(props: IProps.IGeneralInfoOfSummaryReportProps) {
    return (
        <div className="d-flex flex-row gap-1 w-100 p-1">
            <div className="d-flex d-inline-flex flex-row flex-fill gap-1">
                <span className="fw-bold text-uppercase"> Week/Date:</span>
                <span>{props.summaryReport.weekAndDate ? props.summaryReport.weekAndDate.split('w')[1] ?? '' : ''}</span>
            </div>
            <div className="d-flex flex-fill flex-row gap-1">
                <span className="d-flex fw-bold text-uppercase">Customer:</span>
                <span>{props.summaryReport.customer ?? ''}</span>
            </div>
            <div className="d-flex flex-row gap-1">
                <span className="fw-bold text-uppercase"> Station:</span>
                <span>{props.summaryReport.stationCode ?? ''}</span>
            </div>
        </div>
    );
}