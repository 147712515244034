import { FieldArray, FieldArrayRenderProps } from "formik";
import { IProps, FeamEntities } from "../../../models/feam-entities";
import CwsMultiTailFields from "./CwsMultiTailFields";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS } from "react-device-detect";
import CwsWorkAccomplished from "../common/CwsWorkAccomplished";
export default function CwsMultiTail(props: IProps.ICwsMultiTailProps<FeamEntities.ITailDetail>) {
    const { isPrint } = usePrint();

    const { formProps, arraySelector, maintenanceTypes } = props;
    return (
        <>
            <CwsWorkAccomplished formProps={formProps} arraySelector="tailDetails" />
            <div className={`${isPrint && isMobile && isIOS ? "mb-0 mt-0" : "mb-1"} mt-1`}>
                <span className="d-flex header-col fw-bold text-uppercase">
                    {/*//@ts-ignore */}
                    {formProps.values.maintenanceTypeId && maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.isCourseType ? "Courses completed details" : "WORK DETAILS"}
                </span >
                <div className="d-flex flex-column ps-3">
                    <div className="">
                        <div className="d-flex d-inline-flex customer-worksheet-mgap customer-worksheet-multiletail-gap">
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">TAIL</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">FLIGHT#</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">GATE</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">ETA</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">ATA</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">ETD</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">ATD</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">GU</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">APU</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">O1</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">O2</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">O3</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">O4</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">H1</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">H2</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">H3</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">H4</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">Ni</div>
                            <div className="d-flex  col-form-label fw-bold justify-content-start customer-worksheet-multiletail-min-control-width">Ox</div>
                        </div>
                        <FieldArray
                            name={arraySelector}
                            render={(_: FieldArrayRenderProps) =>
                            (
                                <>
                                    {/*//@ts-ignore */}
                                    {formProps.values.tailDetails.map((x, i) => {
                                        return <CwsMultiTailFields key={`multiTail_${i}`} itemIndex={i} arraySelector={arraySelector} formProps={formProps} />
                                    })}
                                </>
                            )} />
                    </div>
                </div>
            </div >
        </>
    )
}