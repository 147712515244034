import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";


class AimsflightDataService {
  #rootPath: string = "aimsflightdata";
  async getByFlightNo(flightNo:string): Promise<FeamEntities.IAimsflightData[]> {
    try {
      const reponse = await apiClient.get<FeamEntities.IAimsflightData[]>(
        `${this.#rootPath}`,
        {
          params: { flightNo: flightNo },
        }
      );
      return reponse.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const aimsflightDataService = new AimsflightDataService();
export default aimsflightDataService;
