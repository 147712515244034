import { useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import { NoRecordFound } from "../common";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CwsPersonalExpensesForm from "./CwsPersonalExpensesForm";
import { useDirty } from "./DynamicWorkSheet";

const initialExpenses: { expensesData: FeamEntities.IExpenses, action: 'add' | 'edit' | undefined } = { expensesData: { expenses: [] }, action: undefined };

export default function CwsDynamicPersonalExpenses(props: IProps.ICwsDynamicPersonalExpensesProps<FeamEntities.ID407Data>) {
    const [expenses, setExpenses] = useState({ ...initialExpenses });
    const [, setDeleteToggle] = useState(false);
    const { isDirty, setIsDirty } = useDirty();
    const { formProps, print, users } = props;
    return (
        <>
            <div className="d-flex d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
                <div>Personal Expenses</div>
                {!print && formProps.values.personalExpenses && formProps.values.personalExpenses.length > 0 &&
                    <div className="d-flex align-items-center">
                        <BootStrapIcon.PencilFill role="button" className="text-body" size={16} onClick={() => { setExpenses({ expensesData: { expenses: [...formProps.values.personalExpenses ?? []] }, action: 'edit' }) }} title="Edit Personal Expenses" />
                    </div>}
            </div>
            {(!formProps.values.personalExpenses || formProps.values.personalExpenses.length === 0) &&
                <NoRecordFound message="No Personal Expenses found" className="mb-3 mt-3" />
            }
            {formProps.values.personalExpenses && formProps.values.personalExpenses.length > 0 && <table className="table dynamic-tail-table m-0 p-0 mt-2 mb-1">

                <thead className="">
                    <tr className="text-uppercase">
                        <th scope="col">Name</th>
                        <th scope="col">Baggage</th>
                        <th scope="col">Rental Fuel</th>
                        <th scope="col">Taxi</th>
                        <th scope="col">Parking</th>
                        <th scope="col">Mileage</th>
                        <th scope="col">Other</th>
                        {!print && <th scope="col"></th>}
                    </tr>
                </thead>
                <tbody>
                    {formProps.values.personalExpenses?.map((item, index) => {
                        return (
                            <tr key={`${item.employeeName}_${index}`}>
                                <td>{item.employeeName}</td>
                                <td>{item.baggage}</td>
                                <td>{item.rentalFuel}</td>
                                <td>{item.taxi}</td>
                                <td>{item.parking}</td>
                                <td>{item.mileage}</td>
                                <td>{item.other}</td>
                                {!print && <td className="text-end">
                                    <div className="d-flex d-inline-flex gap-1">
                                        {/*//@ts-ignore */}
                                        <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(formProps, index)} title="Delete Personal Expenses" />
                                    </div>
                                </td>}
                            </tr>)
                    })
                    }
                </tbody>
            </table>}
            {!print && formProps.values.personalExpenses?.length === 0 && <button type="button" className="btn btn-outline-secondary btn-block w-100 text-uppercase m-0 p-0 mb-1" onClick={() => setExpenses({ expensesData: { expenses: [] }, action: 'add' })} title="Add Personal Expenses"><BootStrapIcon.PlusCircleFill size={16} /> Add Personal Expenses</button>}
            {expenses.action !== undefined && <Formik
                initialValues={{ ...expenses.expensesData }}
                enableReinitialize={true}
                onSubmit={async (
                    values: FeamEntities.IExpenses,
                    formikHelpers: FormikHelpers<FeamEntities.IExpenses>
                ) => {
                    await addAndUpdateExpensesHandler(values);
                    resetExpenses(formikHelpers);
                }}
            >
                {(fprops: FormikProps<FeamEntities.IExpenses>) => {

                    return (
                        <Modal isOpen={expenses.action !== undefined} scrollable size="lg">
                            <ModalHeader className="text-uppercase">
                                {expenses.action && (expenses.action === 'edit' ? "Edit Personal Expenses" : "Add Personal Expenses")}
                            </ModalHeader>
                            <ModalBody className="p-3">
                                <div className="mb-3">
                                    <CwsPersonalExpensesForm formProps={fprops} users={users} />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-secondary" onClick={() => { fprops.submitForm(); }} title={expenses.action === "add" ? "Add" : "Update"} disabled={fprops.isSubmitting}><BootStrapIcon.SaveFill size={16} />&nbsp;{expenses.action === 'add' ? "Add" : "Update"}</button>
                                <button type="button" className="btn btn-outline-secondary" onClick={() => expensesCancelHandler(fprops)} title="Cancel"><BootStrapIcon.DashCircleFill size={16} />&nbsp;Cancel</button>
                            </ModalFooter>
                        </Modal>)
                }}
            </Formik >}
        </>

    )
    function resetExpenses(formikHelpers: FormikHelpers<FeamEntities.IExpenses>) {
        formikHelpers.resetForm({ values: { expenses: [...formProps.values.personalExpenses ?? []] } });
    }

    function expensesCancelHandler(fprops: FormikProps<FeamEntities.IExpenses>) {
        setExpenses({ expensesData: { expenses: [] }, action: undefined });
        fprops.resetForm();
    }
    function addAndUpdateExpensesHandler(values: FeamEntities.IExpenses): void | Promise<any> {
        formProps.setFieldValue("personalExpenses", [...values.expenses]);
        setExpenses({ expensesData: { expenses: [] }, action: undefined });
        setIsDirty(true);
    }

    function deleteHandler(formProps: FormikProps<FeamEntities.ID407Data>, index: number) {
        //@ts-ignore
        if (window.confirm(`Are you sure! You want to delete the ${formProps.values.personalExpenses[index].employeeName} from Personal Expenses list.`)) {
            formProps.values.personalExpenses?.splice(index, 1);
            setDeleteToggle(prev => !prev);
            setIsDirty(true);
        }
    }
}