import { FeamEntities, IMultiSelectDropdown } from "../models/feam-entities";
import apiClient from "./http-common";

class WeekNumberService {
  #rootPath: string = "weeknumber";
  async getAll(): Promise<IMultiSelectDropdown[]> {
    try {
      const res = await apiClient.get<FeamEntities.ID407WeekNumber[]>(
        this.#rootPath,
      );
      const weekNumbers = res.data.map<IMultiSelectDropdown>(
        (d: FeamEntities.ID407WeekNumber): IMultiSelectDropdown => {
          return {
            label: d.weekNumber ?? "",
            value: d.weekNumber ?? "",
          };
        },
      );

      return weekNumbers;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const weekNumberService = new WeekNumberService();
export default weekNumberService;
