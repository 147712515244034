import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import logo from "./assets/images/logo.png";
import winIcon from "./assets/images/win-icon.png";
import "./SignIn.css";
import { loginRequest } from "./authConfig";
import { useEffect } from "react";
import { pageTitle } from "./models/constants";
import { InteractionStatus } from "@azure/msal-browser";
export default function SingIn() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    document.title = pageTitle.singIn;
  }, []);

  return (
    <div className="feam-app">
      <div className="feam-app-centerContent feam-app-aircraftbg col-12">
        <div className="child feam-app-centerBox col-lg-6">
          <div className="container">
            <div className="row p-5">
              <div className="col">
                <img src={logo} width="224" />
              </div>
            </div>
            <div className="row">
              <div className="col h1">Welcome Back</div>
            </div>

            <div className="row">
              <div className="col h4">Login to your account</div>
            </div>

            <div className="row p-5">
              <div className="col">
                <div className="feam-app-divBtn" onClick={signInHandler}>
                  <img src={winIcon} style={{ width: "40px" }} />
                  &nbsp;&nbsp; Sign in with Microsoft
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function signInHandler() {
    // Default to using the first account if no account is active on page load
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      return;
    }
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
         instance.loginRedirect(loginRequest).catch((e) => {
          console.log(e);
        });
      }
  }
}
