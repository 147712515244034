import { useEffect, useState } from "react";
import { IProps, FeamEntities, IDropdown } from "../../../models/feam-entities";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CswDynamicTailForm from "./CswDynamicTailForm";
import { Formik, FormikErrors, FormikProps } from "formik";
import { workTypeService } from "../../../services";
import workStatusTypeService from "../../../services/workstatustype-service";
import { NoRecordFound } from "../common";
import { formatStringDate } from "../../../models/feam-utils";
import { useDirty, useSharedState } from "./DynamicWorkSheet";
import { useTokenState } from "../../../RequestInterceptors";
import CwsWorkAccomplished from "../common/CwsWorkAccomplished";

const initialTail: { detail: FeamEntities.ITailDetail | undefined, index: number } = { detail: undefined, index: -1 };
const initialWorkDesc: { workTypes: IDropdown[], workStatusTypes: IDropdown[] } = { workTypes: [], workStatusTypes: [] };
export default function CwsDynamicTailContainer(props: IProps.ICwsDynamicContainerProps<FeamEntities.ITailDetail>) {
  const [tail, setTail] = useState(initialTail);
  const [workDesc, setWorkDesc] = useState(initialWorkDesc);
  const [, setDeleteToggle] = useState(false);
  const { reload, setReload } = useSharedState();
  const { isTokenSet } = useTokenState();
  const { isDirty, setIsDirty } = useDirty();
  const { formProps, print, maintenanceTypes } = props;
  useEffect(() => {
    if (isTokenSet) {
      const fetchData = async () => {
        const result = await Promise.all([workTypeService.getAll(), workStatusTypeService.getAll()]);
        setWorkDesc({ workTypes: result[0], workStatusTypes: result[1] });
      }
      fetchData()
    }
  }, [isTokenSet]);
  return (
    <>
      <CwsWorkAccomplished formProps={formProps} arraySelector="tailDetails" />
      <div className="mb-0">
        <div className="d-flex justify-content-start align-items-center header-col w-100 p-1 fw-bold mb-1 rounded text-uppercase">
          {/*//@ts-ignore */}
          {formProps.values.maintenanceTypeId && maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.isCourseType ? "Courses completed details" : "WORK DETAILS"}
        </div>
        <div>
          {/*//@ts-ignore */}
          {(!formProps.values.tailDetails || formProps.values.tailDetails.length === 0) &&
            <NoRecordFound message="No Tail found" className="mb-3 mt-3" />
          }
          {/* Tail detail */}
          {/*//@ts-ignore */}
          {formProps.values.tailDetails?.map((tItem: FeamEntities.ITailDetail, tIndex: number) => {
            return (
              <div key={`${tItem.tail}_${tIndex}`} className="card m-0 mb-1 pb-0">
                <div className="card-header d-flex justify-content-between align-items-center header-col w-100  fw-bold m-0 rounded text-uppercase">
                  <span>Tail {tIndex + 1} #</span>
                  {!print && <div className="d-flex d-inline-flex gap-1">
                    <BootStrapIcon.PencilFill role="button" className="text-body" size={16} onClick={() => { setTail({ detail: tItem, index: tIndex }) }} title="Edit Tail" />
                    {/*//@ts-ignore */}
                    <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteTailHandler(formProps, tIndex)} title="Delete Tail" />
                  </div>}
                </div>
                <div className="card-body m-0 p-1">
                  <div className="row g-3">
                    <div className="col-sm-5">
                      <div className="card mb-0 pb-0 dynamic-tail">
                        <div className="card-header text-uppercase header-col fw-bold m-0">
                          Tail General Information
                        </div>
                        <div className="card-body mb-0 mt-0">
                          <div className="row g-3">
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">AC/Tail</div>
                              <div className="">{tItem.tail}</div>
                            </div>
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">Flight#</div>
                              <div className="">{tItem.flightNum}</div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">Gate</div>
                              <div className="">{tItem.gateNum}</div>
                            </div>
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">Date</div>
                              <div className="">{tItem.arrivalDate ? formatStringDate(tItem.arrivalDate) : ''}</div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">ETA</div>
                              <div className="">{tItem.eta}</div>
                            </div>
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">ATA</div>
                              <div className="">{tItem.ata}</div>
                            </div>
                          </div>
                          <div className="row g-3 ">
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">ETD</div>
                              <div className="">{tItem.etd}</div>
                            </div>
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">ATD</div>
                              <div className="">{tItem.atd}</div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">GU</div>
                              <div className="">{tItem.gu}</div>
                            </div>
                            <div className="col customer-tail-detail d-flex flex-row gap-3">
                              <div className="text-uppercase fw-bold">RON</div>
                              <div className="form-check align-items-center">
                                <input type="checkbox" checked={tItem.ron ?? false} className="form-check-input" disabled={true} style={{ marginTop: "-1px" }} />
                              </div>
                              <div className="text-uppercase fw-bold">Towing</div>
                              <div className="form-check align-items-center">
                                <input type="checkbox" checked={tItem.towing ?? false} className="form-check-input" disabled={true} style={{ marginTop: "-1px" }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="card">
                        <div className="card-body m-0 p-0 header-col  rounded">
                          <table className="table m-0 p-0 dynamic-tail-table">
                            <thead className="header-col">
                              <tr className="text-uppercase header-col">
                                <th scope="col">Material</th>
                                <th scope="col">Total QTY</th>
                                <th scope="col">Eng 1</th>
                                <th scope="col">Eng 2</th>
                                <th scope="col">Eng 3</th>
                                <th scope="col">Eng 4</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td scope="row" className="text-uppercase fw-bold">Oils</td>
                                <td>{(+(tItem.oilEngine1 ?? 0)) + (+(tItem.oilEngine2 ?? 0)) + ((+(tItem.oilEngine3 ?? 0))) + ((+(tItem.oilEngine4 ?? 0)))}</td>
                                <td>{tItem.oilEngine1 ? tItem.oilEngine1 : 0}</td>
                                <td>{tItem.oilEngine2 ? tItem.oilEngine2 : 0}</td>
                                <td>{tItem.oilEngine3 ? tItem.oilEngine3 : 0}</td>
                                <td>{tItem.oilEngine4 ? tItem.oilEngine4 : 0}</td>
                              </tr>
                              <tr>
                                <td scope="row" className="text-uppercase fw-bold">Hydraulic Fluid</td>
                                <td>{(+(tItem.hydEngine1 ?? 0)) + (+(tItem.hydEngine2 ?? 0)) + ((+(tItem.hydEngine3 ?? 0))) + ((+(tItem.hydEngine4 ?? 0)))}</td>
                                <td>{tItem.hydEngine1 ? tItem.hydEngine1 : 0}</td>
                                <td>{tItem.hydEngine2 ? tItem.hydEngine2 : 0}</td>
                                <td>{tItem.hydEngine3 ? tItem.hydEngine3 : 0}</td>
                                <td>{tItem.hydEngine4 ? tItem.hydEngine4 : 0}</td>
                              </tr>
                              <tr>
                                <td scope="row" className="text-uppercase fw-bold">APU</td>
                                <td colSpan={5}>{tItem.apu ? tItem.apu : 0}</td>
                              </tr>
                              <tr>
                                <td scope="row" className="text-uppercase fw-bold">Nitrogen</td>
                                <td colSpan={5}>{tItem.materialsNitrogen ? tItem.materialsNitrogen : 0}</td>
                              </tr>

                              <tr>
                                <td scope="row" className="text-uppercase fw-bold">Oxygen</td>
                                <td colSpan={5}>{tItem.materialsOxygen ? tItem.materialsOxygen : 0}</td>
                              </tr>
                              <tr>
                                <td scope="row" className="text-uppercase fw-bold">Other</td>
                                <td colSpan={5}>{tItem.materialsOther}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/** Description section */}
                  <div className="d-flex  d-flex justify-content-between align-items-center header-col w-100 p-2 mt-1 fw-bold mb-1 rounded text-uppercase">
                    <span>Work Description</span>
                  </div>
                  {(!tItem.workDetails || tItem.workDetails.length === 0) &&
                    <NoRecordFound message="No Work Description found" className="mb-0"></NoRecordFound>
                  }
                  {tItem.workDetails !== undefined && tItem.workDetails.length > 0 && (<table className="table dynamic-tail-table m-0 p-0">
                    <thead>
                      <tr className="text-uppercase">
                        <th scope="col">Work Type</th>
                        <th scope="col" >Ref#</th>
                        <th scope="col">Description</th>
                        <th scope="col">Total Time</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tItem.workDetails?.map((w: FeamEntities.IWorkDetail, i: number) => {
                          return (
                            <tr key={`workdesc_${i}`}>
                              <td>{w.workTypeId ? workDesc.workTypes.find(x => x.value === w.workTypeId?.toString())?.text : ''}</td>
                              <td>{w.refNumber} </td>
                              <td>{w.description} </td>
                              <td>{w.totalTime}</td>
                              <td>{w.status ? workDesc.workStatusTypes.find(x => x.value === w.status?.toString())?.text : ''}</td>
                            </tr>)
                        })}
                    </tbody>

                  </table>)}
                </div>
              </div>)


          })}
        </div>
        {!print && <button type="button" className="btn btn-outline-secondary btn-block w-100 text-uppercase p-0 m-0 mb-1" onClick={() => setTail({ detail: { towing: false, ron: false, workDetails: [] }, index: -1 })} title="Add Tail"><BootStrapIcon.PlusCircleFill size={16} /> Add Tail</button>}
      </div>
      <Formik
        initialValues={tail.detail ?? {}}
        enableReinitialize={true}
        validate={validateHandler}
        onSubmit={(
          values: FeamEntities.ITailDetail
        ) => {
          addAndUpdateTailHandler(values);
        }}
      >
        {(fprops: FormikProps<FeamEntities.ITailDetail>) => {

          return (
            <Modal isOpen={tail.detail !== undefined} scrollable size="lg">
              <ModalHeader className="text-uppercase">
                {tail.detail && (tail.index !== -1 ? "Edit Tail" : "Add AC/Tail")}
              </ModalHeader>
              <ModalBody className="p-3">
                <div className="mb-3">
                  {/*//@ts-ignore */}
                  <CswDynamicTailForm formProps={fprops} maintenanceTypeId={formProps.values.maintenanceTypeId} maintenanceTypes={maintenanceTypes} tailIndex={tail.index} />
                </div>
              </ModalBody>
              <ModalFooter>
                <button type="button" className="btn btn-secondary" onClick={() => { fprops.setFieldTouched("Tail"); fprops.submitForm(); }} title={tail.index === -1 ? "Add" : "Update"} disabled={!fprops.dirty || !fprops.values.tail || fprops.isSubmitting}><BootStrapIcon.SaveFill size={16} />&nbsp;{tail.index === -1 ? "Add" : "Update"}</button>
                <button type="button" className="btn btn-outline-secondary" onClick={() => setTail(initialTail)} title="Cancel"><BootStrapIcon.DashCircleFill size={16} />&nbsp;Cancel</button>
              </ModalFooter>
            </Modal>)
        }}
      </Formik>

    </>
  )

  function addAndUpdateTailHandler(values: FeamEntities.ITailDetail) {
    if (tail.index === -1) {
      //Add item
      //@ts-ignore 
      formProps.values.tailDetails.push(JSON.parse(JSON.stringify(values)));
    }
    else {
      //Update item
      //@ts-ignore 
      formProps.values.tailDetails[tail.index] = JSON.parse(JSON.stringify(values));
    }
    setIsDirty(true);
    setTail(initialTail);
    setReload(!reload);
  }

  function validateHandler(values: FeamEntities.ITailDetail): void | object | Promise<FormikErrors<FeamEntities.ITailDetail>> {
    const error: FeamEntities.ITailDetail = {};
    if (!values.tail) {
      error.tail = 'Tail is Required';
    }
    return error;
  }

  function deleteTailHandler(formProps: FormikProps<FeamEntities.ID407Data>, index: number) {
    //@ts-ignore 
    const tailName = formProps.values.tailDetails[index].tail;
    if (window.confirm(`Are you sure! You want to delete the "${tailName}" AC/Tail`)) {
      formProps.values.tailDetails?.splice(index, 1);
      setDeleteToggle(prev => !prev);
      setReload(!reload);
      setIsDirty(true);
    }
  }

}