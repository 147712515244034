import { autocompleteClasses } from "@mui/material";

export const autoCompleteStyle = {
  borderRadius: 2,
  fontSize: "small",
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#dee2e6",
  },
  "&:hover": {
    "&& fieldset": {
      border: "1 solid #dee2e6",
    },
  },
  [`.${autocompleteClasses.root}`]: {
    borderRadius: 2,
    padding: 0,
    margin: 0,
    border: "none",
    fontSize: "small",
  },
  "& .MuiOutlinedInput-root.hover": {
    border: "none",
    outline: 0,
    fontSize: "small",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    color: "#212529",
    backgroundColor: "transparent",
    outline: 0,
    boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
    border: "1 solid #dee2e6",
    fontSize: "small",
  },
  "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
    paddingLeft: 1,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    height: 21.5,
    // width: 210,
    fontSize: "small",
    border: "none",
  },

  "& input.MuiAutocomplete-input": {
    paddingLeft: 1,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    height: 21.5,
    // width: 210,
    fontSize: "small",
    border: "1px solid #dee2e6",
  },
  [`.${autocompleteClasses.inputRoot}`]: {
    padding: 0,
    margin: 0,
    height: 21.5,
    // width: 210,
    fontSize: "small",
    border: "none",
  },
  [`.${autocompleteClasses.hasClearIcon},.${autocompleteClasses.hasClearIcon}:hover`]:
    {
      right: 0,
      outline: 0,
    },
};
