import { FeamEntities, IDropdown } from "../models/feam-entities";
import feamCache from "./cache";
import apiClient from "./http-common";

class WorkTitleService {
  #rootPath: string = "worktitle";
  #key: string = "feam_worktitles";

  async getAll(): Promise<IDropdown[]> {
    try {
      const res = await apiClient.get<FeamEntities.IWorkTitle[]>(
        this.#rootPath
      );

      const worktitles = res.data.map<IDropdown>(
        (d: FeamEntities.IWorkTitle): IDropdown => {
          return {
            text: d.workTitleCode + "-" + d.workTitleName,
            value: d.workTitleCode,
          };
        }
      );
      return worktitles;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
}

const workTitleService = new WorkTitleService();
export default workTitleService;
