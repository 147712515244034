const mechanicsTypes = ["MCH", "ENG", "APT"];
const supervisorsTypes = ["MNG", "SPS", "AVCS", "MSS", "SPS", "PNES"];
const avionicsType = ["AVC"];
const leadsTypes = ["TCP"];
const adminEmailIds = ["dbroche@feam.aero"];
const finalApprovers = ["ray serrano", "mariano barrios", "ray", "mariano"];
const regularPay = ["RT"];
const overtimePay = ["OT"];
const feamUserInfo = "feam_user_info";
const reportMechanicsTypes = ["MCH", "TCN", "APT"];
const reportSupervisorsTypes = ["MNG", "STS", "SPS"];
const reportAvionicsType = ["AVC", "AVCS"];
const reportLeadsTypes = ["TCP", "LTN", "MCN"];
const reportAssistant = ["ADM", "ADA"];
const reportFlightMechanic = ["FTL"];
const reportEngineer = ["ENG"];
const reportSpecialist = ["MTS", "MSS", "SMW", "GST", "STR", "STRS", "OJT"];
const reportPlanner = ["PNE", "PNES"];
const reportMechanicHelper = ["TAN", "MHR"];
const reportAog = ["AOG"];
const reportOss = ["OSS"];
const reportInspector = ["INS"];

const userRoleType = {
  supperAdmin: 1,
  stationAdmin: 2,
  stationApprover: 3,
  billingApprover: 4,
};

const processingStatusType = {
  queue: 1,
  inProgress: 2,
  created: 3,
  delete: 4,
  failed: 5,
  approved: 6,
  markedforBilling: 7,
  billed: 8,
};

const resultStatusType = {
  failed: 0,
  success: 1,
  notExists: 2,
};

const searchType = {
  advancedSearch: 1,
  billedReview: 2,
  reports: 3,
  billingStatus: 4,
  userManagement: 5,
};

const pageTitle = {
  singIn: "Sign in - Feam Aero",
  advancedSearch: "Search - Feam Aero",
  classicD407Form: "D407 Classic - Feam Aero",
  dynamicD407Form: "D407 Dynamic - Feam Aero",
  reports: "Reports - Feam Aero",
  billingReview: "Billing - Feam Aero",
  help: "Help - Feam Aero",
  billingProcessingStatus: "Status - Feam Aero",
  userManagement: "Users - Feam Aero",
};
const workTitles = {
  mechanics: "MECHANICS",
  avionics: "AVIONICS",
  supervisors: "SUPERVISORS",
  leads: "LEADS",
  assistant: "ASSISTANT",
  flightMechanic: "FLIGHT MECHANIC",
  engineer: "ENGINEER",
  specialist: "SPECIALIST",
  planner: "PLANNER",
  mechanicHelper: "MECHANIC HELPER",
  aog: "AOG",
  oss: "OSS",
  inspector: "INSPECTOR",
  other: "OTHER",
};

const printAction = {
  report: "Report",
  d407Forms: "D407 Forms",
  summaryReports: "Summary Reports",
};

const viewSectionAction = {
  dynamic: "dynamic",
  classic: "classic",
  cancel: "cancel",
};
const allWorkTitles = [
  workTitles.mechanics,
  workTitles.aog,
  workTitles.oss,
  workTitles.avionics,
  workTitles.supervisors,
  workTitles.leads,
  workTitles.assistant,
  workTitles.flightMechanic,
  workTitles.engineer,
  workTitles.specialist,
  workTitles.planner,
  workTitles.mechanicHelper,
  workTitles.inspector,
  workTitles.other,
];
export {
  mechanicsTypes,
  supervisorsTypes,
  avionicsType,
  leadsTypes,
  adminEmailIds,
  finalApprovers,
  regularPay,
  overtimePay,
  pageTitle,
  workTitles,
  userRoleType,
  resultStatusType,
  processingStatusType,
  feamUserInfo,
  searchType,
  printAction,
  viewSectionAction,
  reportAog,
  reportAssistant,
  reportAvionicsType,
  reportEngineer,
  reportFlightMechanic,
  reportInspector,
  reportLeadsTypes,
  reportMechanicHelper,
  reportMechanicsTypes,
  reportOss,
  reportPlanner,
  reportSpecialist,
  reportSupervisorsTypes,
  allWorkTitles,
};
